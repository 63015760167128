import {
  IonInput, IonItem,
  IonLabel
} from "@ionic/react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCities, login } from "../../services/api";
import { applicationConfig, setUserSession } from "../../utils/methods";
import Button2 from "../common/ui-components/buttons/Button2";
import Loader from "../common/ui-components/Loader";
import { disable } from "workbox-navigation-preload";
import { showToast } from "../../utils/UI_methods/global";
var md5 = require('md5');

const Login: React.FC<{
  setisOpenForgetPass: (val: boolean) => void
  setForgetPass: (val: string) => void
}> = (props) => {

  const history = useHistory();

  const [agencyCode, setAgencyCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [reCaptchaToken, setRecaptchaToken] = useState<any>(null);

  useEffect(() => {
    $("#password").keyup(function (event: any) {
      if (event.keyCode === 13) {
        $("#login").click();
      }
    });

  }, [])

  const handleLogin = () => {
    setLoading(true);
    // console.log("click huaa hai....")
    // setUserSession("ksbdfsbdfbfvusdbfbd", {user_name:"user"});
    // window.location.href = '/'
    let formData: any = getLoginData()
    // setUserSession(data.session_id, {user_name:"user"});
    // window.location.href = '/'
    // return

    if (!formData.userName?.length || !formData.agencyCode?.length || !formData.password?.length) {
      setError("Fill empty fields")
      return
    }

    login(formData).then((response: any) => {
      // console.log(response);
      let responseData = response.data.data;
      console.log("responseData.......", responseData);
      if (responseData.success) {
        setUserSession(responseData.session_id, { agencyCode: formData.agencyCode, userName: formData.userName, userId: responseData.user_details[0].id, accountId: responseData.user_details[0].account_id });
        applicationConfig.logger.info("User logged in");
        setTimeout(() => {
          window.location.href = '/'
        }, 200);
      } else {
        applicationConfig.logger.error(responseData.message)
        setError(responseData.message)
      }
      // setLoading(false);
    }).catch((err: any) => {

      // setTimeout(() => {
      //   setError(" ");
      // }, 5000);
      console.log(err);
      showToast(err.message, 2000, "error")
      setLoading(false);

      //  setUserSession("ksbdfsbdfbfvusdbfbd", {user_name:"user"});
      //  window.location.href = '/'
      // disableLoading()
      // initialState()
    })
  };

  function gotoForgetPasswordPage() {
    props.setForgetPass("forget");
    props.setisOpenForgetPass(true);
  }

  function gotoRegisterPage() {
    history.push("otpverify");
    // history.push("register")
  }

  function getLoginData() {
    // console.log("Running...")
    return {
      userName: $("#userName").val(),
      agencyCode: $("#agencyCode").val(),
      password: $("#password").val()
    }
  }


  function onChange() {
    setVerified(!verified);
  }


  // console.log(verified);

  return (
    <div className="login-container position-relative">

      <div className="full-width center-aligned-column">
        <img width={250} src="assets/images/travel_power_logo.png" alt="" />
      </div>

      {/* <div className="heading ion-padding-bottom ion-padding-top color-primary heading1 font-large">Sign in</div>
      <div className="heading2 color-black font-normal">Please login to continue</div> */}
      {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

      <div className="form-label-input-container">
        <div className="label heading3 full-width">Company Code</div>
        <div className="center-aligned-row marginTop10">
          <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={agencyCode} className="input search-input" name="" >
          </input>
        </div>
      </div>

      <div className="form-label-input-container ">
        <div className="label heading3 full-width">Username</div>
        <div className="center-aligned-row marginTop10">
          <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="userName" type="text" defaultValue={username} className="input search-input" name="" >
          </input>
        </div>
      </div>


      <div className="form-label-input-container ">
        <div className="label heading3 full-width">Password</div>
        <div className="center-aligned-row marginTop10">
          <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="password" type="password" defaultValue={password} className="input search-input" name="" >
          </input>
        </div>
      </div>


      <div className="marginTop10">
        <div className="display-flex-row flex-end ion-padding-bottom">
          <strong>
            <span onClick={gotoForgetPasswordPage} className="heading color-black underline pointer" style={{}}>Forgot password?</span>
            {/* <a onClick={gotoForgetPasswordPage} href="" className="color-black heading pointer">Forgot password?</a> */}
          </strong>
        </div>

        <div className="recaptcha-container" style={{border: "", marginLeft: "5px"}}>
           <ReCAPTCHA
            className="g-recaptcha"
            size="normal"
            sitekey="6LfBewcqAAAAAO8CdICl2z2Rei3YIkFhG_BNb3nM"
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={onChange}
            >

            </ReCAPTCHA>
          
        </div>

      </div>

      <div>
        {/* export default YourForm; */}
      </div>

      <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
        <Button2
          configObj={{}}
          action={verified ? handleLogin : () => { }}
          label={loading ? 'Please wait...' : 'Sign in'}
          fontAwesomeIcon={loading ? " " : "/assets/images/Vector.png"}
          iconPos="right"
          classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
        />

      </div>

      {/* ion-padding heading ion-text-center */}
      <div className="go-to-registerpage ion-text-center ion-padding heading3">
        Not a member yet?
        <span onClick={gotoRegisterPage} className="heading bold underline pointer" style={{ color: "blueviolet", marginLeft: "3px" }}>Register Now!</span>
        {/* <a className="pointer" onClick={gotoRegisterPage} >Register Now!</a> */}
      </div>
      {/* <div className="padding10"></div>
      <div className="padding10"></div> */} 

    </div>


  );
};

export default Login;
