import React, { useState } from "react";
import {
  IonModal,
  IonPage,
  IonButton,
  IonImg,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from "@ionic/react";
import "../style/login.css";
import LoginPage from "./LoginPage";
import backgroundImage from "../style/background-main.png";
import { Carousel } from "react-responsive-carousel";
import Button2 from "../common/ui-components/buttons/Button2";
import ForgetPassword from "./ForgetPassword";

const Login: React.FC<{
}> = (props) => {

  const [forgetPass, setForgetPass] = useState<string>(" ");
  const [isOpenForgetPass, setisOpenForgetPass] = useState<boolean>(false)

  // console.log("Run hua....", forgetPass)
  // console.log("isOpenForgetPass", isOpenForgetPass);

  return (
    <IonPage>
      {/* style={{backgroundImage: "url('assets/images/background_login.png')"}} */}
      {/* <div className="full-height-width login-page-main-wrapper center-aligned-column" style={{backgroundImage:`url(${backgroundImage})`,backgroundSize:"cover"}}> */}
      <div className="full-height-width login-page-main-wrapper bg-color-light center-aligned-column">
        <div className="container shadow-medium white-card overflow-y- hide-scrollbar">
          <div className="login-page-left center-aligned-column position-relative" style={{ backgroundImage: "url('')", border: "" }}>

            {/* <div className="top-left full-height-width background">
            </div> */}

            {/* <div className="left-middle ion-padding">
              <div className="heading1 ion-margin-top font-ex-large letter-spacing1 color-white">Travel Power</div>
              <div className="heading1 font-ex-large color-white letter-spacing1 marginTop5">Power in your hands</div>
              <div className="heading3 description ion-margin-top font-normal color-white">The online platform for
              offline travel professionals
              <br />
              Experience seamless travel planning with our cutting-edge booking software, designed for offline travel professionals
              </div>
             </div> */}

            <IonModal
              id="example-modal"
              onDidDismiss={() => setisOpenForgetPass(false)}
              isOpen={isOpenForgetPass}
              // id="inventoryModal"
              cssClass='medium-y-modal Forget-passoword-popup'
            >
              {/* medium-y-modal */}
              {forgetPass == "forget" ?
                <ForgetPassword closeForgetPass={() => setisOpenForgetPass(false)} />
                : null}

            </IonModal>


            <div className="login-left">

              <Carousel className="login-left-cursol" width={285} infiniteLoop={true} autoPlay={true} stopOnHover={false} showThumbs={false} ariaLabel="" showArrows={false} showStatus={false} interval={5000}>

                <IonCard className="ion-card-logo">
                  <IonCardContent className="ion-card-content">
                    <img src="/assets/images/splash/splashtplogo.png" className="ion-card-img" />
                  </IonCardContent>
                </IonCard>

                <IonCard className="ion-card">
                  <IonCardHeader className="ion-card-header">
                    <IonCardSubtitle className="ion-card-subtitle">The only online platform for</IonCardSubtitle>
                    <IonCardTitle className="ion-card-title">Offline Travel</IonCardTitle>
                    <IonCardTitle className="ion-card-title">Professional</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className=""><img src="/assets/images/splash/splash1image.png" className="img-card" /></IonCardContent>
                </IonCard>

                <IonCard className="ion-card" color="">
                  <IonCardHeader className="ion-card-header">
                    <IonCardSubtitle className="ion-card-subtitle">Expand your Network in Travel Industry</IonCardSubtitle>
                    <IonCardTitle className="ion-card-title">Multiply your</IonCardTitle>
                    <IonCardTitle className="ion-card-title">Travel Sales</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className=""><img src="/assets/images/splash/splash2image.png" className="img-card" /></IonCardContent>
                </IonCard>



                <IonCard className="ion-card" color="">
                  <IonCardHeader className="ion-card-header">
                    <IonCardSubtitle className="ion-card-subtitle">Collaborate with wider Travel Fraternity</IonCardSubtitle>
                    <IonCardTitle className="ion-card-title">Take power in</IonCardTitle>
                    <IonCardTitle className="ion-card-title">your hand</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className=""><img src="/assets/images/splash/splash3image.png" className="img-card" /></IonCardContent>
                </IonCard>

              </Carousel>

            </div>

          </div>
          <div className="full-height login-page-right center-aligned-column login-page-wrapper" style={{ border: "" }} >
            
            <LoginPage setForgetPass={setForgetPass}  setisOpenForgetPass={setisOpenForgetPass}/>

            {/* {forgetPass == "forget" ?
              <ForgetPassword />
              :
              <LoginPage setForgetPass={setForgetPass} />
            } */}
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Login;
