import React, { useEffect, useState } from "react";
import { getQueryList } from "../../../../services/api";
import Dates from "./Dates";
import { ReactComponent as SvgHotel } from '../../../svg/Hotel-booking.svg';
import { ReactComponent as SvgVisa } from '../../../svg/Visa-Service.svg';

const Details: React.FC<{
  query: any
}> = (props) => {

  function diffDate(val1: any, val2: any) {
    let date1 = new Date(val1);
    let date2 = new Date(val2);

    // Calculating the time difference
    // of two dates
    let Difference_In_Time =
      date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days =
      Math.round
        (Difference_In_Time / (1000 * 3600 * 24));

    return Difference_In_Days;
  }

  // diffDate()
  //Curr page can be leads list page or details page
  // {props.query.cf_917}
  function Header() {
    return (
      <div className="full-width" style={{ border: "", borderBottom: "1px solid #000", paddingBottom: "5px" }}>
        {/* <div className="display-flex-row no-wrap" style={{ width: "100%" }}> */}

        <div className="display-flex-row no-wrap" style={{ width: "100%" }}>
          <div className="marginTop10">
            {props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <SvgHotel width={40} height={40} style={{ fill: 'blue' }} />
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <SvgVisa width={40} height={40} style={{ fill: 'blue' }} />
                :
                null
            }
          </div>

          <div className="padding5 marginleft5" style={{ width: "100%" }}>
            {props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <div className="heading-large color-ex-dark line-height-1 capitalize header2-heading marginBottom5">
                {props.query.cf_917}
              </div>
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <div className="heading-large color-ex-dark line-height-1 capitalize header2-heading marginBottom5">{props.query.cf_1019}</div>
                :
                null
            }
            <div className="display-flex-row header2-content-container" style={{ border: "" }}>
              <div className="display-flex-row no-wrap" style={{ border: "" }}>
                <div className="center-aligned-column">
                  {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                    <i className="far fa-clock color-dark "></i>
                    : props.query?.cf_983?.toLowerCase() === "visa" ?
                      <i className="far fa-clock color-dark "></i>
                      : null
                  }
                </div>
                {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                  <div className="heading5 color-dark marginleft5 header2-content">{diffDate(props.query.cf_935, props.query.cf_937)} Nights {diffDate(props.query.cf_935, props.query.cf_937) + 1} Days </div>
                  :
                  props.query?.cf_983?.toLowerCase() === "visa" ?
                    <div className="heading5 color-dark marginleft5 header2-content">{props.query.cf_1005}</div>
                    :
                    null
                }
              </div>

              <div className="display-flex-row no-wrap ion-margin-start header2-filed-container">
                <div className="center-aligned-column">
                  {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                    <i className="fas fa-user-friends color-dark"></i>
                    : props.query?.cf_983?.toLowerCase() === "visa" ?
                      <i className="far fa-id-card color-dark"></i>
                      : null
                  }
                </div>
                {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                  <div className="heading5 color-dark marginleft5 header2-content">{props.query.cf_927} Adults, {Number(props.query.cf_929) + Number(props.query.cf_931)} Childrens</div>
                  :
                  props.query?.cf_983?.toLowerCase() === "visa" ?
                    <div className="heading5 color-dark marginleft5 header2-content">{props.query.cf_983}</div>
                    :
                    null
                }
              </div>

              <div className="display-flex-row no-wrap ion-margin-start header2-filed-container">
                <div className="center-aligned-column">
                  {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                    <></>
                    : props.query?.cf_983?.toLowerCase() === "visa" ?
                      <i className="fas fa-user-friends color-dark"></i>
                      :
                      null
                  }
                </div>
                {props.query?.cf_983?.toLowerCase() === "accommodation" ?
                  <></>
                  :
                  props.query?.cf_983?.toLowerCase() === "visa" ?
                    <div className="heading5 color-dark marginleft5 header2-content">{props.query.cf_1011} Adults, {props.query.cf_1013} Childrens</div>
                    :
                    null
                }
              </div>
            </div>

          </div>

        </div>

       { props.query?.cf_983?.toLowerCase() === "accommodation" ? 
        <div className="" style={{ border: "", display: "flex", justifyContent: "space-between", width: "100%", padding:"2px"}}>
          <div className="requested-content" style={{ border: "", width: "30%",}}>
            <p className="color-dark requested-content">Requested Budget:</p>
            <p className="color-dark requested-content">&#x20B9; {props.query.cf_923}</p>
          </div>
          <div className="requested-content" style={{ borderLeft: "1px solid gray", borderRight: "1px solid gray", width: "40%", textAlign:"center", padding:"2px"}}>
            <p className="color-dark requested-content">Requested Hotel:</p>
            <p className="color-dark requested-content">{props.query.cf_919}</p>
          </div>
          <div className="requested-conten requested-right-content" style={{width: "30%", padding:"2px", marginLeft:"25px"}}>
            <p className="color-dark requested-content">Requested Category:</p>
            <p className="color-dark requested-content">{props.query.cf_921} Star</p>
          </div>
        </div>

        :

        <></>
       }

      </div>
    )
  }



  return (
    <div className="padding10 full-width">
      <Header />
      <Dates
        query={props.query}
      />
    </div>
  );
};

export default Details;
