  
  import React, { useEffect, useState } from "react";
  import { VisaForm } from "../../../../utils/forms/VisaForm";
  import { hotelNameValidation } from '../../../../utils/forms/validation';
import { event } from "jquery";
    
    const CustomerCityTown: React.FC<{
      formData:VisaForm
    }> = (props) => {
   
      const [CityOrTown, setCityOrTown] = useState<String>("");

      function onchangesCustomerCityOrTown(event: any){
          hotelNameValidation(event);
          props.formData.setCustomerCityOrTown(event.target.value);
      }

      return(
  
              <div className="full-width display-flex-row no-wrap space-between marginTop10" >
             
  
              <div className="full-width">
                  <div className="label full-width">Customer City/Town <span className="mandatory color-danger">*</span></div>
                  <div className="center-aligned-row">
                    <input onChange={(event) => onchangesCustomerCityOrTown(event)} defaultValue={props.formData.customer_city_town} className="input search-input" name="" type="text" placeholder="Enter City/Town" />
                   
                  </div>
              </div>
  
            
  
              </div>
       
        )
    };
    
    export default CustomerCityTown;
  
   