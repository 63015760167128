import $ from "jquery"
import { getCities } from "../../services/api"
import { registerFormBillingDetails, registerFormWithGST, registerFormWithoutGST } from "../data"
import { applicationConfig } from "../methods"


var typingTimer: NodeJS.Timeout           
var doneTypingInterval = 500   



export function getRegisterUserData() {

    let registerUserData:any = {}
    for (const details of registerFormWithoutGST) {
        let key:string = details.id
        registerUserData[key] = $("#"+key).val()
    }

    for (const details of registerFormWithGST) {
        let key:string = details.id
        registerUserData[key] = $("#"+key).val()
    }

    for (const details of registerFormBillingDetails) {
        let key:string = details.id
        registerUserData[key] = $("#"+key).val()
    }

    return registerUserData
}


export function locationChangeListener() {
    //on keyup, start the countdown
    $("#destinationName").on('keyup', function () {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(doneTyping, doneTypingInterval)
    })

    //on keydown, clear the countdown 
    $("#destinationName").on('keydown', function () {
        clearTimeout(typingTimer)
    })   
}



//user is "finished typing," do something
function doneTyping () {
    let value = String($("#destinationName").val())  || ""
    if(value.length < 3){
        applicationConfig.functions.hotel.setCities([])
        return
    }
    getCities(value).then((data:any)=>{
    applicationConfig.functions.hotel.setCities(data.data.data)
    }).catch(err=>{
    applicationConfig.functions.hotel.setCities([])
    })
}