import React, { useEffect, useState } from "react";

const NumberOfTravellers: React.FC<{
    formData: any
}> = (props) => {

    function onChangeNumberOfTravellers(value: number){
        props.formData.setNoOfTravellers(value)
    }
    return(
        <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
      <div className="label full-width">Number of Travellers <span className="mandatory color-danger">*</span></div>
      <div className="input-wrapper">
        <div className="center-aligned-row">
            <input className="input search-input" type="tel" placeholder="No. of Travellers" defaultValue={""} onChange={(event) => onChangeNumberOfTravellers(Number(event.target.value))} />
        </div>
        {/* <div className="display-flex-row marginTop5" style={{ justifyContent: "center" }}>
          <ul className="color-danger error font-small"></ul>
        </div> */}
      </div>
    </div>
    )
}

export default NumberOfTravellers;