import React, { useEffect, useState } from 'react';
import { createOpportunity } from '../../../../services/api';
import { showToast } from '../../../../utils/UI_methods/global';
import { specialRequestValidation } from '../../../../utils/forms/validation';
import { applicationConfig } from '../../../../utils/methods';
import Button2 from '../../../common/ui-components/buttons/Button2';
import Header from '../common/Header';
import Destination from "./Destination";
import Category from './Category';
import DateOfPackage from './DateOfPackage';
import GuestNationality from './GuestNationality';
import { PackageForm } from '../../../../utils/forms/Package';
import Package_RoomSelector from './Package_RoomSelector';
import Checkboxes from './Checkboxes';
import UploadItinerary from './UploadItinerary';
import PickupFrom from './PickupFrom';
import DepartureFrom from './DepartureFrom';
import Date_TimeOfArrival from './Date_TimeOfArrival';
import Date_TimeOfDeparture from './Date_TimeOfDeparture';
import CitiesToCovered from './CitiesToCovered';
import Other from './Other';



const Tourpackage: React.FC<{
    isUpdateMode: boolean
    closeAction: () => void
}> = (props) => {

    const [arrivalDate, setArrivalDate] = useState(new Date());
    // const getDayOfArrival = new Date(arrivalDate).getDate();
    // console.log(getDayOfArrival);

    const [currStep, setCurrStep] = useState("step1")
    const [error, setError] = useState("");
    const [formData, setFormData]: any = useState(new PackageForm())

    function onchangespecialRequirement(event: any) {
        specialRequestValidation(event)
        formData.setSpecialRequirement(event.target.value)
    }

    function onchangesPackageItinerary(event: any) {
        specialRequestValidation(event)
        formData.setSpecific_itinerary(event.target.value)
    }

    function submit() {

        if (formData.visa == false && formData.sightseeing == false && formData.hotel == false && formData.transfers == false && formData.cruise == false && formData.travel_insurance == false) {
            showToast("Please select services required", 2000, "error");
            return
        }

        if ((Number(formData.service_CategoriesClicked) > 0) && (Number(formData.service_CategoriesClicked) < 2)) {
            setError("Atleast 2 services required")
            showToast("Atleast 2 services required", 2000, "error")
            return
        }

        if (formData.package_destination === "") {
            showToast("Please enter destination", 2000, "error");
            return
        }

        if (formData.cities_to_cover == "") {
            showToast("Please enter cities to cover", 2000, "error");
            return
        }

        if (formData.pickup_from == "") {
            showToast("Please enter pickup from", 2000, "error");
            return
        }

        if (formData.date_of_arrival === "") {
            showToast("Please enter date of arrival", 2000, "error");
            return
        }

        if (formData.departure_from === "") {
            showToast("Please enter tourd ends at", 2000, "error");
            return
        }

        if (formData.date_of_departure === "") {
            showToast("Please enter date of departure", 2000, "error")
            return
        }

        if (formData.category === "") {
            showToast("Please select category", 2000, "error")
            return
        }

        if (formData.nationality === "") {
            showToast("Please select nationality", 2000, "error")
            return
        }

        if(formData.how_soon_are_you_willing_to_continue === ""){
            showToast("Please select how soon are you willing to continue", 2000, "error")
            return
          }

        setError("");
        console.log("FormData****", formData);

    }

    return (

        <div className="wrapper create-query-wrapper full-height-width position-relative">

            <div className='header-content'>
                <Header
                    heading="Tour Package"
                    closeAction={props.closeAction}
                />
            </div>
            <div className='body-content'>

                <div className="select-location-wrapper ion-padding">

                    <div className="position-relative">

                        {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

                        <Checkboxes formData={formData} />

                        <Destination
                            formData={formData}
                        />

                        <CitiesToCovered formData={formData} />

                        <PickupFrom formData={formData} />

                        <Date_TimeOfArrival formData={formData} setArrivalDate={setArrivalDate} />

                        <DepartureFrom formData={formData} />

                        <Date_TimeOfDeparture formData={formData} arrivalDate={arrivalDate} />

                        <Category
                            formData={formData}
                            setCurrStep={setCurrStep}
                        />

                        <GuestNationality formData={formData} />

                        <Package_RoomSelector
                            formData={formData}
                            setCurrStep={setCurrStep}
                        />


                        {/* <div className='display-flex-row nowrap marginTop5'>
                            <div className='width50'>
                                <DateOfPackage formData={formData} />
                            </div>
                            <div className=''>
                                <Category
                                    formData={formData}
                                    setCurrStep={setCurrStep}
                                />
                            </div>
                        </div> */}

                        <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
                            <div className="label full-width">Special Request</div>
                            <div className="input-wrapper">
                                <div className="center-aligned-row">
                                    <textarea defaultValue={formData.special_request} onChange={(event) => onchangespecialRequirement(event)} rows={3} cols={50} className="search-input input" style={{ height: "unset" }} placeholder='Mention package duration special requests related to accommodation, meals, vehicle, time, etc.' />
                                </div>
                                <div className="display-flex-row marginTop5" style={{ marginLeft: "20px" }}>
                                    <ul className="color-danger error font-small"></ul>
                                </div>
                            </div>
                        </div>

                        <Other formData={formData}
                            setCurrStep={setCurrStep} 
                        />

                        <div className="marginBottom5" style={{ marginTop: "20px" }}>
                            <div className="label full-width">Need Specific Itinerary?</div>
                            <div className="input-wrapper">
                                <div className="center-aligned-row">
                                    <textarea defaultValue={formData.specific_itinerary} onChange={(event) => onchangesPackageItinerary(event)} rows={3} cols={50} className="search-input input" style={{ height: "unset" }} placeholder='Paste your itinerary here, if avaibale' />
                                </div>
                                <div className="display-flex-row marginTop5" style={{ marginLeft: "20px" }}>
                                    <ul className="color-danger error font-small"></ul>
                                </div>
                            </div>
                            <p className="label full-width marginTop5" style={{ marginBottom: "-5px" }}>Or</p>
                        </div>

                        
                        <UploadItinerary formData={formData} />

                       

                    </div>
                </div>
            </div>

            <div className='footer-content'>
                <div className="center-aligned-row ion-padding">
                    {!props.isUpdateMode ?
                        <Button2
                            configObj={{}}
                            action={submit}
                            label={"Submit"}
                            fontAwesomeIcon={"fas fa-clipboard color-danger"}
                            iconPos=""
                            classNames={"shadow-light margin0 bg-color-primary"}
                        />
                        :
                        <Button2
                            configObj={{}}
                            action={props.closeAction}
                            label={"Update"}
                            fontAwesomeIcon={"fas fa-clipboard color-danger"}
                            iconPos=""
                            classNames={"shadow-light margin0 bg-color-success"}
                        />
                    }
                </div>
            </div>

        </div>
    )

};

export default Tourpackage;




