
import React from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";

const VisaCategoryAndType: React.FC<{
  formData: VisaForm
}> = (props) => {



  function onChangeCateogry(value: string) {
    props.formData.setVisaCategory(value);
  }

  function onChangeType(value: string) {
    props.formData.setVisaType(value);
  }

  return (

    <div className="full-width display-flex-row no-wrap space-between marginTop10" >
      <div className='width50'>
        <div className="full-width">
          <div className="label full-width">Visa Category <span className="mandatory color-danger">*</span></div>
          <div className="center-aligned-row">
            <select value={props.formData.visa_category} defaultValue={props.formData.visa_category} onChange={(event) => onChangeCateogry(event.target.value)} className="input search-input" name="" >
              <option>Visa Category</option>
              <option>Business</option>
              <option>Tourist</option>
            </select>
          </div>
        </div>
      </div>
      <div className='width50 marginleft10'>
        <div className="full-width">
          <div className="label full-width">Duration<span className="mandatory color-danger">*</span></div>
          <div className="center-aligned-row">
            <select value={props.formData.visa_type} defaultValue={props.formData.visa_type} onChange={(event) => onChangeType(event.target.value)} className="input search-input" name="" >
              <option>Duration</option>
              <option>6 months</option>
              <option>3 months</option>
            </select>
          </div>
        </div>
      </div>

      {/* <div className="ion-margin-top" style={{marginTop:"5px",width:"48%"}}>
                <div className="label full-width">Select Room</div>
                <div className="center-aligned-row">
                  <select  defaultValue={props.formData.room_category} onChange={(event)=>onchangeRoomCategory(event.target.value)} className="input search-input" name="" >
                      <option>Select Room</option>
                  </select>  
                </div>
            </div> */}





    </div>

  )
};

export default VisaCategoryAndType;

