import {
    IonInput, IonItem,
    IonLabel
} from "@ionic/react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCities, login } from "../../../services/api";
import { applicationConfig, setUserSession } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import Loader from "../../common/ui-components/Loader";
import { disable } from "workbox-navigation-preload";
import { showToast } from "../../../utils/UI_methods/global";
import CaptchaGenerator from "./CaptchaGenerator";
import Optreceived from "./Otpreceived"
import { NONAME } from "dns";
var md5 = require('md5');


const Otpverify: React.FC<{

}> = (props) => {

    const history = useHistory();

    const [getotp, setGetOpt] = useState<boolean>(false)
    const [movetonext, setMovetonext] = useState<boolean>(false)
    const [agencyCode, setAgencyCode] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [reCaptchaToken, setRecaptchaToken] = useState<any>(null);

    const [captchaInput, setCaptchaInput] = useState<string>('');
    const [captchaText, setCaptchaText] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaptchaInput(e.target.value);
    };

    const handleCaptchaChange = (text: string) => {
        setCaptchaText(text);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (captchaInput === captchaText) {
            setMessage('CAPTCHA verified successfully!');
            setMovetonext(true);
        } else {
            setMessage('CAPTCHA verification failed. Please try again.');
        }
    };


    function gotoOptConfirm() {
        setGetOpt(true);
        setMovetonext(false);
    }

    function gotpLoginPage(){
        history.push("login");
    }

    return (

        (getotp ?
            <Optreceived setGetOpt={setGetOpt} />
            :
            <div className="login-container position-relative" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>

                <div className="full-width center-aligned-column">
                    <img width={250} src="assets/images/travel_power_logo.png" alt="" />
                </div>

                {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

                <p className="text-center heading1 font-normal capitalize" style={{ fontWeight: "500" }}>REGISTER TO GET POWER IN YOUR HANDS</p>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Mobile Number</div>
                    <div className="center-aligned-row marginTop10">
                        {/* <select className="w3-select" name="option" style={{ width: "20%", border: "none" }}>
                            <option value="" disabled selected>+91</option>
                        </select> */}
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="tel" defaultValue={agencyCode} className="input search-input" name="" >
                        </input>
                    </div>

                    <div className="captcha-div-main" style={{border: "", width: "60%", paddingLeft: "2px", marginTop:"20px"}}>
                        <div className="label heading3 full-width">Please Enter Text Below</div>
                        <CaptchaGenerator onChange={handleCaptchaChange} />
                        <form className="" onSubmit={handleSubmit} style={{ display: "flex", gap: "2px" }}>
                            <input
                                className="input search-input" 
                                style={{border: "none", backgroundColor: "#FBFBFB", width: "100%" }}
                                type="text"
                                value={captchaInput}
                                onChange={handleInputChange}
                                placeholder="Enter CAPTCHA"
                            />
                            <button className="captcha-button" type="submit" style={{ padding: "10px", border: "none", backgroundColor: "blue", color: "white", fontWeight: "700", borderRadius: "4px" }}>Verify</button>
                        </form>
                        <p>{message}</p>
                    </div>

                </div>

                <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
                    <Button2
                        configObj={{}}
                        action={movetonext ? gotoOptConfirm : () => { }}
                        label={loading ? 'Please wait...' : 'Vefify with OTP'}
                        fontAwesomeIcon={loading ? " " : "/assets/images/Vector.png"}
                        iconPos="right"
                        classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
                    />

                </div>

                <div className="go-to-registerpage ion-text-center ion-padding heading3">
                Already have an account?
                    <span onClick={gotpLoginPage} className="heading bold underline pointer" style={{ color: "blueviolet", marginLeft: "3px" }}>Sign in!</span>
                    {/* <a className="pointer" onClick={gotoRegisterPage} >Register Now!</a> */}
                </div>

            </div>

        )
    )
};

export default Otpverify;
