import { IonCheckbox } from "@ionic/react";
import "../../.././style/cnfrmbooking/conformbook.css";
import React, { useEffect, useState } from "react";
import { hotelBookingDetailsForm } from "../../../../utils/data";
import { Adult, Children, Room } from "../../../../utils/forms/hotel";
import Button2 from "../../../common/ui-components/buttons/Button2";
import Dates from "./Dates";
import UploadFile from "./UploadFile";

const EditDetails: React.FC<{
  setCurrTab: (val: string) => void
  currOpt: any
  query: any
}> = (props) => {
  //Curr page can be leads list page or details page
  const [rooms, setRooms]: Array<any> = useState([])
  const [adultsList, setAdultsList]: Array<any> = useState([])
  const [chidlrenList, setChildrenList]: Array<any> = useState([])

  useEffect(() => {

    const adults = []
    const children = []
    // const noOfRooms = props.query.cf_925
    // const noOfAdults = props.query.cf_925
    // const noOfChidlren = props.query.cf_925

    const noOfRooms = 2
    // const noOfAdults = 5
    // const noOfChidlren = 2

    const noOfAdults = props.currOpt?.cf_1089;
    const noOfChidlren = props.currOpt?.cf_1047;

    for (let i = 0; i < noOfAdults; i++) {
      const currAdult = new Adult();
      adults.push(currAdult)
    }
    // console.log(adults);

    for (let i = 0; i < noOfChidlren; i++) {
      const child = new Children();
      children.push(child)
    }
    setAdultsList(adults)
    setChildrenList(children)

    // for (let i = 0; i < noOfRooms; i++) {
    //   const currRoom = new Room()
    //   currRooms.rooms.push(currRoom)
    //   for (let j = 0; j < noOfAdults; j++) {
    //     const adult = new Adult()
    //     currRoom.adult_list.push(adult)
    //   }
    //   for (let k = 0; k < noOfChidlren; k++) {
    //     const child = new Children()
    //     currRoom.children_list.push(child)
    //   }

    // }
    // setRooms(currRooms.rooms)

  }, [props.query])

  function FixedAmountDecimal(currAmt: any) {
    const amount = Number(currAmt).toFixed(2);
    return amount;
  }

  const ColoredLine = (color: any) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 5
      }}
    />
  );

  // function CurrDateGet() {
  //   const timeElapsed = Date.now();
  //   const today = new Date(timeElapsed);
  //   console.log(today);
  // }

  // CurrDateGet();

  function BookingDetails() {
    return (
      // <div className="form-container padding5" >
      //   <div className="" style={{ borderTop: "1px solid rgb(0, 0, 0)", margin: "5px 0 10px 0" }}></div>
      //   {hotelBookingDetailsForm.map((details: any, index: any) => (
      //     <div className="form-label-input-container display-flex-row space-between full-width" key={index}>
      //       <div className="" style={{ padding: "5px 0" }}>
      //         <div className="heading-medium font-normal">
      //           {details.fieldName}
      //         </div>
      //       </div>
      //       <div className="input-container marginRight10">
      //         <div className="color-dark heading3">{details.value}</div>
      //       </div>
      //     </div>
      //   ))}
      // </div>


      <div className="other-details full-width ion-margin-top">
        <div className="display-flex-row margin">
          <div className="heading-small width50">Quote ID</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.quote_no}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Hotel Name</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1027}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Room Category</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1087}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">No. of Rooms</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1093}</div>
        </div>


        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Meal Plan</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1039}</div>
          {/* <div className="heading4 color-dark  width50">{currOption?.cf_1039}</div> */}
        </div>


        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">No. of Adults</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1089}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">No. of Childrens CWB</div>
          <div className="heading4 color-dark  width50">{props.currOpt.cf_1047}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">No. of Childrens CNB</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1049}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Amount</div>
          <div className="heading4 color-dark  width50">&#x20B9; {FixedAmountDecimal(props.currOpt?.hdnGrandTotal)}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Remarks</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1095}</div>
        </div>

        <div className="display-flex-row marginTop5">
          <div className="heading-small width50">Cancellation Policy</div>
          <div className="heading4 color-dark  width50">{props.currOpt?.cf_1041}</div>
        </div>
      </div>
    )
  }

  function RoomDetails() {
    return (
      <div className="form-container padding5">
        <p className="line-height-1" style={{ display: "flex", justifyContent: "flex-end", margin: "10px 10px", color: "blue", fontSize: "15px" }}>Room 1</p>
        {/* {rooms.map((currRoom:Room,index:any)=>( */}
        <div className="form-label-input-container display-flex-row space-between full-width">
          {/* <div className="label-container">
          <div className="heading-medium bold font-medium">
            Room details
          </div>
        </div> */}

          <div className="adult-cont full-width display-flex-row">
            {adultsList.map((currAdult: Adult, j: number) => {
              return (
                // display-flex-row space-between align-items full-width
                <div className="adult-cont-content form-label-input-container display-flex-row" key={j} style={{ border: "" }}>
                  <div className="label-container">
                    <div className="heading-medium font-normal">
                      Adult {j + 1}
                    </div>
                  </div>
                  <div className="input-container" style={{ width: "" }}>
                    <div className="input-container position-relative">
                      <div className="required-text">Required</div>
                      <input className={`input`} name="" />
                      <ul className="color-danger error font-small"></ul>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>


          <div className="child-cont full-width display-flex-row marginTop10">
            {chidlrenList.map((currAdult: Adult, j: number) => {
              return (
                <div className="child-cont-content form-label-input-container display-flex-row" key={j}>
                  <div className="label-container">
                    <div className="heading-medium font-normal">
                      Child {j + 1} (11 Years)
                    </div>
                  </div>
                  <div className="input-container" style={{ width: "" }}>
                    <div className="input-container position-relative">
                      <div className="required-text">Required</div>
                      <input className={`input`} name="" />
                      <ul className="color-danger error font-small"></ul>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>


          <div className="full-width ">
            <div className="form-label-input-container full-width">
              <div className="label-container">
                <div className="heading-medium font-normal">
                  Upload your Pan card
                </div>
              </div>
              <UploadFile
                setSelectedFile={() => { }}
              />
            </div>
          </div>

          <div className="full-width ">
            <div className="form-label-input-container full-width">
              <div className="label-container">
                <div className="heading-medium font-normal">
                  Upload your Passport
                </div>
              </div>
              <UploadFile
                setSelectedFile={() => { }}
              />
            </div>
          </div>
        </div>

      </div>
    )
  }

  function TermsAndConditions() {
    return (
      <div className="terms&conditon-container padding10">
        <div className="cancellation-policy">
          <div className="heading-medium " style={{ borderBottom: "1px solid grey" }}>Cancellation Policy</div>
          <div className="terms&conditon-text heading4 color-dark" style={{ marginTop: "5px" }}>{props.currOpt?.cf_1041}</div>
        </div>

        <div className="cancellation-policy marginTop5">
          <div className="heading-medium" style={{ borderBottom: "1px solid grey" }}>Payment Policy</div>
          <div className="terms&conditon-text heading4 color-dark" style={{ marginTop: "5px" }}>{props.currOpt?.cf_1043}</div>
        </div>

        <div className="cancellation-policy marginTop5">
          <div className="heading-medium" style={{ borderBottom: "1px solid grey", }}>Terms & Conditions</div>
          <div className="terms&conditon-text heading4 color-dark" style={{ marginTop: "5px" }}>{props.currOpt?.terms_conditions}</div>
        </div>
      </div>
    )
  }


  return (
    <div className="">
      <div className="heading-large color-ex-dark line-height-1 capitalize">Booking Details</div>
      <Dates query={props.currOpt} />
      <BookingDetails />


      <div className="full-width white-card bg-color-ex-light shadow-light ion-padding ion-margin-top">
        <div className="heading-large color-ex-dark line-height-1 capitalize marginTop10">Guest Details</div>
        {/* <p className="line-height-1" style={{ display: "flex", justifyContent: "flex-end", margin: "5px 10px", color: "blue", fontSize: "15px" }}>Room 1</p> */}
        <RoomDetails />
      </div>

      <div className="heading-large color-ex-dark line-height-1 capitalize marginTop10">Terms & Condition</div>

      <TermsAndConditions />

      <div className="form-label-input-container padding10">
        <div className="display-flex-row no-wrap">
          <div><IonCheckbox></IonCheckbox></div>
          <div className="input-label marginleft10">I accept the Terms & Condition for booking</div>
        </div>

        <div className="display-flex-row no-wrap">
          <div><IonCheckbox></IonCheckbox></div>
          <div className="input-label marginleft10">I want to recieve the whatsapp messages</div>
        </div>

        <div className="display-flex-row no-wrap">
          <div><IonCheckbox></IonCheckbox></div>
          <div className="input-label marginleft10">I confirm that I am authorized to register on this portal on behalf of my company. I also confirm that all the details provided here are true and accurate</div>
        </div>
      </div>

      <div className="center-aligned-column ion-padding full-width">
        <Button2
          configObj={{}}
          action={() => { }}
          label={'Confirm booking'}
          fontAwesomeIcon={"fas fa-chevron-right color-white"}
          iconPos=""
          classNames={"shadow-light login-button full-width center-aligned-column  bg-color-primary"}
        />
      </div>



    </div>
  );
};

export default EditDetails;
