
import React from "react";
import { createOpportunity } from "../../../../services/api";

const Other: React.FC<{
  formData: any
  setCurrStep: (val: string) => void
}> = (props) => {

  function nextStep() {
    console.log(props.formData)
    createOpportunity(props.formData).then(data => {
      console.log(data)
      props.setCurrStep("step6");
    }).catch(err => {
      console.log(err)
      alert("Error creating query")
    })
  }

  function onchangeTime(value: string) {
    props.formData.setCity(value)
  }

  function onChangeWillingToContinue(event: any) {
    props.formData.setWillingToContinue(event.target.value)
    // console.log(event.target.value);
  }


  return (
    <div className="full-width input-wrapper style" style={{ marginTop: "20px" }}>
      <div className="label full-width">How soon are you willing to continue?  <span className="mandatory color-danger font-medium">*</span></div>
      <div className="display-flex-row no-wrap space-between marginTop10">

        <div className="display-flex-row no-wrap " >
          <div className="center-aligned-row marginRight5">
            <input type="radio" name="time" value="Immediately" onClick={(e) => onChangeWillingToContinue(e)}></input>
          </div>
          <div className="heading4 center-aligned-column text-center">Immediately</div>
        </div>

        <div className="display-flex-row no-wrap ">
          <div className="center-aligned-row marginRight5">
            <input type="radio" name="time" value="Within 24 hours" onClick={(e) => onChangeWillingToContinue(e)}></input>
          </div>
          <div className="heading4 center-aligned-column text-center">Within 24 hours</div>
        </div>

        <div className="display-flex-row no-wrap ">
          <div className="center-aligned-row marginRight5">
            <input type="radio" name="time" value="In next few days" onClick={(e) => onChangeWillingToContinue(e)}>
            </input></div>
          <div className="heading4 center-aligned-column text-center">In next few days</div>
        </div>

        {/* <div className="display-flex-row no-wrap ion-margin-bottom" style={{marginTop:"30px"}}>
                <div className="center-aligned-row marginRight5"><input type="radio" name="time" value="Not sure, just checking"></input></div>
                <div className="label center-aligned-column text-center">Not sure, just checking</div>
            </div> */}

      </div>
    </div>



  )
};

export default Other;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
