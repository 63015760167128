import React, { useEffect, useState } from "react";
import { specialRequestValidation } from "../../../../utils/forms/validation";

const CitiesToCovered: React.FC<{
  formData: any
}> = (props) => {

  function onChangeCitiesToCovered(event: any) {
    specialRequestValidation(event);
    props.formData.setCitiesToCover(event.target.value);
  }

  return (
    <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
      <div className="label full-width">Cities to Cover <span className="mandatory color-danger">*</span></div>
      <div className="input-wrapper">
        <div className="center-aligned-row">
          <textarea defaultValue={props.formData.cities_to_cover} onChange={(event) => onChangeCitiesToCovered(event)} rows={2} cols={50} className="search-input input" style={{ height: "unset" }} placeholder='Mention cities to be cover' />
        </div>
        <div className="display-flex-row marginTop5" style={{ marginLeft: "20px" }}>
          <ul className="color-danger error font-small"></ul>
        </div>
      </div>
    </div>
  )
}


export default CitiesToCovered;