
import React from "react";

const TourEnd: React.FC<{
   formData: any
}> = (props) => {
 
    function onChangeTourEndAt(value: string){
        props.formData.setTourEndAt(value);
    }

    return (
        <div className="full-width display-flex-row no-wrap space-between" >
            <div className="full-width">
                <div className="label full-width">End Tour At <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <input className="input search-input" defaultValue={props.formData.end_tour_at} onChange={(event) => onChangeTourEndAt(event.target.value)} type="text" placeholder="Toue Ends At" />
                </div>
            </div>
        </div>
    )
}

export default TourEnd;