import React from "react";
import { getStatusClass } from "../../../../utils/methods";

const Header: React.FC<{
  query: any
  setCurrTab?: (val: string) => void
  closeQuery?: () => void
}> = (props) => {
  //Curr page can be leads list page or details page

  function gotoquerypage() {
    if (props.closeQuery) {
      props.closeQuery()
    } else {
      window.history.back()
    }

    // window.history.back()
  }
  
  return (
    <div className="display-flex-row no-wrap space-between ion-padding bg-color-primary" style={{ height: "4rem" }}>
      <div className="display-flex-row no-wrap" style={{ alignItems: "center" }}>
        {/* {!props.closeQuery ?
          <div className="center-aligned-column"><i onClick={gotoquerypage} className="fas fa-chevron-left font-medium color-white"></i></div>
          : null} */}
        
        <div className="center-aligned-column"><i onClick={gotoquerypage} className="fas fa-chevron-left font-medium color-white "></i></div>
        <div className="heading3 font-large marginleft10 color-white header-query-content">Query ID: {props.query.potential_no}</div>
        <div className={`heading4 white-card header-query-status no-shadow marginleft10 center-aligned-column color-${getStatusClass(props.query.sales_stage)}`} style={{ padding: "0.5rem 1rem", backgroundColor: "rgba(255,255,255,1)" }}>{props.query.sales_stage}</div>

      </div>
      <div className="center-aligned-column">
        <div className="display-flex-row no-wrap">
          {props.closeQuery ?
            <div onClick={props.closeQuery} className="center-aligned-column pointer"> <i className="fas fa-times font-large marginleft10 color-white header-cancle-btn"></i> </div>
            : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
