import {
    IonInput, IonItem,
    IonLabel
} from "@ionic/react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCities, login } from "../../../services/api";
import { applicationConfig, setUserSession } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import Loader from "../../common/ui-components/Loader";
import { disable } from "workbox-navigation-preload";
import { showToast } from "../../../utils/UI_methods/global";
import Optreceived from "./Otpreceived"
import Emailotpget from "./Emailotpreceives";
import {validateEmail} from "../../../utils/forms/validation";
import {sendEmailotp} from "../../../services/api";
var md5 = require('md5');


const Emailverify: React.FC<{

}> = (props) => {

    const history = useHistory();

    const [emailotptext, setEmailotpText] = useState<any>("");
    const [emailotp, setEmailotp] = useState<boolean>(false)
    const [email, setEmail] = useState("");
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [reCaptchaToken, setRecaptchaToken] = useState<any>(null);

    // useEffect(() => {
    //     let emlotptext: any = Math.random().toString(9).substring(2, 8);
    //     console.log("Random.........", emlotptext);
    //     setEmailotpText(emlotptext);
    // }, [])

    function gotoEmailOptConfirm() {
        let emlotptext: any = Math.random().toString(9).substring(2, 8);
        console.log("Random.........", emlotptext);
        setEmailotpText(emlotptext);

        let email = getEmail();
        let data = {
            emailId : email.emailID,
            otp: emlotptext,
        }
        // console.log(email.emailID);
        // console.log(emailotptext);
        sendEmailotp(data).then((response: any) => {
             let responseData = response.data.data;
             console.log("@@@@@@@@@@@@@@@@@@",responseData);
             setEmailotp(true);
        }).catch((error: any) => {
            console.log("Error Message", error)
        })

        // setEmailotp(true);
    }

    function getEmail() {
        return {
            emailID: $("#emailID").val(),
          }
    }

    

    return (

        (emailotp ? <Emailotpget setEmailotp={setEmailotp} emailotptext={emailotptext} /> :

            <div className="login-container position-relative" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "45px" }}>

                <div className="full-width center-aligned-column">
                    <img width={250} src="assets/images/travel_power_logo.png" alt="" />
                </div>

                {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

                <p className="text-center font-normal heading1" style={{ fontWeight: "bold" }}>REGISTER TO GET POWER IN YOUR HANDS</p>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Enter Your Email</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} onChange={validateEmail} id="emailID" type="text" defaultValue={email} className="input search-input" name="" >
                        </input>
                        <ul className="color-danger error font-small"></ul>
                    </div>
                </div>

                <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
                    <Button2
                        configObj={{}}
                        action={gotoEmailOptConfirm}
                        label={loading ? 'Please wait...' : 'Next'}
                        fontAwesomeIcon={loading ? " " : "/assets/images/Vector.png"}
                        iconPos="right"
                        classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
                    />

                </div>




            </div>
        )
    )

};

export default Emailverify;
