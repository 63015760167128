import $ from "jquery"

export function nameValidation(event:any) {
    let value = event.target.value
    let errors:Array<any> = []
    let errMsgHolder = $(event.target).parent().find(".error")
    let res =  value.match(/^[A-Za-z\s]+$/);

    // if(!value.match(/^[A-Z]/)){
    //     errors.push('First char should be upper case')
    // }
    
    if(res == null){
        errors.push('Only alphabats are allowed')
    }
    // if(value.length < 5){
    //     errors.push('Min length 5')
    // }
    if(value.length > 50){
        errors.push('Max length 50')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function organizationValidation(event:any) {
    console.log(event);
    let value = event.target.value
    let errors:Array<any> = []
    let errMsgHolder = $(event.target).parent().find(".error")
    console.log(errMsgHolder);
    // let res =  value.match(/^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=._-]+$/g);
    var res = value.match(/^[a-zA-Z0-9\s@&.]+$/g);
    if(res == null){
        errors.push('May contains number,alphabats or symbols')
    }
    // if(value.length < 3){
    //     errors.push('Min length 3')
    // }
    if(value.length > 100){
        errors.push('Max length 100')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function brandNameValidation(event:any) {
    let value = event.target.value
    let errors:Array<any> = []
    let errMsgHolder = $(event.target).parent().find(".error")
    // let res =  value.match(/^[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=._-]+$/g);
    var res = value.match(/^[a-zA-Z0-9&-()@*#. +% _$]+$/g);
    if(res == null){
        errors.push('May contains number,alphabats or symbols')
    }
    // if(value.length < 3){
    //     errors.push('Min length 3')
    // }
    if(value.length > 100){
        errors.push('Max length 100')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}


function getErrors(errors:any) {
    let html = ""
    errors.forEach((err:any) => {
        html = html + `<li>${err}</li>`
    });
    return html
}

export function websiteValidation(event:any) {
    let value = event.target.value
    let errors:Array<any> = []
    // var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    var res = value.match(/^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9-:./]+\.[a-zA-Z]{2,}(\.[a-zA-Z0-9-:./]{2,})?$/g);
    let errMsgHolder = $(event.target).parent().find(".error")
    if(res == null){
        errors.push('Invalid url')
    }
    if(value.length < 10){
        errors.push('Min length 10')
    }
    if(value.length > 50){
        errors.push('Max length 50')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function phoneValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/);
    var res = value.match(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/);
    // NEW: ((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}
    if(res == null){
        errors.push('Invalid mobile number')
    }
    if(value.length < 10){
        errors.push('Min length 10')
    }
    if(value.length > 17){
        errors.push('Max length 16')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}


export function validateEmail(event:any){
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    let res =  value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if(res == null){
        errors.push('Invalid email address')
    }
    if(value.length < 3){
        errors.push('Min length 3')
    }
    if(value.length > 50){
        errors.push('Max length 50')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
  };


  export function numberValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    var res = value.match(/^\d*$/);
    if(res == null){
        errors.push('Should be numbers only')
    }
    if(value < 1){
        errors.push('Min value 1')
    }
    if(value > 100){
        errors.push('Max value 100')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function gstinValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^\w+$/);
    // var res = value.match(/[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/);
    var res = value.match(/^\d{2}[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/);
    if(res == null){
        errors.push('Invalid gst number')
    }
    if(value.length < 15){
        errors.push('Min length 15')
    }
    if(value.length > 15){
        errors.push('Max length 15')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function panValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    // var res = value.match(/[A-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/);
    var res = value.match(/[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/);
    if(res == null){
        errors.push('Invalid pan number')
    }
    if(value.length < 10){
        errors.push('Min length 10')
    }
    if(value.length > 15){
        errors.push('Max length 10')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function addressValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    var res = value.match(/^[a-zA-Z0-9\s,./-]+$/g);
    if(res == null){
        errors.push('Special symbols are not allowed')
    }
    if(value.length < 20){
        errors.push('Min length 20')
    }
    if(value.length > 100){
        errors.push('Max length 100')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}


export function postalCodeValidation(event:any) {
    let value = event.target.value
    let errMsgHolder = $(event.target).parent().find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^\d*$/);
    var res = value.match(/^\w+$/);
    if(res == null){
        errors.push('Should be numbers only')
    }
    if(value.length < 5){
        errors.push('Min length 5')
    }
    if(value.length > 6){
        errors.push('Max length 6')
    }
    if(!value.length){
        errors = []
    }
    errMsgHolder.html(getErrors(errors))
    return errors

}

export function mandatoryFieldAction(event:any,isMandatory:boolean) {
    if(isMandatory){
        let value = event.target.value
        if(!value.length){
            $(event.target).addClass("mandatory")
            $(event.target).parent(".input-container").find(".required-text").addClass("--is-active")
        }else{
            $(event.target).removeClass("mandatory")
            $(event.target).parent(".input-container").find(".required-text").removeClass("--is-active")
        }
        
    }
}

export function highlightMandatoryInputs() {
    let searchInputs = document.querySelectorAll(".mandatory-field")
    for (let i = 0; i < searchInputs.length; i++) {
        const currSearchInput:any = $(searchInputs[i]) || null
        if(!currSearchInput.val().length){
            currSearchInput.addClass("border-danger")
        }else{
            currSearchInput.removeClass("border-danger")
        }
    }
}

export function removeMandatoryClass(event:any) {
    $(event.target).removeClass("border-danger")
}


export function noOfRoomsValidation(event:any) {
    let value = Number(event.target.value)
    let errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^\d*$/);
    if(value < 1){
        errors.push('Min value 0')
    }
    if(value >= 999){
        errors.push('Max value 9')
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function noOfAdultsValidation(event:any) {
    let value = Number(event.target.value)
    let errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^\d*$/);
    if(value < 1){
        errors.push('Min value 1')
    }
    if(value >= 999){
        errors.push('Max value 9')
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function noOfChidlrenValidation(event:any) {
    let value = Number(event.target.value)
    let errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    let errors:Array<any> = []
    // var res = value.match(/^\d*$/);
    if(value < 0){
        errors.push('Min value 0')
    }
    if(value >= 999){
        errors.push('Max value 9')
    }
    errMsgHolder.html(getErrors(errors))
    return errors
}

export function hotelNameValidation(event:any,value:any = null) {
    let errMsgHolder = null
    if(event){
        value = String(event.target.value)
        errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    }
    let errors:Array<any> = []
    // value.match(/^[a-zA-Z0-9&-()@*#. +% _$]+$/g)
    var res = value.match(/^[a-zA-Z0-9&-,-()@*#. +/ $]+$/g);
    if(res == null){
        errors.push('May contains number,alphabats or symbols')
    }
    if(value.length < 3){
        errors.push('Min value 3')
    }
    if(value.length >= 100){
        errors.push('Max value 100')
    }
    if(errors.length){
        errMsgHolder?.html(getErrors(errors))
        $(event?.target).addClass("input-error")
    }else{
        errMsgHolder?.html("")
        $(event?.target).removeClass("input-error")
    }
    return errors
}

export function specialRequestValidation(event:any, value:any = null){
    let errMsgHolder = null
    if(event){
        value = String(event.target.value)
        errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    }
    let errors:Array<any> = []
    // value.match(/^[a-zA-Z0-9&-()@*#. +% _$]+$/g)
    var res = value.match(/^[a-zA-Z0-9&-,-()@*#. +/ $]+$/g);
    if(res == null){
        errors.push('May contains number,alphabats or symbols')
    }
    if(value.length < 3){
        errors.push('Min value 3')
    }
    if(value.length >= 1000){
        errors.push('Max value 100')
    }
    if(errors.length){
        errMsgHolder?.html(getErrors(errors))
        $(event?.target).addClass("input-error")
    }else{
        errMsgHolder?.html("")
        $(event?.target).removeClass("input-error")
    }
    return errors
}

export function hotelBudgetValidation(event:any,value:any = null) {
    let errMsgHolder = null
    if(event){
        value = String(event.target.value)
        errMsgHolder = $(event.target).parents(".input-wrapper").find(".error")
    }
    let errors:Array<any> = []
    // var res = value.match(/^\d*$/);
    if(value < 0){
        errors.push('Min value 0')
    }
    if(value >= 99999){
        errors.push('Max value 99999')
    }
    if(errors.length){
        errMsgHolder?.html(getErrors(errors))
        $(event?.target).addClass("input-error")
    }else{
        errMsgHolder?.html("")
        $(event?.target).removeClass("input-error")
    }
    return errors
}


export function checkIfErrorExists() {
    return $(".create-query-wrapper").find(".error").find("li").length || 0
}