import React, { useEffect, useState } from "react";
import "../style/home/home.css"

import { IonPage } from "@ionic/react";
import Button2 from "../common/ui-components/buttons/Button2";


const Profile: React.FC<{
}> = (props) => {


  function HeaderMiddleComponent() {
    return(
      <div></div>
    )
  }

  function HeaderLeftComponent() {
    return(
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading-primary font-medium">Profile Information</div>
      </div>
    )
  }
  return(
    <IonPage>
    <div className="page positon-relative home-page-wrapper full-height-width" >



<div className="display-flex-row full-width header space-between" >
            <div className="center-aligned-column"> <i className="fas fa-bars font-large color-black"></i> </div>
            <div className="heading1 font-medium center-aligned-column">Home</div>
            <div className="profile">
              <img src="/assets/images/home/profile.png" width={30} height={30} alt="" />
            </div>

           

         </div>


          <div className="ion-padding bottom-right">
            <div className="display-flex-row">
              <img src="/assets/images/rsk.png" width={70} alt="" />
              <div className="heading1 marginleft10 color-dark center-aligned-column">RSK Travel Consultant</div>
            </div>
          </div>

       

         <div className="center-aligned-column top-left full-width" style={{height:"100%"}}>
            <div className="home-page-options center-aligned-column full-width">

              <div className="option white-card  display-flex-row space-between" >
                  <div className="center-aligned-column ">
                    <img src="/assets/images/booking.png" width={50} alt="" />
                  </div>
                  <div className=" color-black heading2 text">Booking</div>
              </div>

              <div className="option white-card  display-flex-row space-between" style={{marginTop:"40px"}}>
                <div className="center-aligned-column ">
                    <img src="/assets/images/service.png" width={50} alt="" />
                </div>
                <div className=" color-black heading2 text">Consulting</div>
              </div>

            </div>
         </div>

    </div>
  </IonPage>  
  )
   
};

export default Profile;
