export class cabBooking{

    start_tour_from:string = ""
    end_tour_at:string = ""
    date_of_arrival:string = ""
    date_of_arrival_time:string = ""
    date_of_lastDrop:string = ""
    date_of_lastDrop_time:string = ""
    places_to_be_covered:string = ""
    no_of_travellers:number = 0
    preferred_vehicle:string = ""
    special_requirements:string = ""
    guest_nationality:string = ""
    how_soon_are_you_willing_to_continue: string = ""

    // room_details:Rooms = new Rooms()
    // children_list = []
    
    constructor(){

    }

    setTourStartFrom(value: string){
        this.start_tour_from = value;
    }

    setTourEndAt(value: string){
        this.end_tour_at = value;
    }

    setDateOfArrival(value: string){
        this.date_of_arrival = value;
    }

    setDateOfLastDrop(value: string){
        this.date_of_lastDrop = value;
    }

    setPlacesCovered(value: string){
        this.places_to_be_covered = value;
    }

    setNoOfTravellers(value: number){
        this.no_of_travellers = value;
    }

    setPreferredVehicle(value: string){
        this.preferred_vehicle = value;
    }

    setSpecialRequirements(value: string){
        this.special_requirements = value;
    }
    
    setGuestNationality(value: string){
        this.guest_nationality = value;
    }

    setWillingToContinue(value:string){
        this.how_soon_are_you_willing_to_continue = value
    }

}

 