import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getQueryList } from "../../../../services/api";
import QueryDetailsContainer from "./QueryDetailsContainer";
 
const QueryDetails: React.FC<{}> = (props) => {
  //Curr page can be leads list page or details page

  const [currTab, setCurrTab] = useState<string>("details");

  const [query,setQuery]:Array<any> = useState({})



  useEffect(()=>{
    const url = window.location.href;
    const urlParts = url.split('/');
    const id = urlParts[urlParts.length - 1];
    getQueryList().then((data:any)=>{
      let list:Array<any> = JSON.parse(data.data.opportunities_list)
      list.reverse()
      list = list.find(currQuery=>currQuery.id === String(id))
      setQuery(list)
      console.log(list)
    }).catch(err=>{
      console.log(err)
    })
  },[])

 

  return (
    <IonPage>
        <div className="full-height-width" >
          <QueryDetailsContainer query={query}/>
        </div>
    </IonPage>
  );
};

export default QueryDetails;
