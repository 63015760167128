
import React, { useEffect, useState } from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
import DatePicker from "react-multi-date-picker";
import { returnUpForwardOutline } from "ionicons/icons";
import { event } from "jquery";
import { showToast } from "../../../../utils/UI_methods/global";

const  Date_TimeOfLastDrop: React.FC<{
  formData: any
  arrivalDate: any
}> = (props) => {

  const timeHrs = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

  const timeMinutes = ["00", "15", "30", "45"];

  const [refreshDate, setRefreshDate] = useState(Number(new Date()))

  const [lastDropDateTimeHrs, setLastDropDateTimeHrs] = useState();
  const [lastDropDateTimeMinutes, setLastDropDateTimeMinutes] = useState();

  useEffect(() => {
    props.formData.date_of_lastDrop_time = `${lastDropDateTimeHrs}:${lastDropDateTimeMinutes}`
  }, [lastDropDateTimeHrs && lastDropDateTimeMinutes])

  function diffDate(val1: any, val2: any) {
    let date1 = new Date(val1);
    let date2 = new Date(val2);

    // Calculating the time difference
    // of two dates
    let Difference_In_Time =
      date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days =
      Math.round
        (Difference_In_Time / (1000 * 3600 * 24));

    return Difference_In_Days;
  }

  function onChangeDateOfLastDrop(event: any) {
    let startDateEvent = event
    let startDate = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
    let daysOfTrip = diffDate(props.formData.date_of_arrival, startDate);
    
    if (new Date(startDate) < new Date(props.formData.date_of_arrival)) {
      showToast("Last Drop date should be must greater than arrival date", 3000, "error");
      setRefreshDate(Number(new Date()));
      props.formData.setDateOfLastDrop(" ")
      return;
    } else {
      showToast(daysOfTrip === 0 ? `Cab Booked for ${daysOfTrip + 1} Day` :`Cab Booked for ${daysOfTrip} Nights ${daysOfTrip + 1} Days`, 3000, "success");
      props.formData.setDateOfLastDrop(startDate);
    }
  }

  function onChangeDateOfArrivalTimeHrs(event: any){
    setLastDropDateTimeHrs(event.target.value)
  }

  function onChangeDateOfArrivalTimeMinutes(event: any){
    setLastDropDateTimeMinutes(event.target.value)
  }


  return (

    <div className="full-width" key={refreshDate}>
      <div className="label full-width" style={{ border: "" }}>Date & Time of Last Drop<span className="mandatory color-danger">*</span></div>
      <div className="date&time_container" style={{ border: "", display: "flex", gap:"10px"}}>
        <div className="DateSelect" style={{ width: "" }} >
          <DatePicker
            value={props.formData.date_of_lastDrop}
            range={false}
            minDate={new Date(props.arrivalDate)}
            onChange={onChangeDateOfLastDrop}
            containerClassName="range-date-container"
            style={{ width: "150px" }}
            className="rmdp-mobile mandatory-field"
            placeholder="Select Date"
          />
        </div>

        <div className="TimeSelectHrs">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => {onChangeDateOfArrivalTimeHrs(event)}}>
            <option>Hrs</option>
            {
              timeHrs.map((currHrs, index) => {
                return <option key={index}>{currHrs}</option>
              })
            }

          </select>
        </div>

        <div className="TimeSelectMinutes">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => {onChangeDateOfArrivalTimeMinutes(event)}}>
            <option>Min</option>
            {
              timeMinutes.map((currMinute, index) => {
                return <option key={index}>{currMinute}</option>
              })
            }
          </select>
        </div>

      </div>
    </div>
  )
};

export default Date_TimeOfLastDrop;

