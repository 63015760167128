
import React, { useEffect, useState } from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
import DatePicker from "react-multi-date-picker";
import { returnUpForwardOutline } from "ionicons/icons";
import { showToast } from "../../../../utils/UI_methods/global";
import { cleanData } from "jquery";

const Date_TimeOfDeparture: React.FC<{
  formData: any
  arrivalDate: any
}> = (props) => {

  const timeHrs = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

  const timeMinutes = ["00", "15", "30", "45"];

  const [refreshDate, setRefreshDate] = useState(Number(new Date()))

  const [departureDateTimeHrs, setDepartureDateTimeHrs] = useState();
  const [departureDateTimeMinutes, setDepartureDateTimeMinutes] = useState();

  useEffect(() => {
    props.formData.date_of_departure_Time = `${departureDateTimeHrs}:${departureDateTimeMinutes}`
  }, [departureDateTimeHrs && departureDateTimeMinutes])

  function diffDate(val1: any, val2: any) {
    let date1 = new Date(val1);
    let date2 = new Date(val2);

    // Calculating the time difference
    // of two dates
    let Difference_In_Time =
      date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days =
      Math.round
        (Difference_In_Time / (1000 * 3600 * 24));

    return Difference_In_Days;
  }

  
  function onChangeDatesOfDeparture(event: any) {
    let startDateEvent = event
    let startDate = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
    let daysOfTrip = diffDate(props.formData.date_of_arrival, startDate);
    
    if (new Date(startDate) < new Date(props.formData.date_of_arrival)) {
      showToast("Departure date should not be smaller than arrival date", 3000, "error");
      setRefreshDate(Number(new Date()));
      props.formData.setDepartureDate(" ")
      return;
    } else {
      showToast(daysOfTrip === 0 ? `Package Duration ${daysOfTrip + 1} Day` : `Package Duration ${daysOfTrip} Nights ${daysOfTrip + 1} Days`, 3000, "success");
      props.formData.setDepartureDate(startDate);
    }
  }

  function onChangeDatesOfDepartureTimeHrs(event: any) {
    setDepartureDateTimeHrs(event.target.value)
  }

  function onChangeDatesOfDepartureTimeMinutes(event: any) {
    setDepartureDateTimeMinutes(event.target.value)
  }

  return (

    <div className="full-width" key={refreshDate}>
      <div className="label full-width" style={{ border: "" }}>Date & Time of Departure <span className="mandatory color-danger">*</span></div>
      <div className="date&time_container" style={{ border: "", display: "flex", gap: "10px" }}>
        <div className="DateSelect" style={{ width: "" }} >
          <DatePicker
            value={props.formData.date_of_departure}
            range={false}
            minDate={new Date(props.arrivalDate)}
            onChange={onChangeDatesOfDeparture}
            containerClassName="range-date-container"
            style={{ width: "150px" }}
            className="rmdp-mobile mandatory-field"
            placeholder="Select Date"
          />
        </div>

        <div className="TimeSelectHrs">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => { onChangeDatesOfDepartureTimeHrs(event) }}>
            <option>Hrs</option>
            {
              timeHrs.map((currHrs, index) => {
                return <option key={index}>{currHrs}</option>
              })
            }

          </select>
        </div>

        <div className="TimeSelectMinutes">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => { onChangeDatesOfDepartureTimeMinutes(event) }}>
            <option>Min</option>
            {
              timeMinutes.map((currMinute, index) => {
                return <option key={index}>{currMinute}</option>
              })
            }
          </select>
        </div>

      </div>
    </div>
  )
};

export default Date_TimeOfDeparture;

