import axios from "axios";
import { DeviceUUID } from "device-uuid";
import { getToken, getUser, getUserType } from "../utils/methods";
import { promises, resolve } from "dns";
import { rejects } from "assert";
var md5 = require('md5');

const endPointForCities = "https://wft-geo-db.p.rapidapi.com/v1"
// const BASE_PATH = "http://localhost:3100/" 
// const BASE_PATH = "http://52.7.134.197:443/"
// const BASE_PATH = "http://65.0.96.155:3100/"
// const BASE_PATH = "http://13.232.31.47:3100/"
const BASE_PATH = "http://13.126.16.248:3100/"


export async function getTextures(clientId: number, applicationId: number) {
  const response = await fetch(`${BASE_PATH}api/productmaterials/${clientId}&${applicationId}`);
  const jsonResponse = await response.json();
  if (response.status == 200 || response.status == 404) {
    return Promise.resolve(jsonResponse.data)
  } else {
    return Promise.reject(jsonResponse.message)
  }
}


// export const getToken = (username:string) => {
//     let data = {
//         username:username,
//       };

//       return axios
//         .get(
//           `${BASE_PATH}`,{
//           params: {
//             username: "api.node1",
//             operation:"getchallenge"
//           },
//         }
//         )
// }


export const login = (data: any) => {
  return new Promise((resolve, reject) => {

    const timeoutId = setTimeout(() => {
      reject(new Error("Request timed out. Please try again"));
    }, 10000);

    axios.post(`${BASE_PATH}api/signin`,
      // {
      //   login_username:"username@rsk",
      //   agency_code: "DEMO18",
      //   login_password: md5("password"),
      //   device_id:new DeviceUUID().get()
      // },
      {
        login_username: data.userName,
        agency_code: data.agencyCode,
        login_password: md5(data.password),
        device_id: new DeviceUUID().get()
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // console.log("??????????????????", response);
        clearTimeout(timeoutId);
        resolve(response)
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        reject(error)
      });
  })
};

export const registerUser = (data: any) => {
  let path = `${BASE_PATH}api/register`
  // let path = `http://54.156.216.94/tpstage/api/create_lead.php`
  return new Promise((resolve, reject) => {
    axios.post(path,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "http_x_authorization": "stageapi",
          "http_x_api_key": "Hyj5ydTsgJM4iJ5"
        }
      }
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })

};

export const createOpportunity = (data: any) => {
  let path = `${BASE_PATH}api/createOpportunity`
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        ...data,
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser().userId,
        company_id: getUser().accountId
      },
    )
      .then((response) => {
        // console.log(response)
        resolve(response)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      });
  })
};

export const createVisaQuery = (data: any) => {
  let path = `${BASE_PATH}api/createVisaQuery`
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        ...data,
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser().userId,
        company_id: getUser().accountId
      },
    )
      .then((response: any) => {
        // console.log("response jo aa raha hai", response);
        resolve(response)
      })
      .catch((error) => {
        // console.log("error jo aa raha hai", error);
        reject(error)
      });
  })
};

export const saveLogs = (data: any) => {
  let path = `${BASE_PATH}api/createLogs`
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  const browserInfo = {
    userAgent: window.navigator.userAgent, // User-Agent string
    // appName: window.navigator.appName,     // Browser name
    // appVersion: window.navigator.appVersion, // Browser version
    // platform: window.navigator.platform,     // Operating system
    // language: window.navigator.language,     // User's preferred language
    // cookiesEnabled: window.navigator.cookieEnabled, // Whether cookies are enabled
  };
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        logs: data,
        session_id: getToken() || "",
        device_id: new DeviceUUID().get(),
        login_username: getUser()?.userId || "",
        company_id: getUser()?.accountId || "",
        device_decscription: JSON.stringify(browserInfo)
      },
    )
      .then((response) => {
        // console.log(response);
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })
};


export const getQueryList = () => {
  let path = `${BASE_PATH}api/getQueryList`
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser().userId,
        company_id: getUser().accountId
      },
    )
      .then((response) => {
        console.log("response of all queries", response);
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })
};

export const getQuotesList = (queryId: string) => {
  let path = `${BASE_PATH}api/getQuoteList`
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser().userId,
        company_id: getUser().accountId,
        queryId: queryId,
        quoteId: queryId,
      },
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })
};

export const getVisaQuotesList = (queryId: string) => {
  let path = `${BASE_PATH}api/getVisaQuotesList`;
  // let path = "http://54.156.216.94/tpstage/api/create_opportunity.php"
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser().userId,
        company_id: getUser().accountId,
        queryId: queryId,
        quoteId: queryId,
      },
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })
};

export const getServicesList = () => {
  let path = `${BASE_PATH}api/getServicesList`
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser()?.userId,
        company_id: getUser()?.accountId
      },
    )
      .then((response) => {
        // console.log("getServiceList response",response);
        resolve(response);
      })
      .catch((error) => {
        reject(error)
      });
  })

};




export const getCities = (prefix: any) => {

  return new Promise((resolve, reject) => {
    axios.get(`${endPointForCities}/geo/cities`,
      {
        headers: {
          "x-rapidapi-key": "c91171d01dmshdacad720c50e08fp1e645ejsn38d13c55f230"
        },
        params: {
          namePrefix: prefix
        }
      }
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      });
  })
};




export function logout() {
  const user = getUser()
  const username = user.username
  return axios
    .post(
      `${BASE_PATH}api/logout`,
      {
        username: username
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
}

export const sendEmailotp = (data: any) => {
  console.log("data", data);
  return new Promise((resolve, reject) => {

    axios.post(`${BASE_PATH}api/sendEmailOTP`,
      // {
      //   login_username:"username@rsk",
      //   agency_code: "DEMO18",
      //   login_password: md5("password"),
      //   device_id:new DeviceUUID().get()
      // },
      {
        emailID: data.emailId,
        otp: data.otp,
        device_id: new DeviceUUID().get()
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("response*******", response);
        // clearTimeout(timeoutId);
        resolve(response)
      })
      .catch((error) => {
        console.log("error*******", error);
        // clearTimeout(timeoutId);
        reject(error)
      });
  })

}

export const withDrawQuery = (data: any) => {
  let path = `${BASE_PATH}api/withDrawQuery`;

  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        Query_id: data.QueryId,
        reasonForCancellation: data.ReasonForCancellation,
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser()?.userId,
        company_id: getUser()?.accountId
      }
    ).then((response) => {
      resolve(response);
    }).catch((err) => {
      reject(err);
    })
  })
}

export const validateSession = () => {
  let path = `${BASE_PATH}api/ValidateSession`
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser()?.userId,
        // company_id: getUser()?.accountId
      },
    )
      .then((response) => {
        console.log("validate response", response);
        // resolve(response);
      })
      .catch((error) => {
        console.log("validate Error", error);
        // reject(error)
      });
  })

};

export const getNationalityList = () => {
  let path = `${BASE_PATH}api/getNationalitiesList`;
  return new Promise((resolve, reject) => {
    axios.post(path,
      {
        session_id: getToken(),
        device_id: new DeviceUUID().get(),
        login_username: getUser()?.userId,
        company_id: getUser()?.accountId
      },
    )
      .then((response) => {
        console.log("getNationalityList", response);
        // resolve(response);
      })
      .catch((error) => {
        console.log("getNationalityList", error);
        // reject(error)
      });
  })
}