import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { applicationConfig, getToken } from './methods';
// import { getToken } from './methods';
 
// handle the private routes
const PublicRoute:React.FC<{component:any;path:any;exact:any}> = ({ component: Component, ...rest }) => {
  useEffect(()=>{
    let route = window.location.pathname
    applicationConfig.logger.info("Loaded page: "+ route)
  },[])
  
  return (
    <Route
      {...rest}
      render={(props:any) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/queries' }} />}
    />
  )
}
 
export default PublicRoute;