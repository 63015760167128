import React, { useState } from "react";
import Details from "./Details";
import EditDetails from "./EditDetails";
import Footer from "./Footer";
import Header from "./Header";
import Options from "./Options";
 
const QueryDetailsContainer: React.FC<{
  query:any
  closeQuery?:()=>void
}> = (props) => {
  console.log("Ouery Data", props.query);
  //Curr page can be leads list page or details page
  
  const [currTab, setCurrTab] = useState<string>("details");
  const [currOpt, setCurrOpt]: any = useState(null)

  console.log("CurrOPtion is", currOpt);

  return (
      <div className="full-height-width" >
        <Header
          query={props.query}
          setCurrTab={setCurrTab}
          closeQuery={props.closeQuery}
        />
        <div className="header-content-wrapper padding10 fill-available-height overflow-y-scroll padding10" style={{width:"100%",maxWidth:"55rem",margin:"auto",height:"calc(100% - 4rem)"}}>
          {currTab === "details" ?
            <>
            <Details
              query={props.query}
            />
            <Options
              setCurrOpt={setCurrOpt}
              setCurrTab={setCurrTab}
              query={props.query}
            />
            <Footer
              setCurrTab={setCurrTab}
              query={props.query}
            />
            </>
          :currTab === "edit"?
            <EditDetails
              currOpt={currOpt}
              setCurrTab={setCurrTab}
              query={props.query}
            />
          :
          <></>
          }
        </div>
      </div>
  );
};

export default QueryDetailsContainer;
