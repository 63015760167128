import React, { useEffect, useState } from "react";
import { specialRequestValidation } from "../../../../utils/forms/validation";

const SpecialRequirements: React.FC<{
  formData: any
}> = (props) => {

    function onChangeSpecialRequirement(event: any){
        specialRequestValidation(event)
        props.formData.setSpecialRequirements(event.target.value);
    }

  return (
    <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
      <div className="label full-width">Special Requirements <span className="mandatory color-danger">*</span></div>
      <div className="input-wrapper">
        <div className="center-aligned-row">
          <textarea defaultValue={props.formData.special_requirements} onChange={(event) => onChangeSpecialRequirement(event)} rows={2} cols={50} className="search-input input" style={{ height: "unset" }} placeholder='Special Requirements' />
        </div>
        <div className="display-flex-row marginTop5" style={{marginLeft: "20px"}}>
          <ul className="color-danger error font-small"></ul>
        </div>
      </div>
    </div>
  )
}


export default SpecialRequirements;