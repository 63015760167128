import React, { useEffect } from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";
import Loader from "../../../common/ui-components/Loader";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";

const QueryCreatedSuccess: React.FC<{
  closeQuery: (val: boolean) => void
}> = (props) => {
  
  function backToQueriesAll() {
    props.closeQuery(false)
  }
  return (
    <div className="queryList100-container">
      <p>Query Created Successfully</p>
      <img src="/assets/icon/loading.gif" width={50} alt="" />
      {/* <button onClick={backToQueriesAll} className="queryList100-btn center-aligned-column bg-color-primary" style={{}}>OK</button> */}
    </div>
  );
};

export default QueryCreatedSuccess;
