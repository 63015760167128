
import React from "react";

const Nationality: React.FC<{
    formData: any
    setCurrStep: (val: string) => void;
}> = (props) => {

    const Nationality: string[] = ["Indian", "Austrailian", "Canadian", "German", "British", "American"]

    function onChangeNationality(value:string){
        props.formData.setNationality(value)
    }

    return (
        <div className="full-width display-flex-row no-wrap space-between" >

            <div className="full-width" style={{marginTop:"10px"}}>
                <div className="label full-width">Nationality <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <select className="input search-input" name="" defaultValue={props.formData.nationality} onChange={(event) => onChangeNationality(event.target.value)} >
                        <option>Select Nationality</option>
                        {
                            Nationality.map(currNation => {
                                return <option>{currNation}</option>
                            })
                        }
                    </select>
                </div>
            </div>

        </div>
    )
};

export default Nationality;

