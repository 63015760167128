
import React from "react";

const PreferredVehicle: React.FC<{
    formData: any
}> = (props) => {

    let Vehicles = ["Hatchback", "Sedan", "Luxary Sedan", "Basic SUV", "Luxury SUV", "Coach(9 to 12 seats)", "Coach(13 to 15 seats)", "Coach(16 to 27 seats)", "Coach(28 to 35 seats)", "Bus (35+)"]

    function onChangePreferredVehicle(value: string){
        props.formData.setPreferredVehicle(value);
    }

    return (
        <div className="full-width display-flex-row no-wrap space-between" >
            <div className="full-width">
                <div className="label full-width">Preferred Vehicle <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <select className="input search-input" name="" defaultValue={props.formData.preferred_vehicle} onChange={(event) => onChangePreferredVehicle(event.target.value)}>
                        <option>Select Vehicle</option>
                        {
                            Vehicles.map((vehicle) => {
                                return <option>{vehicle}</option>
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

export default PreferredVehicle;