  
  import { IonCheckbox, IonIcon } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import React, { useEffect } from "react";
import { QUERIES } from "../../../utils/data";
import Badge from "../../common/ui-components/badge/badge";
import { applicationConfig } from "../../../utils/methods";
  
  const ArchivedQueryList: React.FC<{
    setIsShowDetails:(val:boolean)=>void
  }> = (props) => {

    useEffect(()=>{
      applicationConfig.logger.info(`Showing bookings`)
    },[])

  function LeadsList() {
    return (
      <div id="leadArchiveTable" className="leads-wrapper full-height-width display-flex-row full-width overflow-x-scroll marginTop20">
        <div className=" overflow-y-hidden hide-scrollbar overflow-x-scroll">
        <table className="table">
                <thead className="white-card no-shadow">
                <tr>
                    <th className="center-aligned-column"><IonCheckbox slot="end" color="primary" /></th>
                    <th>Requirement</th>
                    <th>Destination</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Details</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {QUERIES?.map((query:any,index:any)=>{
                    return (
                      <tr className="query-container" key={index}>
                        <td className="center-aligned-column"><IonCheckbox className="marginTop10" slot="end" color="primary" /></td>
                        <td>
                          <div className="icon">
                            <div className="name-icon bg-color-ternary center-aligned-column">
                              <i className={`${query.icon} font-medium color-dark`}></i>
                            </div>
                          </div>
                        </td>
                        <td className="">{query.destination}</td>
                        <td><Badge label={query.status} type={query.statusClass}/></td>
                        <td>{query.date}</td>
                        <td>{query.details}</td>
                        <td><IonIcon icon={ellipsisVertical} /></td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
        </div>
        
      </div>
    );
  }

  
    return(
        <LeadsList />
      )
  };
  
  export default ArchivedQueryList;
  