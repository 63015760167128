import React, { useEffect, useState } from "react";
// import "../style/dashboard.css";
import Header from "./common/ui-components/Header";
import MainSideBar from "./common/ui-components/MainSideBar";

import { IonPage } from "@ionic/react";
import Query from "./queries/queryList/Queries";
import Slider from "./common/ui-components/Silder";
const Dashboard: React.FC<{
}> = (props) => {


  function HeaderMiddleComponent() {
    return(
      <div></div>
    )
  }

  function HeaderLeftComponent() {
    return(
      <div className="brand">
        <div className="heading font-medium">Dashboard</div>
      </div>
    )
  }

  return(
    <IonPage>
      <div className="page full-height-width">
         <div className="display-flex-row no-wrap full-height-width">
         <MainSideBar/>  
         <div className="header-content-wrapper" style={{padding:"0"}}>
           <Header
             componentMiddle={HeaderMiddleComponent}
             componentLeft={HeaderLeftComponent}
           />
          <div className="featured-content-wrapper background-white padding10">
            {/* <Slider/> */}
          </div>
           {/* <Query/> */}
         </div>
         </div>
    </div>
  </IonPage>  
  )
   
};

export default Dashboard;
