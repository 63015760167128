import React, { useState } from "react";
import { addClassInElement, removeClassFromElement, showToast } from "../../../../utils/UI_methods/global";
import { HotelForm, Rooms } from "../../../../utils/forms/hotel";
import { hotelBudgetValidation, hotelNameValidation, mandatoryFieldAction } from "../../../../utils/forms/validation";
import Modal2 from "../../../common/ui-components/modals/Modal2";
import Button2 from "../../../common/ui-components/buttons/Button2";

const HotelOptions: React.FC<{
    formData:HotelForm
    setCurrStep: (val: string) => void;
}> = (props) => {

    const[refresh,setRefresh] = useState(String(Number(new Date)))
    const[isOpen,setIsOpen] = useState(false)


    const formDetails = {
      hotelName:props.formData.hotel_name,
      roomCategory:props.formData.room_category,
      budget:props.formData.budget
    }
 

    
 
    function onchangeHotelName(event:any){
      hotelNameValidation(event)
      formDetails.hotelName = event.target.value

    }

    function onchangeRoomCategory(event:any){
      hotelNameValidation(event)
      formDetails.roomCategory =  event.target.value

    }

    function onchangeBudget(event:any){
      hotelBudgetValidation(event)
      formDetails.budget =  Number(event.target.value)
    }

    function submitHandle() {
      let errors = []
      if(formDetails.hotelName.length){
        errors.push(...hotelNameValidation(null,formDetails.hotelName))
      }
      if(formDetails.roomCategory.length){
        errors.push(...hotelNameValidation(null,formDetails.roomCategory))
      }
      if(formDetails.budget){
        errors.push(...hotelBudgetValidation(null,formDetails.budget))
      }
      if(formDetails.budget === 0 && (formDetails.hotelName === "" || formDetails.roomCategory === "")){
        showToast("Please enter budget or hotel name / category",2000,"error")
        return
      }
      if(!errors.length){
        props.formData.setHotelName(formDetails.hotelName)
        props.formData.setRoomCategory(formDetails.roomCategory)
        props.formData.setBudget(formDetails.budget)
        setIsOpen(false)
      }else{
        showToast("Please enter correct values",2000,"error")
      }
    }

    function Options() {
      return(
        <div  className="padding10 overflow-y-scroll create-query-wrapper full-height-width">

            {/* <div onClick={()=>removeClassFromElement("roomSelectorWrapper","--is-active")} className="heading1 color-primary display-flex-row" style={{justifyContent:"flex-end"}}> <i className="fas fa-times font-medium color-black"></i> </div> */}
            <div className="input-wrapper" style={{marginTop:"20px"}}>
              <div className="" >
                  <div className="label font-md-small full-width">
                    Hotel Name
                  </div>
                  <div className="-aligned-row">
                    <input onChange={(event)=>onchangeHotelName(event)} defaultValue={props.formData.hotel_name} className="search-input input " />
                  </div>
                </div>
                <div className="display-flex-row marginTop5" style={{justifyContent:"center"}}>
                      <ul className="color-danger error font-small"></ul>
                </div>
            </div>

            <div className="input-wrapper">
              <div className="" >
                  <div className="label font-md-small full-width">
                    Room Category
                  </div>
                  <div className="-aligned-row">
                    <input onChange={(event)=>onchangeRoomCategory(event)} defaultValue={props.formData.room_category} className="search-input input " />
                  </div>
                </div>
                <div className="display-flex-row marginTop5" style={{justifyContent:"center"}}>
                      <ul className="color-danger error font-small"></ul>
                </div>
            </div>

            <div className="padding5 center-aligned-row heading2">OR</div>


            <div className="input-wrapper">
              <div className="" >
                  <div className="label font-md-small full-width">
                    Budget (INR)
                  </div>
                  <div className="-aligned-row">
                    <input onChange={(event)=>onchangeBudget(event)} defaultValue={props.formData.budget} type="number" className="search-input input " />
                  </div>
                </div>
                <div className="display-flex-row marginTop5" style={{justifyContent:"center"}}>
                      <ul className="color-danger error font-small"></ul>
                </div>
            </div>

            <div className="center-aligned-row padding5">
                  <Button2
                    configObj={{}}
                    action={()=>submitHandle()}
                    label={"Submit"}
                    fontAwesomeIcon={"fas fa-clipboard color-danger"}
                    iconPos=""
                    classNames={"shadow-light margin0 bg-color-primary"}
                  />
              </div>


            {/* <div className="bottom-right padding10" style={{paddingTop:"15px",borderColor:"black"}}>
                <div></div>
                <div className="heading2  color-primary" onClick={submitHandle}>
                    Done
                </div>
            </div> */}
        </div>
      )
    }

  return (
    <>
        <div   className="full-width ion-margin-top">
          <div className="input-wrapper" style={{marginTop:"20px"}}>
            <div className="" >
                <div className="label full-width">
                  Hotel Name / Room Category
                  <span className="mandatory color-danger font-medium">*</span>
                </div>
                <div className="-aligned-row" onClick={()=>setIsOpen(true)} >
                  <input data-refresh={refresh} value={`${props.formData.hotel_name} ${props.formData.hotel_name.length && props.formData.room_category.length?"/":""} ${props.formData.room_category} ${props.formData.hotel_name || props.formData.room_category?",":""} ${props.formData.budget? `Budget: ₹ ${props.formData.budget}`:``} `} className="search-input pointer-none input " />
                </div>
              </div>
              <div className="display-flex-row marginTop5" style={{justifyContent:"center"}}>
                    <ul className="color-danger error font-small"></ul>
              </div>
          </div>
        </div>

        <Modal2
           isOpen={isOpen}
           onDismiss={()=>setIsOpen(false)}
           classNames={"small-box-modal"}
           id={""}
           Content={Options}
        
        />

        
    </>
  );
  
};

export default HotelOptions;

 