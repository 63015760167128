import React, { useEffect, useState } from "react";
// import "../style/dashboard.css";
import Header from "../common/ui-components/Header";
import MainSideBar from "../common/ui-components/MainSideBar";

import { IonPage } from "@ionic/react";
import User from "./User";


const Profile: React.FC<{
}> = (props) => {


  function HeaderMiddleComponent() {
    return(
      <div></div>
    )
  }

  function HeaderLeftComponent() {
    return(
      <div className="brand">
        {/* <img src="/assets/images/opus.png" alt="Opus"/> */}
        <div className="heading-primary font-medium">Profile Information</div>
      </div>
    )
  }
  return(
    <IonPage>
   <div className="page full-height-width">
         <div className="display-flex-row no-wrap full-height-width">
         <MainSideBar/>  
         <div className="header-content-wrapper" style={{padding:"0"}}>
           <Header
             componentMiddle={HeaderMiddleComponent}
             componentLeft={HeaderLeftComponent}
           />

           <User/>

         </div>
         </div>

    </div>
  </IonPage>  
  )
   
};

export default Profile;
