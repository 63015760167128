import {
    IonIcon,
  } from "@ionic/react";
  
  import React, { useEffect } from "react";
  import { appsOutline,homeOutline, colorFillOutline, colorPaletteOutline, settingsOutline, home, apps, colorPalette, colorFill, person, time, } from "ionicons/icons";
  
  import $ from "jquery"
import { useHistory } from "react-router";
import "../../style/common/mainsidebar.css"
  const MainSideBar: React.FC<{
     
  }> = (props) => {

    const tabs = [
      {tabName:"Dashboard",tabIcon:home,link:"/dashboard"},
      {tabName:"Queries",tabIcon:apps,link:"/queries"},
      {tabName:"Status",tabIcon:time,link:"/status"},
      {tabName:"Profile",tabIcon:person,link:"/user"}
    ]


    const history = useHistory()

    function handleClick(event:any,link:string) {
      history.push(link)
    }


    function Header(){
      return(
        <div className="header overflow-hidden center-aligned-column shadow-light ion-padding-start">
            <div className="display-flex-row">
              {/* <img src="/assets/images/krab.png" alt="" width="50" height="50" /> */}
              {/* <div className="center-aligned-column"><div className="brand-name">KRAB.travel</div></div> */}
            </div>
        </div>
      )
    }
  
    return (
        <div className="main-side-bar border-right position-relative shadow-light">
        <Header/>
        <div className="middle navigation-links">
          {tabs.map((tab:any,index:number)=>{
            let classNames = ["display-flex-row ion-margin-top nav-link-container padding5"]
            if(tab.link.includes(window.location.pathname)){
              classNames.push("--is-active")
            }
            return(
              <div key={index} className={classNames.join(" ")}  onClick={(event)=>handleClick(event,tab.link)}>
                <div className="center-aligned-row full-width">
                  <IonIcon className="icon " icon={tab.tabIcon}></IonIcon>
                </div>
                <div className="text-center  full-width heading link">{tab.tabName}</div>
              </div>
            )
          
          })}
        </div>
      </div>
    );
  };
  
  export default MainSideBar;
  