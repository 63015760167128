export class PackageForm{

    visa: boolean = false
    hotel: boolean = false
    cruise: boolean = false
    sightseeing: boolean = false
    transfers: boolean = false
    travel_insurance: boolean = false
    package_destination:string = ""
    cities_to_cover:string = ""
    category:any = ""
    pickup_from:string = ""
    departure_from:string = ""
    nationality:string = ""
    date_of_arrival:string = ""
    date_of_arrival_Time:string = ""
    date_of_departure:string = ""
    date_of_departure_Time:string = ""
    no_of_rooms:number = 1
    no_of_adults:number = 1
    no_of_children:number = 0
    specific_itinerary:string = ""
    special_request:string = ""
    upload__itinerary: any = ""
    service_CategoriesClicked: string = ""
    how_soon_are_you_willing_to_continue: string = ""

    room_details:Rooms = new Rooms()
    children_list = []
    
    constructor(){

    }

    setVisa(value: boolean){
        this.visa = value;
    }

    setHotel(value: boolean){
        this.hotel = value;
    }

    setCruise(value: boolean){
        this.cruise = value
    }

    setSightseeing(value: boolean){
        this.sightseeing = value;
    }

    setTransfers(value: boolean){
        this.transfers = value;
    }

    setTravelInsurance(value: boolean){
        this.travel_insurance = value;
    }

    setUploadItinerary(value: string){
        this.upload__itinerary = value;
    }

    setPickupFromArrival(value:string){
        this.pickup_from = value;
    }

    setDepartureFrom(value: string){
        this.departure_from = value;
    }

    setNationality(value:string){
         this.nationality = value
    }

    setCity(value:string){
        this.package_destination = value
    }

    setArrivalDate(value: string){
        this.date_of_arrival = value
    }

    setDepartureDate(value: string){
        this.date_of_departure = value
    }

    setPackageCategory(value:string){
        this.category = value
    }

    setNoOfRooms(value:number){
        this.no_of_rooms = value
    }

    setNoOfAdults(value:number){
        this.no_of_adults = value
    }

    setNoOfChildren(value:number){
        this.no_of_children = value
    }

    setSpecialRequirement(value:string){
        this.special_request = value
    }

    setSpecific_itinerary(value:string){
        this.specific_itinerary = value
    }

    setService_CategoriesClicked(value: string){
        this.service_CategoriesClicked = value
    }

    setCitiesToCover(value: string){
        this.cities_to_cover = value
    }

    setWillingToContinue(value:string){
        this.how_soon_are_you_willing_to_continue = value
    }
}



export class Rooms{
    rooms:Array<any> = [new Room()]

    constructor(){

    }

    addRoom(){
        this.rooms.push(new Room())
    }

    deleteRoom(id:number){
        this.rooms = this.rooms.filter(currRoom=>currRoom.roomId!==id)
    }
}

export class Room{

    no_of_adults:number = 2
    // no_of_children_6_to_12yr:number = 0
    // children_less_than_5yr:number = 0

    // children_age_list_6_to_12yr = []
    // children_age_list_less_than_5yr = []

    no_of_children:number = 0

    children_list:Array<Children> = []
    adult_list:Array<Adult> = []

    roomId:number = Number(new Date())
    constructor(){

    }

    removeChildren(id:number){
        this.children_list = this.children_list.filter(currChild => currChild.childId !== id)
    }
}

export class Children{
    childId:number = Number(new Date())
    age:number = 0
    name:string = ""

    constructor(){
        
    }
}

export class Adult{
    id:number = Number(new Date())
    age:number = 7
    name:string = ""

    constructor(){
        
    }
}
 