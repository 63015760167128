
import React from "react";

const Destination: React.FC<{
    formData: any
}> = (props) => {


    function onChangeDestination(value: string) {
        props.formData.setCity(value);
    }

    return (

        <div className="full-width display-flex-row no-wrap space-between" >

            <div className="full-width">
                <div className="label full-width">Destination <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <select className="input search-input" name="" defaultValue={props.formData.package_destination} onChange={(event)=>onChangeDestination(event.target.value)}>
                        <option>Select Destination</option>
                        <option>London</option>
                        <option>Delhi</option>
                        <option>Mumbai</option>
                        <option>Bangalore</option>
                        <option>Pune</option>
                        <option>Singapore</option>
                        <option>Dubai</option>
                    </select>
                </div>
            </div>
        </div>

    )
};

export default Destination;

