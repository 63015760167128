
import React, { useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { applicationConfig } from "../../../utils/methods";
import { ReactComponent as Bus } from '../../svg/Bus-Ticket.svg';
import { ReactComponent as Cab } from '../../svg/Cab-booking.svg';
import { ReactComponent as Conference } from '../../svg/Conference.svg';
import { ReactComponent as Corporate } from '../../svg/Corporate-Event.svg';
import { ReactComponent as Group } from '../../svg/Group-Tour.svg';
import { ReactComponent as Holiday } from '../../svg/Holiday-Package.svg';
import { ReactComponent as SvgHotel } from '../../svg/Hotel-booking.svg';
import { ReactComponent as Train } from '../../svg/Train-Ticket.svg';
import { ReactComponent as Visa } from '../../svg/Visa-Service.svg';
import { ReactComponent as SvgFlight } from '../../svg/plane-ticket.svg';
import Button2 from "../../common/ui-components/buttons/Button2";

const Services: React.FC<{
  setCurrSelectedService: (val: string) => void
}> = (props) => {

  const [services, setServices] = useState(applicationConfig.data.servicesList)

  const notAvilableServices = ["Conference", "GroupTour", "Corporate Event"];

  // console.log("Services", services);

  function closeCreateQuery() {
    applicationConfig.functions.query.closeCreateQueryForm(false)
  }

  function selectServiceHandle(service: string) {
    props.setCurrSelectedService(service.toLowerCase())
  }

  function Slider() {
    return (
      <Carousel
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        className="bg-color-primary"
      >
        <div>
          <img src="assets/images/destinations/create-query/1.jpg" />
        </div>
        <div>
          <img src="assets/images/destinations/create-query/2.jpg" />
        </div>
        <div>
          <img src="assets/images/destinations/create-query/3.jpg" />
        </div>
      </Carousel>
    );
  }

  // "white-card border bg-color-light center-aligned-column shadow-medium pointer-none"

  function Services() {
    return (
      <div className="service-margin-container full-width center-aligned-column" style={{height: "100%"}}>
        <div className="display-flex-row wrap" style={{ justifyContent: "center", height:"100%"}}>
          {services.map((item: any, index: number) => {
            return (
              <div key={index} className={`${Number(item.discontinued) ? "ion-margin-top pointer position-relative" : "display-none"}`} onClick={() => selectServiceHandle(item.servicename)} style={{ width: "13rem", margin: "0.5rem"}}>
                <div style={{ width: "100%", height: "110px", borderRadius: "10px"}} key={index} className={`${item.cf_1427 === "Active" ? "white-card border bg-color-light center-aligned-column shadow-medium pointer-none" : item.cf_1427 === "Coming Soon" ? "white-card border center-aligned-column shadow-medium pointer-none bg-color-comingsoon" : " "}`}>
                  <div className="">
                    <div className="center-aligned-row">
                      {item.servicename === "Accommodation" ?
                        <SvgHotel />
                        :
                        item.servicename === "Visa" ?
                          <Visa />
                          :
                          item.servicename === "Tour Package" ?
                            <Holiday />
                            :
                            item.servicename === "Cab Booking" ?
                              <Cab />
                              :
                              item.servicename === "Conference" ?
                                <Conference />
                                :
                                item.servicename === "Group Tours" ?
                                  <Group />
                                  :
                                  item.servicename === "Corporate Event / Conference" ?
                                    <Corporate />
                                    :
                                    item.servicename === "Flight" ?
                                      <SvgFlight />
                                      :
                                      item.servicename === "Bus Ticket" ?
                                        <Bus />
                                        :
                                        item.servicename === "Train Ticket" ?
                                          <Train />
                                          :
                                          null
                      }
                    </div>
                    <div className="heading2 font-normal marginTop10 pointer-none text-center">{item.servicename}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }


  return (
    <div className="wrapper create-query-wrapper full-height-width position-relative overflow-y-scroll">

      <div className="header-content position-relative">
        <div className="display-flex-row space-between" onClick={closeCreateQuery}>
          {/* <i className="fas fa-arrow-left color-black font-large hide-in-desktop"></i> */}
          <i className="fas fa-times color-black font-large right-middle"></i>
        </div>
        <div className="heading2 font-large color-black middle">Choose category</div>
      </div>

      <div className="body-content center-aligned-column" style={{ border: "" }}>
        <Services />
      </div>

      <div className="footer-content border-top center-aligned-column">
        <Button2
          configObj={{}}
          action={() => closeCreateQuery()}
          label={"Close"}
          fontAwesomeIcon={"fas fa-edit color-danger"}
          iconPos=""
          classNames={"shadow-light bg-color-primary"}
        />
      </div>

      {/* <div className="overflow-hidden hide-in-desktop" style={{borderRadius:"18px"}}>
          <Slider/>
        </div> */}









      {/* <div className="footer full-width bottom-middle ion-padding">
          <div className="center-aligned-row ion-margin-bottom">
            <button className="button-gradient capitalize" onClick={closeCreateQuery} >Back to home</button>
          </div>
        </div> */}

    </div>
  )
};

export default Services;

