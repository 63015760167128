  
  import { IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getCities } from "../../../../services/api";
import { locationChangeListener } from "../../../../utils/forms/helper";
import { mandatoryFieldAction, removeMandatoryClass } from "../../../../utils/forms/validation";
import { applicationConfig, waitFor } from "../../../../utils/methods";
  
  const Location: React.FC<{
    formData:any
    setCurrStep:(val:string)=>void
    isUpdateMode:boolean
  }> = (props) => {

    const[cities,setCities] = useState([])

    useEffect(()=>{
      setTimeout(() => {
        // console.log(props.formData)
        locationChangeListener()
      }, 1000);
      applicationConfig.setFunctionRef("hotel",{setCities:setCities})
    },[])


    function nextStep() {
      props.setCurrStep("step2")
    }

    async function onchangeDesgination(event:any){
      removeMandatoryClass(event)
      let value = event.target.value
      if(value.length === 0){
        setCities([])
        return
      }
      if(value.length < 3){
        setCities([])
        return
      }
      await waitFor(1000)
      await getCities(value).then((data:any)=>{
        // console.log(data.data.data)
        setCities(data.data.data)
      }).catch(err=>{
        console.log(err)
        // setCities([])
      })
    }  

    function setCityName(value:string) {
      props.formData.setCity(value)
      $("#destinationName").val(value)
      setCities([])
    }

    function submitHandle() {
      if(!props.formData.destination_city.length){
        alert("Please enter destination")
        return
      }
      nextStep()
    }

 
  
    return(
        <div className="select-location-wrapper">

            <div className="full-width position-relative">
              <div className="required-text">Required</div>
              <div className="label" style={{ alignItems: "start" }}>
                Destination
                <span className="mandatory color-danger font-medium">*</span>
              </div>
              <div className="full-width position-relative">
                <input disabled={props.isUpdateMode}  onChange={(event)=>onchangeDesgination(event)} id="destinationName" defaultValue={props.formData.destination_city} placeholder="Enter location"  className="input mandatory-field search-input">
                </input>
                <IonIcon
                  className="icon center-aligned-column marginRight10 right-middle"
                  icon={search}></IonIcon>
              </div>
             


                <div className="top-left zindex30 full-width padding0 white-card no-shadow border" style={{top:"101%",border:"none"}}>

                  {cities.map((city:any,index:number)=>{
                    return(
                      <div key={index} className="border-bottom pointer display-flex-row no-wrap" onClick={()=>setCityName(city.city)} style={{padding:"10px 10px"}}>
                        <div className=" heading4 font-md-small color-dark" >{city.city}</div>
                        <div className="heading3 font-md-small marginleft5">{city.country}</div>
                      </div>
                    )
                  })}
                </div>

            </div>

            <div className="popular-locations-list display-none" style={{marginTop:"0px"}}>
              <div className="heading3">Trending Searches</div>
              <div className="marginTop10">
                <div className="display-flex-row padding5 no-wrap">
                  <i className="fas fa-bolt font-ex-small center-aligned-column color-dark"></i>
                  <div className="heading4 marginleft10 color-dark">New York</div>
                </div>
                <div className="display-flex-row padding5 no-wrap">
                  <i className="fas fa-bolt font-ex-small center-aligned-column color-dark"></i>
                  <div className="heading4 marginleft10 color-dark">London</div>
                </div>
                <div className="display-flex-row padding5 no-wrap">
                  <i className="fas fa-bolt font-ex-small center-aligned-column color-dark"></i>
                  <div className="heading4 marginleft10 color-dark">Goa</div>
                </div>
                <div className="display-flex-row padding5 no-wrap">
                  <i className="fas fa-bolt font-ex-small center-aligned-column color-dark"></i>
                  <div className="heading4 marginleft10 color-dark">Maldives</div>
                </div>
              </div>
            </div>

            {/* <div className="ion-margin-top ion-margin-bottom" style={{marginTop:"20px"}}>
              <img src="assets/images/map.png" alt="" />
            </div> */}
           
          </div>
      )
  };
  
  export default Location;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
  