export class HotelForm{
    // query_service_category:string = "demo"
    // opportunity_name:string = "opp1"
    query_id:string = ""
    sales_stage:string = ""
    destination_city:any = ""
    hotel_name:any = ""
    nationality:string = ""
    hotel_category:any = 0
    budget:any = 0
    no_of_rooms:number = 1
    no_of_adults:number = 1
    no_of_children:number = 0
    no_of_children_6_to_12yr:number = 0
    children_less_than_5yr:number = 0
    age_of_childrens: string[] = []
    meal_plan:string = ""
    check_in:string = ""
    check_out:string = ""
    special_request:string = ""
    room_category: string = ""
    how_soon_are_you_willing_to_continue: string = ""

    room_details:Rooms = new Rooms()
    children_list = []

    constructor(query:any){
        if(query){
            console.log("This is form query",query);
            this.query_id = query.potential_no
            this.sales_stage = query.sales_stage
            this.destination_city = query.cf_917
            this.hotel_name = query.cf_919

            this.hotel_category = query.cf_921

            this.budget = query.cf_923
            this.no_of_rooms = query.cf_925
            this.no_of_adults = query.cf_927
            this.no_of_children = query.cf_929
            this.no_of_children_6_to_12yr = query.cf_929
            this.children_less_than_5yr = query.cf_931
            this.meal_plan = query.cf_933
            this.check_in = query.cf_935
            this.check_out = query.cf_937
            // this.special_request = query.potential_no
            // this.room_category = query.cf_921
        }
    }

    // removeFields(){
    //     this.destination_city = undefined
    //     this.hotel_name = undefined
    //     this.hotel_category = undefined
    //     this.budget = undefined
    // }

    setNationality(value: string){
        this.nationality = value;
    }

    setCity(value:string){
        this.destination_city = value
    }

    setCheckIn(value:string){
        this.check_in = value
    }

    setCheckOut(value:string){
        this.check_out = value
    }

    setHotelName(value:string){
        this.hotel_name = value
    }

    setHotelCategory(value:string){
        this.hotel_category = value
    }

    setRoomCategory(value:string){
        this.room_category = value
    }

    setNoOfRooms(value:number){
        this.no_of_rooms = value
    }
    setNoOfAdults(value:number){
        this.no_of_adults = value
    }
    setNoOfChildren(value:number){
        // this.no_of_children = value
        this.no_of_children_6_to_12yr = value
    }
    setNoOfInfant(value:number){
        this.children_less_than_5yr = value
    }
    setMealPlan(value:string){
        this.meal_plan = value
    }
    setSpecialRequirement(value:string){
        this.special_request = value
    }
    setBudget(value:number){
        this.budget = value
    }
    setWillingToContinue(value:string){
        this.how_soon_are_you_willing_to_continue = value
    }
}

export class Rooms{
    rooms:Array<any> = [new Room()]

    constructor(){

    }

    addRoom(){
        this.rooms.push(new Room())
    }

    deleteRoom(id:number){
        this.rooms = this.rooms.filter(currRoom=>currRoom.roomId!==id)
    }
}


export class Room{

    no_of_adults:number = 2
    // no_of_children_6_to_12yr:number = 0
    // children_less_than_5yr:number = 0

    // children_age_list_6_to_12yr = []
    // children_age_list_less_than_5yr = []

    no_of_children:number = 0

    children_list:Array<Children> = []
    adult_list:Array<Adult> = []

    roomId:number = Number(new Date())
    constructor(){

    }

    removeChildren(id:number){
        this.children_list = this.children_list.filter(currChild => currChild.childId !== id)
    }
}

export class Children{
    childId:number = Number(new Date())
    age:number = 0
    name:string = ""

    constructor(){
        
    }
}

export class Adult{
    id:number = Number(new Date())
    age:number = 7
    name:string = ""

    constructor(){
        
    }
}