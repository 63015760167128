
import React from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
import DatePicker from "react-multi-date-picker";

const DateOfTravel: React.FC<{
  formData: VisaForm
}> = (props) => {


  function onChangeDatesOfTravel(event: any) {
    let startDateEvent = event
    let startDate = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
    props.formData.setDateOfTravel(startDate)
  }

  function onChangeDatesOfReturn(event: any) {
    let startDateEvent = event
    let startDate = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
    props.formData.setDateOfReturn(startDate)
  }

  return (

    <div className="full-width display-flex-row no-wrap marginTop10" style={{gap:"10px"}}>
      {/* <div className="full-width display-flex-row no-wrap" style={{ justifyContent: "space-between" }}> */}
      <div className="" style={{width:""}}>
        <div className="label full-width">Date of Travel <span className="mandatory color-danger">*</span></div>
        <div className="center-aligned-row">
          <DatePicker
            // minDate={new Date()}
            value={props.formData.date_of_travel}
            placeholder="Date of Travel"
            range={false}
            onChange={onChangeDatesOfTravel}
            containerClassName="range-date-container"
            style={{ width: "200px"}}
            className="rmdp-mobile mandatory-field"
          />
        </div>
      </div>

      <div className="" style={{width:""}}>
        <div className="label full-width">Date of Return <span className="mandatory color-danger">*</span></div>
        <div className="center-aligned-row">
          <DatePicker
            // minDate={new Date()}
            value={props.formData.date_of_return}
            placeholder="Date of Return"
            range={false}
            onChange={onChangeDatesOfReturn}
            containerClassName="range-date-container"
            style={{ width: "200px" }}
            className="rmdp-mobile mandatory-field"
          />
        </div>
      </div>
    </div>

  )
};

export default DateOfTravel;

