
import React, { useEffect, useState } from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
import DatePicker from "react-multi-date-picker";
import { returnUpForwardOutline } from "ionicons/icons";
import { event } from "jquery";

const  Date_TimeOfArrival: React.FC<{
  formData: any
  setArrivalDate: (value: any) => void
}> = (props) => {

  const timeHrs = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

  const timeMinutes = ["00", "15", "30", "45"];

  const [arrivalDateTimeHrs, setArrivalDateTimeHrs] = useState();
  const [arrivalTimeMinutes, setArrivalDateTimeMinutes] = useState();

  useEffect(() => {
       props.formData.date_of_arrival_Time = `${arrivalDateTimeHrs}:${arrivalTimeMinutes}`
  }, [arrivalDateTimeHrs && arrivalTimeMinutes])


  function onChangeDateOfArrival(event: any) {
    let startDateEvent = event
    let startDate = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
    props.formData.setArrivalDate(startDate);
    props.setArrivalDate(startDate)
  }

  function onChangeDateOfArrivalTimeHrs(event: any){
        setArrivalDateTimeHrs(event.target.value)
  }

  function onChangeDateOfArrivalTimeMinutes(event: any){
        setArrivalDateTimeMinutes(event.target.value)
  }


  return (

    <div className="full-width" style={{ border: "" }}>
      <div className="label full-width" style={{ border: "" }}>Date & Time of Arrival <span className="mandatory color-danger">*</span></div>
      <div className="date&time_container" style={{ border: "", display: "flex", gap:"10px"}}>
        <div className="DateSelect" style={{ width: "" }} >
          <DatePicker
            value={props.formData.date_of_arrival}
            range={false}
            minDate={new Date()}
            onChange={onChangeDateOfArrival}
            containerClassName="range-date-container"
            style={{ width: "150px" }}
            className="rmdp-mobile mandatory-field"
            placeholder="Select Date"
          />
        </div>

        <div className="TimeSelectHrs">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => {onChangeDateOfArrivalTimeHrs(event)}}>
            <option>Hrs</option>
            {
              timeHrs.map((currHrs, index) => {
                return <option key={index}>{currHrs}</option>
              })
            }

          </select>
        </div>

        <div className="TimeSelectMinutes">
          <select style={{fontSize:"13px"}} className="input search-input" name="" defaultValue={""} onChange={(event) => {onChangeDateOfArrivalTimeMinutes(event)}}>
            <option>Min</option>
            {
              timeMinutes.map((currMinute, index) => {
                return <option key={index}>{currMinute}</option>
              })
            }
          </select>
        </div>

      </div>
    </div>



    // <div className="full-width display-flex-row no-wrap space-between" >
    //   <div className="">
    //     <div className="label full-width">Date & Time Of Travel</div>
    //     <div className="date&time_container" style={{display:"flex"}}>
    //       <div className="DateSelect" style={{width:""}} >
    //         <DatePicker
    //           value={""}
    //           range={false}
    //           onChange={onChangeDatesOfTravel}
    //           containerClassName="range-date-container"
    //           style={{ width: "600px" }}
    //           className="rmdp-mobile mandatory-field"
    //           placeholder="Please select Date"
    //         />
    //       </div>
    //       <div className="TimeSelect">
    //         <select className="input search-input" name="" defaultValue={""} onChange={() => { }}>
    //           <option>Select Time</option>
    //           <option>00</option>

    //         </select>
    //       </div>
    //     </div>
    //   </div>
    // </div>

  )
};

export default Date_TimeOfArrival;

