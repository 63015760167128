import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { DeviceUUID } from "device-uuid"

import PrivateRoute from "../utils/PrivateRoute"
import PublicRoute from "../utils/PublicRoute"

import Dashboard from "./Dashboard"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../theme/variables.css";
import "./style/cssvariables.css";
import "./style/style.css";
import "./style/design.css";
import "./style/design1.css";
import "./style/buttons/buttons.css";
import "./style/form/form.css";
import "./style/controls/controls.css";
import "./style/cssvariables.css";
import Profile from "./profile/Profile";
import { getToken, globalMethodsInit, removeUserSession } from "../utils/methods";
import { hideComponentLoader, showComponentLoader } from "../utils/UI_methods/global";
import { applicationConfig } from "../utils/applicationConfig";
import Query from "./queries/queryList/Queries";
import Login from "./login/login";
import LogoutPage from "./LogoutPage";
import Register from "./login/register/RegisterPage";
import Home from "./home/Home";
import Page1 from "./login/otpverify/Page1"
import QueryDetails from "./queries/queryList/query-details/QueryDetails";
import { getServicesList, logout } from "../services/api";
import Toast from "./common/ui-components/notification/Toast";
import ForgetPassword from "./login/ForgetPassword";
import { hasUncaughtExceptionCaptureCallback } from "process";
import HotelBookingVoucher from "./HotelBookingVoucher";

const Main: React.FC = () => {


  // const [BASE_PATH_API] = useState<string>("http://localhost:3000/");

  //------myState--------//


  const [userProfileData, setUserProfileData]: Array<any> = useState([]);


  const [isLogin, setIsLogin]: any = useState(false)



  const [applicationConfigObject, setApplicationConfigObject] = useState<any>(null)

  //React Stated 


  const [mainLoaderId] = useState("mainLoaderId")


  const history = useHistory()


  useEffect(() => {
    let isDataLoaded = false

    // createLoader("Authenticating..");
    showComponentLoader(mainLoaderId)
    // updateLoaderProgress(mainLoaderId,1,1,"Fetching data..")

    let configObj: applicationConfig = new applicationConfig()


    configObj.data.userProfileData = userProfileData;
    // console.log(configObj);
    // configObj?.setData(data)
    globalMethodsInit(configObj)

    let token: string = getToken()!
    // console.log(token);

    if (token) {
      let promises = [getServicesList()];
      Promise.all(promises).then((data: any) => {
        // console.log("getServicesList", data);
        if (!data[0]?.data.message.toLowerCase().includes("internal server error")) {
          configObj.data.servicesList = data[0].data.ServicesList
          setApplicationConfigObject(configObj)
        } else {
          handleLogout()
        }
      }).catch(err => {
        hideComponentLoader(mainLoaderId)
        handleLogout()
      })
    } else {
      hideComponentLoader(mainLoaderId)
      handleLogout()
    }



  }, [isLogin]);


  useEffect(() => {

    //Create config object for the application which will contain the data and other 

  }, [userProfileData])

  function handleLogout() {
    removeUserSession();
    // window.location.href = ""
    return
    logout().then((response: any) => {
      removeUserSession();
      window.location.href = ""
    })
      .catch((error: any) => {
      });
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <PublicRoute path="/login" component={() => (
            <Login
            />)} exact={true} />

          <PrivateRoute
            path="/dashboard"
            component={() => (
              <Dashboard />
            )}
            exact={true}
          />

          <PrivateRoute
            path="/queries"
            component={() => (
              <Query
                applicationConfig={applicationConfigObject}
              />
            )}
            exact={false}
          />

          <PrivateRoute
            path="/user"
            component={() => (
              <Profile />
            )}
            exact={true}
          />
          <PrivateRoute
            path="/queries/:id"
            component={() => (
              <QueryDetails />
            )}
            exact={false}
          />


          <PublicRoute
            path="/register"
            component={() => (
              <Register />
            )}
            exact={true}
          />

          <PublicRoute
            path="/logout"
            component={() => (
              <LogoutPage />
            )}
            exact={true}
          />
          <PublicRoute
            path="/home"
            component={() => (
              <Home />
            )}
            exact={true}
          />

          <PublicRoute
            path="/"
            component={() => (
              <Login />
            )}
            exact={true}
          />

          <PublicRoute
            path="/hotelbookingvoucher"
            component={() => (
              <HotelBookingVoucher />
            )}
            exact={true}
          />

          <PublicRoute
            path="/otpverify"
            component={() => (
              <Page1 />
            )}
            exact={true}
          />

          {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
          <Redirect to="/" />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default Main;
