import {
  IonCheckbox
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { registerUser } from "../../../services/api";
import { showToast } from "../../../utils/UI_methods/global";
import { registerFormWithoutGST } from "../../../utils/data";
import { getRegisterUserData } from "../../../utils/forms/helper";
import { mandatoryFieldAction } from "../../../utils/forms/validation";
import Button2 from "../../common/ui-components/buttons/Button2";

const Form1: React.FC<{
  setCurrStep: (val: string) => void
}> = (props) => {

  const [isUnderGst, setIsUnderGst] = useState(false);
  const [isTrvelCompany, setISTravelCompany] = useState(false);
  const [owenershipValue, setOwnershipValue] = useState("");


  var history = useHistory()

  useEffect(() => {
    if (isTrvelCompany) {
      // let ownershipField = getObjectByParameter(registerFormWithoutGST,"fieldName","Ownership")
      setOwnershipValue("Travel & Tourism")
    } else {
      setOwnershipValue("Select ownership")
    }
  }, [isTrvelCompany])


  function isTravelAgencyChangeHandle(value: boolean) {
    setISTravelCompany(value)
  }

  function gotoLoginPage() {
    history.push("/login")
  }

  function registerUserHandle() {
    let registerUserData = getRegisterUserData()
    registerUserData["are_you_a_travel_company"] = isTrvelCompany
    registerUserData["is_gst"] = isUnderGst
    console.log(registerUserData)
    registerUser(registerUserData).then((data: any) => {
      console.log(data.data)
      showToast(data.data.code + ", " + data.data.message, 3000)
    }).catch(err => {
      console.log(err)
      showToast(err, 3000)
    })
  }


  return (
    <div className="form-container white-card display-flex-row  full-width" style={{ padding: "25px 10px" }}>
      {/* <div className="top-middle ion-padding-bottom ion-padding-top text-center">
        <img width={150} src="assets/images/travel_power_logo.png" alt="" />
      </div> */}

      {registerFormWithoutGST.map((details: any, index: any) => (
        <div className="form-label-input-container width50" key={index}>
          <div className="label-container">
            <div className="label">
              {details.fieldName} {details.mandatory ? <span className="mandatory color-danger">*</span> : null}
            </div>
          </div>
          <div className="input-container">
            {details.inputType === "text" ?
              <div className="input-container position-relative">
                <div className="required-text">Required</div>
                <input id={details.id} className={`input ${details.className}`} onBlur={(event) => mandatoryFieldAction(event, details.mandatory)} name="" onChange={details.onChange} />
                <ul className="color-danger error font-small"></ul>
              </div>
              : details.inputType === "number" ?
                <div className="input-container">
                  <input id={details.id} className="input" type="number" name="" onChange={details.onChange} />
                  <ul className="color-danger error font-small"></ul>
                </div>
                : details.inputType === "select" ?
                  <div className="input-container">
                    <select id={details.id} className={details.fieldName === "Industry" ? isTrvelCompany ? "input disable" : "input" : "input"} name="" >
                      {details.value.map((val: string, index1: number) => (
                        <option key={index1} value={val}>{details.fieldName === "Industry" ? isTrvelCompany ? owenershipValue : val : val}</option>
                      ))}
                    </select>
                  </div>
                  : details.inputType === "radio" ?
                    <div className="input-container display-flex-row  no-wrap">
                      {details.value.map((val: any, index1: number) => (
                        <div key={index1} className="display-flex-row padding5 no-wrap">
                          <input id={details.id} type="radio" onChange={() => (isTravelAgencyChangeHandle(val.onChangeValue))} name={val.name} value={val.value} />
                          <label className="capitalize">{val.value}</label>
                        </div>
                      ))}
                    </div>
                    :
                    <div className="display-flex-row no-wrap">
                      <IonCheckbox></IonCheckbox>
                      <div className="input-label marginleft10">{details.fieldName}</div>
                    </div>
            }
          </div>
        </div>

      ))}



      <div className="full-width marginTop10 padding5">
        <Button2
          configObj={{}}
          action={() => { props.setCurrStep("step2") }}
          label={"Next"}
          fontAwesomeIcon={"fas fa-chevron-right color-white"}
          iconPos=""
          classNames={"shadow-light margin0 login-button center-aligned-column full-width bg-color-primary"}
        />

        <div className="marginTop5">
          <div className="dislay-flex-row text-center padding5 heading3">Already have an account? <span onClick={gotoLoginPage} className="heading3 bold underline pointer" style={{ color: "blueviolet" }}>Sign in!</span></div>
        </div>
      </div>

    </div>
  );
};

export default Form1;
