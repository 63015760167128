// import { saveLogs } from "../../services/api"

import { saveLogs } from "../../services/api"

export class Logging{

    logs:Array<any> = []
    username:string = ""
    sessionId:string = ""
    loginSessionId:string = ""
    sessionDatabaseId:number = 0
    

    constructor(username:string = "",sessionId:string=""){
        this.username = username
        this.sessionId = sessionId
        this.loginSessionId = "kjbasd5d4867d"

        this.saveLogs()
    }

    info(message:string){
        this.logs.push(new Log("Info",message))
        this.saveLogs()

    }

    error(message:string){
        this.logs.push(new Log("Error",message))
        this.saveLogs()
    }

    createNewLogData(moduleName:string,logCode:string){
        let data:any = {}
        data.sessionId = this.sessionDatabaseId
        data.moduleName = moduleName
        data.timestamp = Number(new Date())
        data.logCode = logCode
        return data
    }

    getLogCode(moduleName:string,actionType:string){
        return moduleName+"_"+actionType
    }


    saveLogs(){
        // setInterval(()=>{
            if(this.logs.length){
                saveLogs(this.logs)
                .then((response: any) => {
                    this.resetLogs()
                })
                .catch((error: any) => {
                    console.log(error)
                    this.resetLogs()
                })
            }
        // },10 * 1000)
    }

    setSessionDatabaseId(id:number){
        this.sessionDatabaseId = id
    }

    resetLogs(){
        this.logs = []
    }

}

class Log{
    type:string = "success"
    message:string = ""
    constructor(type:string,message:string){
        this.type = type
        this.message = message
    }
}

 