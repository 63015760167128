import $ from "jquery"

export function showToast(message:string,time:number,type:string="success"){
  $("#toast").removeClass("show")
  $("#toast").addClass("show")
  $("#toast").find(".text").text(message || "Internal error")
  if(type==="success"){
    $("#toast").find("#icon").attr("class","fas fa-check-circle font-medium color-primary")
  }
  if(type==="error"){
    $("#toast").find("#icon").attr("class","fas fa-exclamation-circle font-medium color-danger")
  }
  setTimeout(() => {
      $("#toast").removeClass("show")
  }, time);
}


export function toggleDropdownMenu(event:any) {
  $(event.target).siblings(".dropdown-menu").toggleClass("--is-active")
  
}

export function setCanvasContainerAspectRatio(width:any,height:any,controlsContainerWidthPercent:any,leftContainerWidthPercent:any,moduleName:string){

    let MAX_WIDTH_LEFT_WRAPPER = 165
    let MAX_WIDTH_CONTROLS_WRAPPER = 300

    switch(moduleName){
      case "moodboard":
        MAX_WIDTH_LEFT_WRAPPER = 130
      break;

      case "customizeIn3D":
        MAX_WIDTH_LEFT_WRAPPER = 165
      break;

      case "customiseFinish":
        MAX_WIDTH_LEFT_WRAPPER = 130
      break;
    }

    let windowHeight:any = $(window).height(); 
    let windowWidth:any = $(window).width(); 
    let canvasContainer = $(".customize-canvas-wrapper")
    if(!canvasContainer){
      return
    }
  
    let topOffsetContainer:any = canvasContainer?.offset()?.top
    let finalHeight:any = windowHeight - topOffsetContainer
    let finalWidth = (finalHeight*width)/height

  
    //assign widht and height to canvas 
    // $("#imageContainer").height(finalHeight)
    // $("#imageContainer").width(finalWidth)
    $(".customize-canvas-wrapper").width(finalWidth)
    $(".customize-canvas-wrapper").height(finalHeight)


    let remainingWidth = windowWidth-finalWidth
    let controlsContainerWidth = remainingWidth * controlsContainerWidthPercent
    let leftContainerWidth = remainingWidth * leftContainerWidthPercent

    if(leftContainerWidth < MAX_WIDTH_LEFT_WRAPPER){
      // $(".customize-left-wrapper").addClass("--is-hidden")
      $(".customize-left-wrapper").addClass("--is-overlay")
      $(".toggle-left-wrapper").addClass("--is-visible")


      $(".customize-controls-wrapper").width(controlsContainerWidth+leftContainerWidth)
        return
    }


    $(".customize-left-wrapper").width(leftContainerWidth)
    $(".customize-controls-wrapper").width(controlsContainerWidth)
    return


    // let LeftSidebarWidth = windowWidth-(finalWidth+controlsWidth)
    $(".customize-controls-wrapper").removeClass("--is-hidden")
    $(".customize-canvas-controls-wrapper").append($(".customize-controls-wrapper"))
    $(".customize-controls-wrapper").removeClass("--is-overlay")

    $(".customize-left-wrapper").removeClass("--is-hidden")
    $(".customize-left-wrapper").removeClass("--is-overlay")
    $(".toggle-left-menu").addClass("--is-hidden")

    $(".close-left-side-bar").addClass("--is-hidden")

  
    if(leftContainerWidth<140){
      $(".customize-left-wrapper").addClass("--is-hidden")
      $(".customize-left-wrapper").addClass("--is-overlay")
      $(".toggle-left-menu").removeClass("--is-hidden")
      $(".close-left-side-bar").removeClass("--is-hidden")
    }else{
      $(".customize-left-wrapper").width(leftContainerWidth)
      $(".customize-left-wrapper").removeClass("--is-hidden")
      $(".customize-left-wrapper").removeClass("--is-overlay")
      $(".toggle-left-menu").addClass("--is-hidden")
    }

    if(controlsContainerWidth<210){
      $(".customize-controls-wrapper").addClass("--is-hidden")
      $(".customize-canvas-wrapper").append($(".customize-controls-wrapper"))
      $(".customize-controls-wrapper").addClass("--is-overlay")
    }else{
      $(".customize-controls-wrapper").width(controlsContainerWidth)
      $(".customize-controls-wrapper").removeClass("--is-hidden")
      $(".customize-canvas-controls-wrapper").append($(".customize-controls-wrapper"))
      $(".customize-controls-wrapper").removeClass("--is-overlay")
    }


    if(leftContainerWidth<140 && controlsContainerWidth<210){
      //calculate the remaining width
      let totalEmptySpace = leftContainerWidth+controlsContainerWidth
      if(totalEmptySpace>=150){
        $(".customize-controls-wrapper").width(totalEmptySpace)
        $(".customize-controls-wrapper").removeClass("--is-hidden")
        $(".customize-controls-wrapper").removeClass("--is-overlay")
        $(".customize-canvas-controls-wrapper").append($(".customize-controls-wrapper"))
      }

      // if(totalEmptySpace>=100 && totalEmptySpace<150){
      //   $(".customize-left-wrapper").width(totalEmptySpace)
      //   $(".customize-left-wrapper").removeClass("--is-hidden")
      //   $(".customize-left-wrapper").removeClass("--is-overlay")
      //   $(".toggle-left-menu").addClass("--is-hidden")
      //   $(".close-left-side-bar").addClass("--is-hidden")
      // }
    }


    // let containerWidth:any = $("#imageContainer").width()
    // let containerHeight = (containerWidth*height)/width
    // $("#imageContainer").height(containerHeight)

    // $(".customize-controls-wrapper").height(containerHeight)
  }


  export function addClassInElement(id:string,className:string) {
    // console.log("Chal ye.....");
    $("#"+id).addClass(className)
  }

  export function removeClassFromElement(id:string,className:string) {
    $("#"+id).removeClass(className)
  }

  export function handleLeftWrapperToggle() {
    
    $(".customize-left-wrapper").toggleClass("--is-visible")
    $(".toggle-left-wrapper").toggleClass("--is-active")
  }
  export function showComponentLoader(id:string,className:any=null) {
    if(id){
      $("#"+id).addClass("--is-active")
    }
    if(className){
      $("."+className).addClass("--is-active")
    }
  }

  export function closeDropDowns() {
    $(".dropdown-menu").removeClass("--is-active")
    
  }

  export function hideComponentLoader(id:string,className:any=null) {
    if(id){
      $("#"+id).removeClass("--is-active")
    }
    if(className){
      $("."+className).removeClass("--is-active")
    }
  }

  