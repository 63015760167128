
import React from "react";

const TourFrom: React.FC<{
    formData: any
}> = (props) => {

    function onChangeStartTourFrom(value: string) {
        props.formData.setTourStartFrom(value);
    }

    return (
        <div className="full-width display-flex-row no-wrap space-between" >

            <div className="full-width">
                <div className="label full-width">Start Tour From <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <input className="input search-input" type="text" defaultValue={props.formData.start_tour_from} onChange={(event) => onChangeStartTourFrom(event.target.value)} placeholder="Start Tour Form" />
                </div>
            </div>
        </div>
    )
}

export default TourFrom;