import { IonPage } from "@ionic/react";
import React, { useState } from "react";
import { applicationConfig } from "../../../utils/applicationConfig";
import Footer from "../../common/ui-components/Footer";
import Header from "../../common/ui-components/Header";
import MainSideBar from "../../common/ui-components/MainSideBar";
import "../../style/query/query.css";
import QueryListContainer from "./QueryListContainer";

const Query: React.FC<{
  applicationConfig:applicationConfig
}> = (props) => {
  //Curr page can be leads list page or details page
  const [isShowDetails, setIsShowDetails] = useState(false);

  const [currTab, setCurrTab] = useState<string>("All");

  function HeaderMiddleComponent() {
    return <div></div>;
  }

  function HeaderLeftComponent() {
    return (
      <div className="brand">
        <div className="header-heading font-medium">Queries</div>
      </div>
    );
  }

  return (
    <IonPage>
      {props.applicationConfig || true?
      <div className="full-height-width" >
        <div className="display-flex-row no-wrap full-height-width" >
          <MainSideBar />
          <div className="header-content-wrapper">
            <Header
              componentMiddle={HeaderMiddleComponent}
              componentLeft={HeaderLeftComponent}
            />
            <QueryListContainer 
              isShowDetails={isShowDetails}
              setIsShowDetails={setIsShowDetails}
              currTab={currTab}
              setCurrTab={setCurrTab}
            />
            <Footer
            />
          </div>
        </div>
      </div>
      :null}
        
    </IonPage>
  );
};

export default Query;
