import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";
import Modal from "../../../common/ui-components/modals/Modal2";
import { saveLogs } from "../../../../services/api";
import CancleQueryReason from "./CancleQueryReason";
import Hotel from "../../craeteQuery/hotel/Hotel";
import Visa from "../../craeteQuery/Visa/Visa";
import Tourpackage from "../../craeteQuery/tourPackage/Tourpackage";
import CabBooking from "../../craeteQuery/cabBooking/CabBooking";

const Footer: React.FC<{
  setCurrTab: (val: string) => void
  query: any
}> = (props) => {
  //Curr page can be leads list page or details page

  const [isOpenForm, setisOpenForm] = useState<boolean>(false);
  const [isOpenCancleForm, setIsOpenCancleForm] = useState<boolean>(false);

  const salesStageArr = ["Query Submitted", "Receiving Quotes", "Quote Received", "Confirmation Requested", "Pending for Payment"];

  return (
    <div className="ion-padding">

      <IonModal
        onDidDismiss={() => setisOpenForm(false)}
        isOpen={isOpenForm}
        id="inventoryModal"
        cssClass='vertical-modal'
      >

        {props.query.cf_983?.toLowerCase() === "accommodation" ?
          <Hotel
            isUpdateMode={true}
            closeAction={() => setisOpenForm(false)}
            query={props.query}
          />
          :
          props.query.cf_983?.toLowerCase() === "visa" ?
            <Visa
              isUpdateMode={false}
              closeAction={() => setisOpenForm(false)}
              query={props.query}
            />
            :
            props.query.cf_983?.toLowerCase() === "tour package" ?
              <Tourpackage
                isUpdateMode={false}
                closeAction={() => setisOpenForm(false)}
              />
              :
              props.query.cf_983?.toLowerCase() === "cab booking" ?
                <CabBooking
                  isUpdateMode={false}
                  closeAction={() => setisOpenForm(false)}
                />
                :
                <></>
        }
      </IonModal>

      <IonModal
        onDidDismiss={() => setIsOpenCancleForm(false)}
        isOpen={isOpenCancleForm}
        id=""
        cssClass='vertical-modal my-modal-cancleQuery'
      >

        <CancleQueryReason
          closeAction={() => setIsOpenCancleForm(false)}
          query={props.query}
        />

      </IonModal>


      <div className="center-aligned-column ">
        {
          salesStageArr.includes(props.query.sales_stage) ?
            (
              // <Button2
              //   configObj={{}}
              //   action={()=>setisOpenForm(true)}
              //   label={"Modify Requirements"}
              //   fontAwesomeIcon={"fas fa-chevron-right color-white"}
              //   iconPos="right"
              //   classNames={"shadow-light login-button center-aligned-column full-width button-border-blue bg-color-primary-inverse"}
              // /> 


              <div className="add-new center-aligned-column full-width">
                <div onClick={() => setisOpenForm(true)} className={"position-relative shadow-light padding5 zindex30 full-width button-border-blue white-card click-effect`"} style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "3rem" }}>
                  <p className="text font-medium  pointer-none" style={{ fontWeight: "600", fontSize: "", color: "blue" }}>Modify Requirements</p>
                  {/* <span className="text middle pointer-none" style={{fontWeight: "bold", fontSize: "15px"}}>Confirm Booking</span> */}
                  {/* <span className="heading4 right-middle color-white font-small  pointer-none" style={{ marginTop: "2px" }}>{getFommatedDate(currOption?.cf_1051)} | {currOption?.cf_1053}</span> */}
                </div>
              </div>
            )
            :
            (
              <></>
            )
        }
      </div>

      <div className="display-flex-row space-between">
        <div></div>
        <div onClick={() => { setIsOpenCancleForm(true) }} className="color-danger padding10">Cancel the query</div>
      </div>

    </div>
  );
};

export default Footer;
