import { gstinValidation, numberValidation, organizationValidation, panValidation, phoneValidation, nameValidation, validateEmail, websiteValidation, addressValidation, postalCodeValidation, brandNameValidation } from "./forms/validation";

export var generalDetails = [
  {
    fieldName: "Salutation",
    value: "Salutation",
  },
  {
    fieldName: "Contact ID",
    value: "CC123",
  },
  {
    fieldName: "First Name",
    value: "Kartik",
  },
  {
    fieldName: "Last Name",
    value: "Katahre",
  },
  {
    fieldName: "Mobile No.",
    value: "9996963654",
  },
  {
    fieldName: "Designation",
    value: "Sales",
  },
  {
    fieldName: "Primary Email",
    value: "katahrekartik@gmail.com",
  },
  {
    fieldName: "Secondary Email",
    value: "katahrekartik@gmail.com",
  },
];

export const contactDetails = [
  
  {
    fieldName: "Organization Name",
    value: "org",
  },
  {
    fieldName: "Address",
    value: "Udyog Vihar",
  },

  {
    fieldName: "City",
    value: "Gurgaon",
  },

  {
    fieldName: "State",
    value: "Haryana",
  },

  {
    fieldName: "Pin Code",
    value: "112005",
  },

  {
    fieldName: "Country",
    value: "India",
  },

  {
    fieldName: "Contact No.",
    value: "9998877744",
  },
  {
    fieldName: "Office Phone",
    value: "2365896",
  },
  {
    fieldName: "GST Phone",
    value: "9998877744",
  },
  {
    fieldName: "GST Email",
    value: "gst@gmail.com",
  },
  {
    fieldName: "GST Address",
    value: "Gurgaon",
  },
  {
    fieldName: "GST State",
    value: "Haryana",
  },
  {
    fieldName: "GSTIN",
    value: "KK678",
  },
  {
    fieldName: "PAN",
    value: "MKF454LHK",
  },
];

export var otherDetails = [
  {
    fieldName: "Agency Code",
    value: "AA34",
  },
  {
    fieldName: "Portal Access",
    value: "true",
  },
  {
    fieldName: "Support Start Date",
    value: "DD/MM/YYYY",
  },
  {
    fieldName: "Support End Date",
    value: "DD/MM/YYYY",
  },
  {
    fieldName: "Portal Username",
    value: "kkatahre",
  },
  {
    fieldName: "Contact Category",
    value: "CC123",
  },

];

export var queryDetails = [
  {
    fieldName:"Date of travel",
    value:"",
    inputType:"date",
    mandatory:false,
  },
  {
    fieldName:"Number of traveller’s",
    inputType:"select",
    mandatory:false,
    value:["1","2","3"]
  },
  {
    fieldName:"Contact",
    value:"",
    inputType:"text",
    mandatory:false,
  },
  {
    fieldName:"Service",
    value:"",
    inputType:"text",
    mandatory:false,
  },
  {
    fieldName:"Service details",
    value:"",
    inputType:"textarea",
    mandatory:false,
  },
  {
    fieldName:"Destination",
    value:"",
    inputType:"Select",
    mandatory:false,
  },
  {
    fieldName:"Number of adults",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Number of children with age",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Number of infant",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Duration number of nights ",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Starting from",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Ending at",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Special requests",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Number of room",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Hotel category",
    value:"",
    inputType:"select",
    mandatory:false,
  },
  {
    fieldName:"Description",
    value:"",
    inputType:"select",
    mandatory:false,
  },
]



    
export const QUERIES = [
  {
    name: "Kartik Katahre",
    status: "On hold",
    destination:"Maldives",
    duration: "3 Nights 4 Days",
    namebg: "pink",
    statusbg: "bg-color-primary",
    response:"25 min ago",
    isNewMessage:false,
    icon:"fas fa-hotel",
    date:"25/04/2022",
    statusClass:"primary",
    noOfAdults:"2 Adults, 1 child",
    serviceType:"bus"
  },

  {
    name: "FirstName Last",
    status: "Pending",
    destination:"Australia",
    duration: "3 Nights 4 Days",
    namebg: "lightblue",
    statusbg: "bg-color-warning",
    response:"2 Days ago",
    isNewMessage:true,
    icon:"fas fa-address-card",
    date:"14/04/2022",
    noOfAdults:"2 Adults, 1 child",
    statusClass:"warning",
    serviceType:"hotel"
  },

  {
    name: "Name",
    status: "Completed",
    destination:"Thailand",
    duration: "3 Nights 4 Days",
    namebg: "lightgray",
    statusbg: "bg-color-success",
    response:"1 Day ago",
    isNewMessage:false,
    icon:"fas fa-fighter-jet",
    date:"25/05/2021",
    noOfAdults:"2 Adults, 1 child",
    statusClass:"success",
    serviceType:"visa"
  },
];



export var registerFormWithoutGST = [
  {
    fieldName:"Legal Name",
    value:"",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"legal_name",
    onChange:organizationValidation
  },
  {
    fieldName:"Brand Name",
    value:"",
    inputType:"text",
    mandatory:false,
    id:"brand_name",
    className:"capitalize",
    onChange:brandNameValidation
  },
  {
    fieldName:"Website",
    value:"",
    inputType:"text",
    mandatory:false,
    id:"website",
    onChange:websiteValidation

  },
  {
    fieldName:"PAN number",
    value:"",
    inputType:"text",
    id:"pan_number",
    mandatory:true,
    onChange:panValidation
  },
  {
    fieldName:"First name",
    value:"",
    inputType:"text",
    id:"first_name",
    mandatory:false,
    className:"capitalize",
    onChange:nameValidation
  },
  {
    fieldName:"Last name",
    value:"",
    inputType:"text",
    id:"last_name",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
  {
    fieldName:"Designation",
    value:"",
    inputType:"text",
    id:"designation",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
 
  {
    fieldName:"Primary Phone",
    value:"",
    inputType:"text",
    id:"primary_phone",
    mandatory:true,
    onChange:phoneValidation

  },
   {
    fieldName:"Mobile Number",
    value:"",
    inputType:"text",
    id:"mobile_number",
    mandatory:false,
    onChange:phoneValidation

  },
   {
    fieldName:"WhatsApp Number",
    value:"",
    inputType:"text",
    id:"whatsapp_number",
    mandatory:false,
    onChange:phoneValidation

  },
  {
    fieldName:"No. of employees",
    // value:Array.from(Array(100).keys()).filter(val=>val!=0),
    value:["One Person","2 - 10","11 - 100","101 - 500","500 - 2500","2500 +"],
    inputType:"select",
    id:"no_of_employees",
    mandatory:false,
    onChange:numberValidation
  },
  {
    fieldName:"Primary Email",
    value:"",
    inputType:"text",
    id:"primary_email",
    mandatory:true,
    onChange:validateEmail

  },
  {
    fieldName:"Secondary Email",
    value:"",
    inputType:"text",
    id:"secondary_email",
    mandatory:false,
    onChange:validateEmail
  },
  {
    fieldName:"Ownership",
    value:["Select ownership","Sole Proprietorship","Partnership","One person company","Limited Liability Partnership (LLP)","Private Limited Company","Public Limited Company"],
    inputType:"select",
    id:"ownership",
    mandatory:true,
  }
  // {
  //   fieldName:"Are you a travel company",
  //   value:[{name:"istravelagency",value:"yes",onChangeValue:true},{name:"istravelagency",value:"no",onChangeValue:false}],
  //   inputType:"radio",
  //   id:"are_you_a_travel_company",
  //   mandatory:true,
  // },
  // {
  //   fieldName:"Industry",
  //   value:["Select Industry","Advertising / Marketing","Agriculture","Airlines","Antique / art / arms dealer","Automobiles","Banking","Bullion / Gems / Jewellery","Business correspondent","Call center","Checmical / Dyes / Paintasinos","Chit funds","Construction / Infrastructure","Courior / Logistics / Transporter","Defence","Electronics","Eectricity","Embassises","Entertainment / Media","Govt bodies (state)","Govt bodies (central)","Hotel / Restraurat","IT / Ites","Medical / Health care","MF / Insurance","Money changer","NBFC","NGO / NPO","Petrol Pump / Gas Station","Polotical Parties","Real Estate / Housing","Religious Institutions","RetailChain / FMCG", "School / College","Shipping","Stock / Commodity Brokers","Telecom","Textiles","Travel and Tourism","Professionals","Other"],
  //   inputType:"select",
  //   id:"industry",
  //   mandatory:true,
  // },
 
]

export var industryValues = ["Select Industry","Advertising / MArketing","Agriculture","Airlines","Antique / art / arms dealer","Automobiles","Banking","Bullion / Gems / Jewellery","Business correspondent","Call center","Checmical / Dyes / Paintasinos","Chit funds","Construction / Infrastructure","Courior / Logistics / Transporter","Defence","Electronics","Eectricity","Embassises","Entertainment / Media","Govt bodies (state)","Govt bodies (central)","Hotel / Restraurat","IT / Ites","Medical / Health care","MF / Insurance","Money changer","NBFC","NGO / NPO","Petrol Pump / Gas Station","Polotical Parties","Real Estate / Housing","Religious Institutions","RetailChain / FMCG", "School / College","Shipping","Stock / Commodity Brokers","Telecom","Textiles","Travel and Tourism","Professionals","Other"]

export const registerFormWithGST = [
  {
    fieldName:"GSTIN",
    value:"",
    inputType:"text",
    id:"gstin",
    mandatory:true,
    onChange:gstinValidation
  },

  // {
  //   fieldName:"PAN number",
  //   value:"",
  //   inputType:"text",
  //   mandatory:true,
  //   onChange:panValidation
  // },

  {
    fieldName:"GST Email",
    value:"",
    inputType:"text",
    id:"gst_email",
    mandatory:true,
    onChange:validateEmail
  },

  {
    fieldName:"GST Phone",
    value:"",
    inputType:"text",
    id:"gst_phone",
    mandatory:true,
    onChange:phoneValidation
  },
  {
    fieldName:"GST Contact name",
    value:"",
    inputType:"text",
    id:"gst_contact_name",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
  {
    fieldName:"GST Registration Name",
    value:"",
    inputType:"text",
    id:"gst_registration_name",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  }
]

export const registerFormBillingDetails = [
  {
    fieldName:"Billing Address",
    value:"",
    inputType:"text",
    id:"billing_address",
    mandatory:true,
    onChange:addressValidation
    
  },
  {
    fieldName:"Billing City",
    value:"",
    inputType:"text",
    id:"billing_city",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
  {
    fieldName:"Billing State",
    value:"",
    inputType:"text",
    id:"billing_state",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
  {
    fieldName:"Billing Postal code",
    value:"",
    inputType:"text",
    id:"billing_postal_code",
    mandatory:true,
    onChange:postalCodeValidation
  },
  {
    fieldName:"Billing Country",
    value:"",
    id:"billing_country",
    inputType:"text",
    className:"capitalize",
    mandatory:true,
    onChange:nameValidation
  },
]


export var hotelBookingDetailsForm = [
  {
    fieldName:"Booking Date",
    value:"02/02/2023",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"Hotel Name",
    value:"Hotel Name",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"Room Category",
    value:"Category",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"No. of rooms",
    value:"4",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"No. of adults",
    value:"2",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"No. of children",
    value:"4",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
  {
    fieldName:"Remarks",
    value:"",
    inputType:"text",
    mandatory:true,
    className:"capitalize",
    id:"",
  },
]