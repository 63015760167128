import { hideComponentLoader, showComponentLoader } from "./UI_methods/global";
var WINDOW_OBJ:any = window
 

var LOADING = document.createElement("ion-loading");

export var applicationConfig:any = null

export function globalMethodsInit(configObj:any) {
  applicationConfig = configObj
}

// return the user data from the session storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }



  export const getUserType = () => {
    let user = getUser()
    if(applicationConfig?.isShareMode){
      return "customer"
    }
    return user?.userType || null 
  }
   
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('_grecaptcha');
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token:any, user:any) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    // localStorage.setItem('currUserType',user.userType);
    // applicationConfig?.setIsShareMode(false)
  }

  export function updateUserSession(val:boolean,moduleName:string) {
    let user = getUser()
    if(user){
      user.isShareMode = val
      user.moduleName = moduleName
      localStorage.setItem('user', JSON.stringify(user));
      applicationConfig?.setIsShareMode(val)
    }

  }

  export function disableShareMode(){
    // removeUserSession()
    // let user = getUser()
    // user.userType = "admin"
    // localStorage.setItem('user', JSON.stringify(user));
    window.location.href = ""
  }

  export function gotoPreviousPage() {
    window.history.back()
  }
 
  
 

  export function getMaterialTypesFromPartName(partName:string,objectMaterialTypes:any,categoryName:string) {
    partName = stringWithoutNumbers(partName)
    let materialTypeArray: any = [];
    for (let productPart of objectMaterialTypes) {
      if (productPart.category_name === categoryName && (productPart.object_name.toLowerCase().includes(partName.toLowerCase()) || partName.toLowerCase().includes(productPart.object_name.toLowerCase()))) {
        // if (productPart.category_name === categoryName && productPart.object_name === partName) {
        materialTypeArray.push(productPart.material_type);
      }
    }
    return materialTypeArray
  }

  export function materialTypeFilteredTextureArray(textures:Array<any>,objectMaterialTypes:Array<any>,material:string){
    return textures.filter(function (texture: any) {
      return texture.material_type===material;
    });

  }

  export function categoryFilteredArray(textures:Array<any>,categoryMaterialTypes:Array<any>,objectMaterialTypes:Array<any>,partName:string,categoryName:string){
    let updatedTextures:Array<any> = []
    //Create Category materialType Array 
    // let categoryMaterialTypeArray: any = [];
    // for (let categoryMaterial in categoryMaterialTypes) {
    //   if (categoryMaterial.toLocaleLowerCase() == categoryName.toLocaleLowerCase()) {
    //     categoryMaterialTypeArray = categoryMaterialTypes[categoryMaterial];
    //     break;
    //   }
    // }
    let materialTypeArray: any = [];
    for (let productPart of objectMaterialTypes) {
      
      if (productPart.object_name.toLowerCase() === partName.toLowerCase() && productPart.category_name.toLowerCase() === categoryName.toLowerCase()) {
        materialTypeArray.push(productPart.material_type);
      }
    }

    updatedTextures = textures.filter(function (texture: any) {
      return materialTypeArray.includes(texture.material_type);
    });
    return updatedTextures
  }



  export function getMaterialTypeArray(objectMaterialTypes:any,partname:string){
    let materialTypeArray:any = []
    for (let productPart in objectMaterialTypes) {
      if (productPart.toLocaleLowerCase() == partname.toLocaleLowerCase()) {
        materialTypeArray = objectMaterialTypes[productPart];
        break;
      }
    }
    return materialTypeArray
  }

  export function compareArrays( arrayFirst:any, arraySecond:any ){

    // console.log(arrayFirst)
    // console.log(arraySecond)

    //check if lengths are different
    if(arrayFirst.length !== arraySecond.length) return false;

    
    if(arrayFirst.length===0 || arraySecond.length===0) return false;

    //slice so we do not effect the original
    //sort makes sure they are in order
    //join makes it a string so we can do a string compare
    var cA = arrayFirst.slice().sort().join(","); 
    var cB = arraySecond.slice().sort().join(",");
    

    return cA===cB;

}


export function getObjectNamesArray(objectCanvasMapping:any){
  let arr = [] 
  for (const objectName in objectCanvasMapping) {
    arr.push(objectName)
  }
  return arr
}

export function getCollectionsFilteredTextures(textures:Array<any>,collections:Array<any>) {
  return textures?.filter(texture=>{
    if(collections.includes(texture.collection_name)){
      return texture
    }
  })
}


  export function getFilteredConfiguration(foregroundSavedConfiguration:Array<any>,projectId:number,areaId:number,currViewId:number){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_id===projectId&&config.area_id===areaId&&currViewId===config.view_id)
    } catch (error) {
      // console.log(error)      
    }
  }

  export function getFilteredConfigurationByProjectAreaName(foregroundSavedConfiguration:Array<any>,projectName:number,areaName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_name===projectName&&config.area_name===areaName)
    } catch (error) {
      console.log(error)      
    }
  }

  export function getFilteredConfigurationByProjectAreaViewName(foregroundSavedConfiguration:Array<any>,projectName:number,areaName:string,viewName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.project_name===projectName&&config.area_name===areaName&&config.view_name===viewName)
    } catch (error) {
      console.log(error)      
    }
  }


  export function getFilteredConfigurationFromConfigName(foregroundSavedConfiguration:Array<any>,configName:string){
    try {
      return foregroundSavedConfiguration.filter((config:any)=>config.config_name===configName)
    } catch (error) {
      console.log(error)      
    }
  }

  export function getSavedConfigByName(savedConfiguration:Array<any>,configName:string){
    try {
      return savedConfiguration.filter((config:any)=>config.config_name===configName)
    } catch (error) {
      console.log(error)      
    }
  }

  export   function getForegroundImageUrl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,productName:string,collectionName: string,materialCode: string,objectLabel: string) {
    return `./assets/images/${clientName}/${projectName}/${areaName}_${viewName}/Foreground/${productName}/${areaName}_${viewName}_${productName}_${collectionName}_${materialCode}/${areaName}_${viewName}_${productName}_${objectLabel}_${collectionName}_${materialCode}0000.png`;
  }


  export function stringWithoutNumbers(string:string) {
    return string.replace(/[0-9]/g, '');
  }
  
  export function getTextureUrl(BASE_URL:string,companyName:string,collectionName:string,materialCode:string) {
    return `textures/${companyName}/${collectionName}/${materialCode}/${materialCode}.png`
  }

  export function getRoughnessMapURL(BASE_PATH:string,companyName:string,collectionName:string,roughness:string,){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Roughness.png'
    return url;   
  }

  export function getNormalMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Normal.png'
    return url;
  }

  export function getMetalnessMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Metalness.png'
    return url;
  }

  export function getSpecularMapURL(BASE_PATH:string,companyName:string,collectionName:string,normalmap:string){
    let url = 'textures/' +  companyName + '/' + collectionName + '/' + collectionName+"Common/" + 'Specular.png'
    return url;
  }

  export function getBackgroundurl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,objectName:string) {
      return `./assets/images/${clientName}/${projectName}/${areaName}_${viewName}/Background/Background/${areaName}_${viewName}_Background0000.png`;
  }

  export function getRandomArray(array:any){
    let length = array.length
    let randomNumber = Math.floor(Math.random() * length)
    return array[randomNumber]
  }

  
  export   function getMaterialsImageUrl(BASE_PATH:string,companyName: string,collectionName:string,materialCode:string) {
    return `thumbnails/${companyName}/${collectionName}/${materialCode}.png`;
  }

  export function getObjectByParameter(array:any,paramenterName:string,value:any) {
    try {
      let res = null
      array.forEach(function(object:any){
        if(object[paramenterName]===value){
          res = object
        }
      })
      return res 
    } catch (error) {
      console.log(error)
    }
  }


  export function getProductName(objectName:string){
    let array = objectName.split(".")
    return array[3] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }

  export async function waitFor(time:number) {
    return new Promise(resolve=>{
      setTimeout(() => {
        resolve("Done")
      }, time);
    })
  }

  export function getCategoryName(objectName:string){
    let array = objectName.split(".")
    return array[2] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }

  export function searchTexture(string:string,array:Array<any>){
    let updatedArray = array.filter((texture:any)=>{
      return texture.company_name.toLowerCase().includes(string.toLowerCase()) || texture.collection_name.toLowerCase().includes(string.toLowerCase()) || texture.material_code.toLowerCase().includes(string.toLowerCase()) 
    })
    return updatedArray
  }

  export function getViewName(objectName:string){
    let array = objectName?.split(".")
    return array[1] 
    // return  objectName.substring(0,objectName.indexOf('.'))
  }


  export function getPartName(objectName:string){
    return objectName?.split(".")[4] || null
    // return objectName.substr(objectName.indexOf('.')+1)
  }
 

  
//   export function getAreaViewList(products:Array<any>){
//     let views:any = new Set()
//     products.forEach(product=>{
//         views.add(product.view_name)
//     })
//     return Array.from(views)
// }


  export function getFilteredProducts(products:Array<any>,projectId:number,areaId:number){

    return products.filter((product:any)=>product.project_id===projectId&&product.area_id===areaId)

  }


  export function getFilteredArray(array:Array<any>,key:string,value:any){
    return array?.filter((arrayItem:any)=>String(arrayItem[key])===String(value))
  }

  export function getCurrentConfigurationKey(projectName:string,areaName:string){
    return `Config_${projectName}_${areaName}`
  }

  export function getProductPartConfigKey(areaName:string,viewName:string,productName:string,partname:string,categoryName:string){
    return stringWithoutWhiteSpace(`${areaName}.${viewName}.${categoryName}.${productName}.${partname}`)
  }
  
  export async function dismissLoading() {
    await LOADING?.dismiss();
  }

  export function getViewIdFromName(viewName:string,allViews:any){
    let arr = allViews.filter((view:any)=>view.view_name.toLowerCase()===viewName.toLowerCase())
    return arr[0].id
  }

  export function getPartIdFromName(partName:string,allParts:any){
    let arr = allParts.filter((part:any)=>part.object_name.toLowerCase()===partName.toLowerCase())
    return arr.length?arr[0].id:null
  }
 

  export async function createLoader(message:string) {
    LOADING.cssClass = "main-loader";
    LOADING.message = message;
    LOADING.translucent = true;
    LOADING.mode="ios"
    document.body.appendChild(LOADING);
    await LOADING.present();
  }

  export function getArrayOfDistinctValuesFromArray(array:Array<any>){
    if(array){
      let set = new Set();
      for (let values of array) {
        set.add(values);
      }
      return Array.from(set)
    }
    return []
  }

  export function getArrayOfDistinctValues(array:Array<any>,parameter:string){
    if(array){
      // let serviceListName: string[] = [];
      let set = new Set();
      // console.log(set);
      for (let values of array) {
        // serviceListName.push(values[parameter]);
        set.add(values[parameter]);
      }
      return Array.from(set) || []
      // return serviceListName;
    }
    return []
  }
  

  export function getArrayOfDistinctValuesFromObject(object:Array<any>,parameter:string){
    let set = new Set();
    for (let key in object) {
      set.add(object[key][parameter]);
    }
    return Array.from(set)
  }

 
  export function getLoggedInUserName(){
    let user:any = localStorage.getItem("user")
    user = JSON.parse(user)
    return user.username
  }


  export function getIdFromName(array:Array<any>,idName:string,parameter:string,value:string) {
    try {
      let filterdData = array.filter((object:any)=>object[parameter]===value)
      return filterdData[0][idName]
    } catch (error) {
      console.log(error)
    }
   
  }

  export function stringWithoutWhiteSpace(string:string) {
    try {
      if(!string || string===""){
        return
      }
      return string?.replace(/ /g,'')
    } catch (error) {
      return ""
    }

  }

  export function getAllProductsFromConfig(configuration:any){
    let productSet:any = []
    let productsArray:any = []

    for (const key in configuration) {
      let config = configuration[key]
      let productName = getProductName(key)
      let categoryName = getCategoryName(key)
      let myObj = {productName:productName,categoryName:categoryName}
      // if(config.view_name===props.currentConfigObject?.viewName){
          if(!productsArray.includes(JSON.stringify(myObj))){
              productsArray.push(JSON.stringify(myObj))
              productSet.push(myObj)
          }
      // }
    
  }

    
 

    // for (const product of productSet) {
    //     for (const config of configuration) {
    //             if(config.product_name===product.productName){
    //                 let myObj = {collectionName:config.collection_name,materialCode:config.material_code}
    //                 product.configuration.push(myObj)
    //             }
    //     }
    // }

    return productSet
}


export function getApplicationFiltersMaterials(applicationName:string,allMaterials:Array<any>,applicationMaterials:Array<any>) {
  applicationMaterials = getFilteredArray(applicationMaterials,"application_name",applicationName)
  // let companyList = getArrayOfDistinctValuesFromObject(applicationMaterials,"company_id")
  // let collectionList = getArrayOfDistinctValuesFromObject(applicationMaterials,"collection_id")
  // return allMaterials.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id))
  let companyCollectionMaterialsType = getArrayOfDistinctValuesFromObject(applicationMaterials,"company_collection_materialtype_id")
  return allMaterials.filter(currMaterial=> companyCollectionMaterialsType.includes(currMaterial.company_collection_materialtype_id))
}

export function getProjectFiltersMaterials(materialsList:Array<any>,projectMaterials:Array<any>,projectId:number) {
  projectMaterials = getFilteredArray(projectMaterials,"project_id",projectId)
  let companyList = getArrayOfDistinctValuesFromObject(projectMaterials,"company_id")
  let collectionList = getArrayOfDistinctValuesFromObject(projectMaterials,"collection_id")
  return materialsList.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id))
}

export function getCategoryFiltersMaterials(materialsList:Array<any>,projectMaterials:Array<any>,categoryId:number) {
  projectMaterials = getFilteredArray(projectMaterials,"category_id",categoryId)
  let companyList = getArrayOfDistinctValuesFromObject(projectMaterials,"company_id")
  let collectionList = getArrayOfDistinctValuesFromObject(projectMaterials,"collection_id")
  return materialsList.filter(currMaterial=> companyList.includes(currMaterial.company_id) && collectionList.includes(currMaterial.collection_id))
}

export function getModulePrice(productModulesPrice:any,collectionName:string,moduleId:number) {
  return productModulesPrice.filter((currdata:any)=>currdata.collection_name===collectionName  && currdata.module_id===moduleId )[0]?.price || 5482
}

 
 
export function goback() {
  window.history.back()
}


export function checkIfLastElement(array:Array<any>,value:string) {
  if(value === array[array.length-1]){
    return true
  }
  return false
}

export function checkIfFirstElement(array:Array<any>,value:string) {
  if(value === array[0]){
    return true
  }
  return false
}

 

export function getFormatedPrice(price:any) {
  price = String(price)
  if(!price || price === "undefined"){
    return "0"
  }
  if(price.length <= 3){
    return price
  }
  let array = price?.split("").reverse() 
  array?.splice(3,0,",")
  return array?.reverse().join("") || "0"
}

export function getStatusClass(stage:string) {
  if(stage){
    stage = stringWithoutWhiteSpace(stage.toLowerCase()) || ""
    if(stage.includes("querysubmitted")  || stage.includes("receivingquotes") || stage.includes("modificationrequest")){
      return "primary"
    }
    if(stage.includes("quotereceived") || stage.includes("confirmationpending") || stage.includes("confirmed") ){
      return "success"
    }
    if(stage.includes("pendingforpayment") ){
      return "danger"
    }
    if(stage.includes("modificationrejected") || stage.includes("sentforconfirmation") ){
      return "warning"
    }
    if(stage.includes("cancellationrequested") ){
      return "dark"
    }
    return "primary"
  }
  return "primary"
}


export function isTouchDevice() {
  let navigatorObject:any = window.navigator
  if(!navigatorObject?.share){
    return
  }
  return ( 'ontouchstart' in window ) || 
         ( navigatorObject.maxTouchPoints > 0 ) ||
         ( navigatorObject.msMaxTouchPoints > 0 );
  }