import React from 'react';

import Main from './components/MainComponent';
import Toast from './components/common/ui-components/notification/Toast';


const App: React.FC = () => (
<>
<Main/>
<Toast/>
</>
 );

export default App;
