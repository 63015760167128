import React from "react";

const Badge: React.FC<{
    label:string
    type:string
}> = (props) => {




  return (
    <div className={`badge-container ${props.type}`}>
        {props.label}
    </div>
  );
};

export default Badge;
