import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import '../../style/style.css';
// import {removeUserSession,getToken,getUser,getLoggedInUserName} from "../../../utils/methods"
import { applicationConfig, removeUserSession } from '../../../utils/methods';
import "../../style/common/header.css";
import { ReactComponent as SvgEvent } from '../../svg/event.svg';
import { ReactComponent as SvgTime } from '../../svg/time.svg';

const Header: React.FC<{
  componentMiddle:any
  componentLeft:any
}> = (props) => {

  const history = useHistory()

  const[isSideNav,setIsSideNav] = useState(false)

  const[username,setUserName] = useState("")

  useEffect(()=>{

    let sideNav =  document.getElementById("mySidepanel")!
    if(sideNav){
      setIsSideNav(true)
    }

    // $("#userInfo").on("click",function(){
    //   $("#userOptions").css("opacity","1")
    // })
  },[])


  function handleLogout() {
      applicationConfig.logger.info("Logged out")
      removeUserSession();
      window.location.href = ""

  }

  function closeNav() {
    document.getElementById("mySidepanel")!.style.width = "0";
  }

  return (
      <div className="white-card main-header-wrapper padding-container display-flex-row space-between">
       
        <div className="header-left-content">
          <div className='display-flex-row no-wrap'>
              <div className='brand-img center-aligned-column'> <img src="assets/icon/travel-power-icon.png" width={40} alt="" /> </div>
              <div className='heading marginleft5 center-aligned-column'>
                <div className=''>
                  <div className='heading2 normal line-height-1'>Welcome</div>
                  <div className='heading2 bold'>Travel Services</div>
                </div>
              </div>
          </div>
          {/* <props.componentLeft/> */}
        </div>
        <div className="header-middle-content">
           <props.componentMiddle/>
        </div>
        <div className="header-right-content">
           <div className="center-aligned-column" style={{ color: 'blue' }}>
              <SvgTime width={24} height={24} style={{ fill: 'blue' }} />
            </div>

            <div className="notification center-aligned-column">
              <SvgEvent width={24} height={24} style={{ fill: 'blue' }} />
            </div>
          

            <div className="white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} onClick={()=>{handleLogout()}}><i className="fas color-danger fa-power-off font-normal" area-hidden="true"/></div>
        </div>
       
      </div>
  );
};

export default Header;
