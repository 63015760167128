import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../utils/methods";

 

const UploadFile: React.FC<{
  setSelectedFile:(file:any)=>void
}> = (props) => {
  
  const [isOpenImageUpload, setIsOpenImageUpload] = useState(false);
  const [imageName, setImageName] = useState("Choose files");
  // const [imageSrc,setImageSrc] = useState(`https://opusassets.s3.ap-south-1.amazonaws.com/uploads/floorplan/${applicationConfig?.clientName}/${props.projectForm.projectName}/flooplan.png`);
 

 

  function selectImage(imageDetails:any) {
    setImageName(imageDetails.selectedFile.name)
    props.setSelectedFile(imageDetails.selectedFile)
  }
  



 
  return (
    <React.Fragment>
      <div className="input-container full-width" style={{border: "1px solid black"}}>
          <div className="display-flex-row full-width no-wrap" style={{border:"2px solid blue",width:"fit-content", justifyContent:"center"}}>
          <div className="heading4 color-dark overflow-hidden center-aligned-column width70" style={{background: "white"}}>{imageName}</div>
          <div className="center-aligned-column padding10 heading2 font-normal"  style={{borderLeft:"1px solid var(--op-color-dark)",width:"100px",background:"#D9D9D9"}}>Browse file</div>
          </div>
      </div>
  </React.Fragment>
        
  );
};

export default UploadFile;
