import React, { useEffect, useState } from "react";
import { IonModal } from "@ionic/react";
import { getQuotesList, getVisaQuotesList } from "../../../../services/api";
import { hideComponentLoader, showToast } from "../../../../utils/UI_methods/global";
import { applicationConfig } from "../../../../utils/methods";
import Loader from "../../../common/ui-components/Loader";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { colorFill } from "ionicons/icons";
import { bg } from "date-fns/locale";
import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from "constants";
import WattsappLink from "./WattsappLink";
import GifImage from "../../../../../public/assets/images/teamworking.gif";

const Options: React.FC<{
  setCurrOpt: (val: string) => void
  setCurrTab: (val: string) => void
  query: any
}> = (props) => {
  //Curr page can be leads list page or details page

  var xmlHttp: any;
  var ActiveXObject: any;
  const [currDate, setcurrDate]: any = useState(null);
  const [currOption, setCurrOption]: any = useState(null)
  const [currSelectedIndex, setCurrSelectedIndex]: any = useState(0)
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  const [visaOptions, setVisaOptions]: any = useState([]);
  const [options, setOptions]: any = useState([
    // {
    //   id:"12x1254",
    //   img:"",
    //   cf_1027:"The sun and wine hotel",
    //   cf_1025:"Log huts area, Old Manali, Himachal Pradesh",
    //   quoteId:"123456",
    //   cf_1087:"Twin Room",
    //   cf_925:"2",
    //   cf_1039:"Not required",
    //   cf_923:"20000/-",
    //   cf_1095:"No remarks",
    // }
  ])


  //-------- my function ---------//

  function srvTime() {
    try {
      //FF, Opera, Safari, Chrome
      xmlHttp = new XMLHttpRequest();
    }
    catch (err1: any) {
      //IE
      try {
        xmlHttp = new ActiveXObject('Msxml2.XMLHTTP');
      }
      catch (err2: any) {
        try {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
        }
        catch (err3: any) {
          //AJAX not supported, use CPU time.
          alert("AJAX not supported");
        }
      }
    }
    xmlHttp.open('HEAD', window.location.href.toString(), false);
    xmlHttp.setRequestHeader("Content-Type", "text/html");
    xmlHttp.send('');
    setcurrDate(xmlHttp.getResponseHeader("Date"));
  }
   
  // console.log("&&&&&&&", currDateFromFunction);

  //------- Current Date
  const currentDate = new Date(currDate);
  // console.log("current-Date", currentDate);

  //-------- Current Time
  const currentTime = currentDate.toLocaleTimeString().split(" ");
  const currentTimeGet = currentTime[0];

  //---------- Date Get From Backend
  const dateGet = new Date(new Date(currOption?.cf_1051));
  // console.log("Backend-Date"dateGet);

  //----------- Time Get From Backend
  const timeGet = currOption?.cf_1053;
  // console.log(timeGet);

  // console.log(currentTimeGet < timeGet);

  useEffect(() => {
    srvTime()

    // console.log("konsi id hai ye", props.query.id);
    if (props.query.cf_983?.toLowerCase() === "accommodation") {
      getQuotesList(props.query.id).then((data: any) => {
        console.log("Quote Data", data);
        if (data.data.quotes_list) {
          let list: Array<any> = data.data.quotes_list
          list.reverse()
          setOptions(list)
          setCurrSelectedIndex(0)
          applicationConfig.logger.info(`Showing quotes`)
        }
        hideComponentLoader("optionsLoader")
      }).catch(err => {
        console.log(err)
        hideComponentLoader("optionsLoader")
      })
    }

    if (props.query.cf_983?.toLowerCase() === "visa") {
      getVisaQuotesList(props.query.id).then((data: any) => {
        console.log("Visa-Quote Data", data);
        if (data.data.quotes_list) {
          let list: Array<any> = data.data.quotes_list
          list.reverse()
          setOptions(list)
          setCurrSelectedIndex(0)
          applicationConfig.logger.info(`Showing Visaquotes`)
        }
        hideComponentLoader("optionsLoader")
      }).catch(err => {
        console.log("Errores********", err)
        hideComponentLoader("optionsLoader")
      })
    }
  }, [])


  useEffect(() => {
    if (options.length) {
      showToast(`Showing Option ${currSelectedIndex + 1}`, 2000)
    }
    setCurrOption(options[currSelectedIndex])
  }, [currSelectedIndex, options])


  function confirmBookingAction() {
    props.setCurrTab("edit");

    // my nick added
    props.setCurrOpt(currOption);
  }

  function nextHandle() {
    if (currSelectedIndex === options.length - 1) {
      showToast("No more options", 2000)
      return
    }
    setCurrSelectedIndex((prevValue: any) => {
      return prevValue + 1
    })
  }

  function prevHandle() {
    if (currSelectedIndex === 0) {
      showToast("Showing first Quotation", 2000)
      return
    }
    setCurrSelectedIndex((prevValue: any) => {
      return prevValue - 1
    })
  }

  function getArrayFromNumber(num: number) {
    let array = []
    for (let i = 0; i < num; i++) {
      array.push(i);
    }
    return array
  }

  function getFommatedDate(currdate: any) {
    const date = new Date(currdate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const year = date.getFullYear();
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
    return formattedDate
  }

  function FixedAmountDecimal(currAmt: any) {
    const amount = Number(currAmt).toFixed(2);
    return amount;
  }

  function goToWattsappLink() {
    console.log("Wattsapp Link......");
    setIsOpenForm(true);
  }


  return (
    <div className="position-relative full-width ">

      <Loader
        data={{}}
        className="--is-active fade-background-loader"
        id="optionsLoader"
        loaderType="spinner"
      />

      {options?.length ?
        <div className="full-width overflow-x-scroll">

          <IonModal
            onDidDismiss={() => setIsOpenForm(false)}
            isOpen={isOpenForm}
            id="inventoryModal"
            cssClass='vertical-modal my-modal-wattsapp'
          >
            <WattsappLink optionsData={currOption} closeQuery={() => setIsOpenForm(false)} />
          </IonModal>

          <div className="display-flex-row no-wrap padding5 space-between">
            <div className="center-aligned-column">
              <Button2
                configObj={{ id: "" }}
                action={prevHandle}
                label={"Prev"}
                fontAwesomeIcon={"fas fa-chevron-left  color-black"}
                iconPos="left"
                classNames={"button-small shadow-light border bg-color-light"}
              />
              {/* <div className="center-aligned-column"><i className="fas fa-chevron-left font-large color-black"></i></div> */}
            </div>
            <div className="center-aligned-column">
              <div className="center-aligned-column heading-medium color-ex-dark"><p className="heading-medium">Option: <span className="heading-medium" style={{ color: "blue" }}>{currSelectedIndex + 1}</span> of {options.length} </p></div>
            </div>
            <div className="center-aligned-column">
              <Button2
                configObj={{ id: "" }}
                action={nextHandle}
                label={"Next"}
                fontAwesomeIcon={"fas fa-chevron-right color-black"}
                iconPos="right"
                classNames={"button-small shadow-light border bg-color-light"}
              />
              {/* <div className="center-aligned-column"><i className="fas fa-chevron-right font-large color-black"></i></div> */}
            </div>
          </div>


          <div className="full-width white-card bg-color-ex-light shadow-light ion-padding ion-margin-top">

            <div className="display-flex-row no-wrap" style={{ justifyContent: "space-between" }}>

              <div className="">
                <div className="heading-medium color-ex-dark capitalize" style={{ display: "flex", alignItems: "center" }}>
                  {props.query.cf_983?.toLowerCase() === "accommodation" ?
                    currOption?.cf_1027
                    :
                    props.query.cf_983?.toLowerCase() === "visa" ?
                      currOption?.cf_1143
                      :
                      null
                  }
                  <div className="heading4 marginTop5 marginleft10">
                    {currOption?.cf_1025}
                  </div>
                </div>

                {props.query.cf_983?.toLowerCase() === "accommodation" ?
                  <div className="display-flex-row no-wrap marginTop5 ">
                    {getArrayFromNumber(currOption?.cf_1091).map((_: any, index: number) => (
                      <i key={index} className="fas fa-star color-warning marginRight5 font-normal"></i>
                    ))}
                  </div>
                  :
                  props.query.cf_983?.toLowerCase() === "visa" ?
                    <></>
                    :
                    null
                }
              </div>

              <div className="btn-grp">
                <div className="display-flex-row no-wrap">
                  <Button2
                    configObj={{ id: "" }}
                    action={goToWattsappLink}
                    label={""}
                    fontAwesomeIcon={"fab fa-whatsapp color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light"}
                  />
                  <Button2
                    configObj={{ id: "" }}
                    action={() => { }}
                    label={""}
                    fontAwesomeIcon={"far fa-envelope color-black"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-light"}
                  />
                </div>
              </div>
            </div>


            <div className="other-details full-width ion-margin-top">

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row margin">
                  <div className="heading-small width50">Quote ID</div>
                  <div className="heading4 color-dark  width50">{currOption?.quote_no}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <div className="display-flex-row margin">
                    <div className="heading-small width50">Visa Type</div>
                    <div className="heading4 color-dark  width50">{currOption?.cf_1145}</div>
                  </div>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row margin">
                  <div className="heading-small width50">Check In</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1029 == props.query.cf_935 ? currOption?.cf_1029 : <p className="heading4 " style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_935}</p></s> {currOption?.cf_1029}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <div className="display-flex-row margin">
                    <div className="heading-small width50">Visa Category</div>
                    <div className="heading4 color-dark  width50">{currOption?.cf_1147}</div>
                  </div>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row margin">
                  <div className="heading-small width50">Check Out</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1031 == props.query.cf_937 ? currOption?.cf_1031 : <p className="heading4 " style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_937}</p></s> {currOption?.cf_1031}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <div className="display-flex-row margin">
                    <div className="heading-small width50">Visa Notes</div>
                    <div className="heading4 color-dark  width50">{currOption?.cf_1163}</div>
                  </div>
                  :
                  null
              }


              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">Room Category</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1087 == props.query.cf_987 ? currOption?.cf_1087 : <p className="heading4 " style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_987}</p></s> {currOption?.cf_1087}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">No. of Rooms</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1093 == props.query.cf_925 ? currOption?.cf_1093 : <p className="heading4 " style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_925}</p></s> {currOption?.cf_1093}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">Meal Plan</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1039 == props.query.cf_933 ? currOption.cf_1039 : <p className="heading4 " style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_933}</p></s> {currOption?.cf_1039}</p>}</div>
                  {/* <div className="heading4 color-dark  width50">{currOption?.cf_1039}</div> */}
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">No. of Adults</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1089 == props.query.cf_927 ? currOption?.cf_1089 : <p className="heading4" style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_927}</p></s> {currOption?.cf_1089}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }


              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">No. of Children</div>
                  {/* {Number(props.query.cf_929) + Number(props.query.cf_931)} */}
                  <div className="heading4 color-dark  width50">{currOption?.cf_1047 == props.query.cf_929 && currOption?.cf_1049 == props.query.cf_931 ? Number(currOption?.cf_1047) + Number(currOption?.cf_1049) : <p className="heading4" style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{Number(props.query.cf_929) + Number(props.query.cf_931)}</p></s> {Number(currOption?.cf_1047) + Number(currOption?.cf_1049)}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {/* {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">No. of Childrens CNB</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1049 == props.query.cf_931 ? currOption?.cf_1049 : <p className="heading4" style={{ display: "flex", gap: "5px", color: "red", fontWeight: "" }}><s style={{ color: "black" }}><p className="heading4" style={{ color: "grey", fontWeight: "" }}>{props.query.cf_931}</p></s> {currOption?.cf_1049}</p>}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              } */}

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">Amount</div>
                  <div className="heading4 color-dark  width50">&#x20B9; {FixedAmountDecimal(currOption?.hdnGrandTotal)}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">Remarks</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1095}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <div className="display-flex-row marginTop5">
                  <div className="heading-small width50">Cancellation Policy</div>
                  <div className="heading4 color-dark  width50">{currOption?.cf_1041}</div>
                </div>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <></>
                  :
                  null
              }

              {props.query.cf_983?.toLowerCase() === "accommodation" ?
                <></>
                :
                props.query.cf_983?.toLowerCase() === "visa" ?
                  <div className="display-flex-row marginTop10">
                    <div className="heading-small width50">Total Amount</div>
                    <div className="heading4 color-dark  width50"> &#x20B9; {Number(currOption?.hdnGrandTotal).toFixed(2)}</div>
                  </div>
                  :
                  null
              }

            </div>
            {/* `position-relative padding10 zindex30 full-width button2 bg-color-primary white-card click-effect` */}

            {
              currentDate < dateGet ?
                //  currentTime <= timeGet 
                <div className="add-new center-aligned-column full-width marginTop10">
                  <div onClick={confirmBookingAction} className={`position-relative padding10 zindex30 full-width button2 bg-color-primary white-card click-effect`} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", minHeight: "3rem" }}>
                    <p className="text font-medium  pointer-none" style={{ fontWeight: "bold", fontSize: "" }}>Send For Confirmation</p>
                    <p className="heading4 color-white font-small pointer-none" style={{ marginTop: "2px" }}>Before {getFommatedDate(currOption?.cf_1051)} | {currOption?.cf_1053}</p>
                    {/* <span className="text middle pointer-none" style={{fontWeight: "bold", fontSize: "15px"}}>Confirm Booking</span> */}
                    {/* <span className="heading4 right-middle color-white font-small  pointer-none" style={{ marginTop: "2px" }}>{getFommatedDate(currOption?.cf_1051)} | {currOption?.cf_1053}</span> */}
                  </div>
                </div>
                :
                currentDate == dateGet ?
                  currentTimeGet < timeGet ?
                    <div className="add-new center-aligned-column full-width marginTop10">
                      <div onClick={confirmBookingAction} className={`position-relative padding10 zindex30 full-width button2 bg-color-primary white-card click-effect`} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", minHeight: "3rem" }}>
                        <p className="text font-medium  pointer-none" style={{ fontWeight: "bold", fontSize: "" }}>Send For Confirmation</p>
                        <p className="heading4 color-white font-small pointer-none" style={{ marginTop: "2px" }}>Before {getFommatedDate(currOption?.cf_1051)} | {currOption?.cf_1053}</p>
                        {/* <span className="text middle pointer-none" style={{fontWeight: "bold", fontSize: "15px"}}>Confirm Booking</span> */}
                        {/* <span className="heading4 right-middle color-white font-small  pointer-none" style={{ marginTop: "2px" }}>{getFommatedDate(currOption?.cf_1051)} | {currOption?.cf_1053}</span> */}
                      </div>
                    </div>
                    :
                    null
               :
               null
            }


          </div>
        </div>

        :
        <div className="ion-padding center-aligned-column options-container" style={{ position: "relative" }}>
          <div style={{ height: "3rem", position: "absolute", top: "10px" }} className="ion-padding options-content">Our team is working on your query, check back in some time</div>
          <img className="options-img" src="/assets/images/teamworking.gif" alt="" width={250} style={{ border: "" }} />
        </div>
      }




    </div>
  );
};

export default Options;
