import $ from "jquery";
import React, { useEffect, useState } from "react";


const HotelCategory: React.FC<{
  formData: any
  setCurrStep: (val: string) => void;
}> = (props) => {

  // console.log("Form data Rating", props.formData.hotel_category);
  useEffect(() => {
    setTimeout(() => {
      updateClass(props.formData.hotel_category)
    }, 500);
  }, [props.formData])

  function onchangeHotelCategory(value: number) {
    props.formData.setHotelCategory(String(value))
  }


  function updateClass(index: number) {
    // console.log("Update function called with hotel category", index);

    $(".hotel-star").removeClass("color-warning")
    $(".hotel-star").removeClass("fas")

    $(".hotel-star").addClass("color-dark")
    $(".hotel-star").addClass("far")

    onchangeHotelCategory(index)
    let stars = document.querySelectorAll(".hotel-star")
    // console.log(stars);

    for (let i = 0; i < index; i++) {
      const element = stars[i];

      if (i <= index) {
        // console.log("Setting value for", i, " as enabled");
        $(element).addClass("color-warning")
        $(element).addClass("fas")

        $(element).removeClass("color-dark")
        $(element).removeClass("far")
      }

    }

  }

  const [values, setValues] = useState(null)


  return (
    <div className="full-width" style={{ paddingLeft: "15px" }}>
      <div className="input-wrapper full-width">
        <div className="" >
          <div className="label full-width ">
            Hotel Category
          </div>
          <div className="center-aligned marginTop10">
            <div className="display-flex-row no-wrap">
              <i className="far fa-star font-large color-dark hotel-star" onClick={() => updateClass(1)}></i>
              <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(2)}></i>
              <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(3)}></i>
              <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(4)}></i>
              <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(5)}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCategory;

