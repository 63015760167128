import {
  IonCheckbox
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { registerUser } from "../../../services/api";
import { showToast } from "../../../utils/UI_methods/global";
import { industryValues, registerFormBillingDetails, registerFormWithGST } from "../../../utils/data";
import { getRegisterUserData } from "../../../utils/forms/helper";
import { mandatoryFieldAction } from "../../../utils/forms/validation";
import Button2 from "../../common/ui-components/buttons/Button2";

const Form2: React.FC<{
  setCurrStep: (val: string) => void
}> = (props) => {

  const [loading, setLoading] = useState(false);
  const [isUnderGst, setIsUnderGst] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmedPolicy, setIsConfirmedPolicy] = useState(false);
  const [isTrvelCompany, setISTravelCompany] = useState(false);
  const [owenershipValue, setOwnershipValue] = useState("");

  var registerUserData = {}

  var history = useHistory()

  useEffect(() => {
    if (isTrvelCompany) {
      // let ownershipField = getObjectByParameter(registerFormWithoutGST,"fieldName","Ownership")
      setOwnershipValue("Travel & Tourism")
    } else {
      setOwnershipValue("Select ownership")
    }
  }, [isTrvelCompany])


  function isTravelAgencyChangeHandle(value: boolean) {
    setISTravelCompany(value)
  }

  function gotoLoginPage() {
    history.push("/login")
  }

  function registerUserHandle() {
    let registerUserData = getRegisterUserData()
    registerUserData["are_you_a_travel_company"] = isTrvelCompany
    registerUserData["is_gst"] = isUnderGst
    console.log("***********On submit register formr", registerUserData);
    registerUser(registerUserData).then((data: any) => {
      console.log(data.data)
      showToast(data.data.code + ", " + data.data.message, 3000)
    }).catch(err => {
      console.log(err)
      showToast(err, 3000)
    })
  }


  return (
    <div className="form-container white-card display-flex-row  full-width" style={{ padding: "25px 10px" }}>
     
      {/* <div className="top-middle ion-padding-bottom ion-padding-top text-center">
        <img width={150} src="assets/images/travel_power_logo.png" alt="" />
      </div> */}

      <div className="form-container full-width">
        <div className="form-label-input-container full-width padding10">
          <div className="label-container full-width">
            <div className="label full-width">
              Are you a travel company ?  <span className="mandatory color-danger">*</span>
            </div>
            <div className="display-flex-row ion-margin-top">
              <div className="display-flex-row  no-wrap">
                <input type="radio" onChange={() => (isTravelAgencyChangeHandle(true))} name="istravelagency" value="yes" />
                <label>Yes</label>
              </div>
              <div className="display-flex-row no-wrap ion-margin-start">
                <input type="radio" onChange={() => (isTravelAgencyChangeHandle(false))} name="istravelagency" value="no" />
                <label>No</label>
              </div>
            </div>
          </div>
          <div className="">
            <div className="label-container">
              <div className="label">
                Industry <span className="mandatory color-danger">*</span>
              </div>
            </div>
            <div className="input-container">
              <select className={isTrvelCompany ? "input disable" : "input"} name="" >
                {industryValues.map((val: string, index1: number) => (
                  <option key={index1} value={val}>{isTrvelCompany ? owenershipValue : val}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="form-label-input-container padding10">
        <div className="display-flex-row no-wrap">
          <IonCheckbox onIonChange={(event) => setIsUnderGst(!isUnderGst)}></IonCheckbox>
          <div className="input-label marginleft10">My business is registered under GST </div>
        </div>
      </div>


      {isUnderGst ?
        <div className="form-container display-flex-row  full-width">
          {registerFormWithGST.map((details: any, index: any) => (
            <div className="form-label-input-container width50 padding10" key={index}>
              <div className="label-container">
                <div className="label">
                  {details.fieldName} <span className="mandatory color-danger">*</span>
                </div>
              </div>
              <div className="input-container position-relative">
                {details.inputType === "text" || details.inputType === "number" ?
                  <div className="input-container">
                    <div className="required-text">Required</div>
                    <input id={details.id} className={`input ${details.className}`} onBlur={(event) => mandatoryFieldAction(event, details.mandatory)} onChange={details.onChange} type={details.inputType} name="" />
                    <ul className="color-danger error font-small"></ul>
                  </div>
                  : details.inputType === "select" ?
                    <div className="input-container">
                      <select id={details.id} className="input" name="">
                        {details.value.map((val: string, index1: number) => (
                          <option key={index1} value={val}>{val}</option>
                        ))}
                      </select>
                    </div>
                    :
                    <div className="display-flex-row no-wrap ">
                      <IonCheckbox></IonCheckbox>
                      <div className="input-label marginleft10">{details.fieldName}</div>
                    </div>
                }
              </div>
            </div>

          ))}

        </div>
        : null}

      <div className="form-container display-flex-row  full-width">
        {registerFormBillingDetails.map((details: any, index: any) => (
          <div className="form-label-input-container width50 padding10" key={index}>
            <div className="label-container">
              <div className="label">
                {details.fieldName} <span className="mandatory color-danger">*</span>
              </div>
            </div>
            <div className="input-container position-relative">
              {details.inputType === "text" || details.inputType === "number" ?
                <div className="input-container">
                  <div className="required-text">Required</div>
                  <input id={details.id} className={`input ${details.className}`} onBlur={(event) => mandatoryFieldAction(event, details.mandatory)} onChange={details.onChange} type={details.inputType} name="" />
                  <ul className="color-danger error font-small"></ul>
                </div>
                : details.inputType === "select" ?
                  <div className="input-container">
                    <select id={details.id} className="input" name="">
                      {details.value.map((val: string, index1: number) => (
                        <option key={index1} value={val}>{val}</option>
                      ))}
                    </select>
                  </div>
                  :
                  <div className="display-flex-row no-wrap ">
                    <IonCheckbox></IonCheckbox>
                    <div className="input-label marginleft10">{details.fieldName}</div>
                  </div>
              }
            </div>
          </div>
        ))}
      </div>


      <div className="form-label-input-container padding10">
        <div className="display-flex-row no-wrap">
          <IonCheckbox onIonChange={(event) => setIsConfirmed(!isConfirmed)}></IonCheckbox>
          <div className="input-label marginleft10">I accept the Terms of use & Privacy Policy </div>
        </div>
      </div>

      <div className="form-label-input-container padding10">
        <div className="display-flex-row no-wrap">
          <div><IonCheckbox onIonChange={(event) => setIsConfirmedPolicy(!isConfirmedPolicy)}></IonCheckbox></div>
          <div className="input-label marginleft10">I confirm that I am authorized to register on this portal on behalf of my company. I also confirm that all the details provided here are true and accurate</div>
        </div>
      </div>

      <div className="ion-padding-top full-width">
        <Button2
          configObj={{}}
          action={() => { props.setCurrStep("step1") }}
          label={"Prev"}
          fontAwesomeIcon={"fas fa-chevron-left color-danger"}
          iconPos="left"
          classNames={"shadow-light bg-color-ternary login-button center-aligned-column full-width"}
        />
        <div className="padding5"></div>
        <Button2
          configObj={{}}
          action={registerUserHandle}
          label={loading ? 'Please wait...' : 'Submit'}
          fontAwesomeIcon={"fas fa-chevron-right color-white"}
          iconPos=""
          classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
        />
      </div>

      <div className="marginTop5 full-width">
        <div className="dislay-flex-row text-center padding5 heading3">Already have an account? <span onClick={gotoLoginPage} className="heading3 bold underline pointer" style={{ color: "blueviolet" }}>Sign in!</span></div>
      </div>
    </div>
  );
};

export default Form2;
