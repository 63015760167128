import React from 'react';
import { logout } from '../../../services/api';
import { applicationConfig, removeUserSession } from '../../../utils/methods';
import { useHistory } from 'react-router';
// import './Home.css';



const Footer: React.FC = () => {

  var history = useHistory()

  function handleLogout() {
    // logout().then((response:any) => {
    //     history.push("/login")
    //     removeUserSession();
    //   })
    //   .catch((error:any) => {
    //   });
      applicationConfig.logger.info("Logged out")
      removeUserSession();
      window.location.href = ""

  }


  return (
     <React.Fragment>
      <div className='display-flex-row bg-color-primary center-aligned-column main-footer hide-in-desktop' >
        <div className='container display-flex-row no-wrap'>
          <div className='icon-wrapper --is-active center-aligned-column'>
            <img className='icon' src="assets/icon/footer/Home.png" alt="" />
            <div className='heading3 font-small text text-center color-white'>Home</div>
          </div>
          <div className='icon-wrapper center-aligned-column'>
            <img className='icon' src="assets/icon/footer/Payment-Gateway.png" alt="" />
          </div>
          <div className='icon-wrapper center-aligned-column'>
            <img className='icon' src="assets/icon/footer/contact.png" alt="" />
          </div>
          <div className='icon-wrapper center-aligned-column'>
            <img className='icon' src="assets/icon/footer/setting.png" alt="" />
          </div>
          <div className='icon-wrapper center-aligned-column' onClick={handleLogout}>
            <img className='icon' src="assets/icon/footer/LogOut.png" alt="" />
          </div>
        </div>
      </div>
     </React.Fragment>
  );
};

export default Footer;
