import React, { useEffect, useState } from 'react';
import { createOpportunity } from '../../../../services/api';
import { showToast } from '../../../../utils/UI_methods/global';
import { hotelNameValidation } from '../../../../utils/forms/validation';
import { applicationConfig } from '../../../../utils/methods';
import { cabBooking } from "../../../../utils/forms/cabBooking"
import Button2 from '../../../common/ui-components/buttons/Button2';
import Header from '../common/Header';
import TourFrom from './TourFrom';
import Date_TimeOfArrival from './Date_TimeOfArrival';
import PlacesCovered from './PlacesCovered';
import TourEnd from './TourEnd';
import NumberOfTravellers from './NumberOfTravellers';
import PreferredVehicle from './PreferredVehicle';
import SpecialRequirements from './SpecialRequirements';
import Date_TimeOfLastDrop from './Date_TimeOfLastDrop';
import GuestNationality from "./GuestNationality";
import Other from "./Other";

const CabBooking: React.FC<{
    isUpdateMode: boolean
    closeAction: () => void
}> = (props) => {
    
    const [currStep, setCurrStep] = useState("step1")
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [formData, setFormData]: any = useState(new cabBooking())
    const [error, setError] = useState("")

    function submit() {
        // if (!formData.start_tour_from?.length || !formData.end_tour_at?.length || !formData.date_of_arrival?.length || !formData.date_of_lastDrop?.length || !formData.places_to_be_covered?.length || !formData.no_of_travellers?.length || !formData.preferred_vehicle?.length || !formData.special_requirements?.length) {
        //     showToast("Please Fill Mandatory Fields", 3000, "error");
        //     setError("Fill Mandatory Fields");
        //     return;
        // }

        if(formData.start_tour_from === ""){
            showToast("Please enter Start Tour From", 3000, "error");
            return;
        }
        
        if(formData.date_of_arrival === ""){
            showToast("Please enter Date & Time of Arrival", 3000, "error");
            return;
        }

        if(formData.places_to_be_covered === ""){
            showToast("Please enter Places to be Covered", 3000, "error");
            return;
        }

        if(formData.end_tour_at === ""){
            showToast("Please enter End Tour At", 3000, "error");
            return;
        }

        if(formData.date_of_lastDrop === ""){
            showToast("Please enter Date & Time of Last Drop", 3000, "error");
            return;
        }

        if(formData.guest_nationality === ""){
            showToast("Please enter Guest Nationality", 3000, "error");
            return;
        }

        if(formData.no_of_travellers === ""){
            showToast("Please enter No of Travellers", 3000, "error");
            return;
        }

        if(formData.no_of_travellers === ""){
            showToast("Please enter No of Travellers", 3000, "error");
            return;
        }

        if(formData.preferred_vehicle === ""){
            showToast("Please enter Preferred Vehicle", 3000, "error");
            return;
        }

        if(formData.how_soon_are_you_willing_to_continue === ""){
            showToast("Please select how soon are you willing to continue", 2000, "error")
            return
          }

        if(formData.special_requirements === ""){
            showToast("Please enter Special Requirements", 3000, "error");
            return;
        }

        
        console.log("Cab-Booking", formData);

    }

    return (

        <div className="wrapper create-query-wrapper full-height-width position-relative">

            <div className='header-content'>
                <Header
                    heading="Cab Booking"
                    closeAction={props.closeAction}
                />
            </div>
            <div className='body-content'>

                <div className="select-location-wrapper ion-padding">

                    <div className="position-relative">

                        {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

                        <TourFrom formData={formData} />

                        <Date_TimeOfArrival formData={formData} setArrivalDate={setArrivalDate} />

                        <PlacesCovered formData={formData} />

                        <TourEnd formData={formData} />

                        <Date_TimeOfLastDrop formData={formData} arrivalDate={arrivalDate} />

                        <GuestNationality formData={formData} />

                        <NumberOfTravellers formData={formData} />

                        <PreferredVehicle formData={formData} />

                        <Other formData={formData}  setCurrStep={setCurrStep} />
                        
                       <SpecialRequirements formData={formData} />

                    </div>
                </div>
            </div>

            <div className='footer-content'>
                <div className="center-aligned-row ion-padding">
                    {!props.isUpdateMode ?
                        <Button2
                            configObj={{}}
                            action={submit}
                            label={"Submit"}
                            fontAwesomeIcon={"fas fa-clipboard color-danger"}
                            iconPos=""
                            classNames={"shadow-light margin0 bg-color-primary"}
                        />
                        :
                        <Button2
                            configObj={{}}
                            action={props.closeAction}
                            label={"Update"}
                            fontAwesomeIcon={"fas fa-clipboard color-danger"}
                            iconPos=""
                            classNames={"shadow-light margin0 bg-color-success"}
                        />
                    }
                </div>
            </div>

        </div>
    )

};

export default CabBooking;




