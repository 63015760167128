  
  import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../utils/methods";
import { validateSession } from "../../../../services/api";
import { error } from "console";
  
  const Header: React.FC<{
    heading:string
    closeAction:()=>void
    // homeAction:()=>void
  }> = (props) => {

 
    let steps = ["step1","step2","step3","step4","step5","step6","step7","step8"];

    // Check session is Validate or not
    
    let [demoSession, setDemoSession] = useState(false);

    useEffect(() => {
      validateSession().then((data: any) => {
        console.log("ValidateSession Response", data);
      }).catch((error) => {
        console.log("ValidateSession Error", error);
      }) 
    }, [])

    useEffect(() => {
          if(demoSession === true){
             console.log("Your session is valid");
          }
    }, [demoSession])

    // function goBack() {
    //   let index = steps.indexOf(applicationConfig.states.query.currStepHotelBooking)
    //   if(index>0){
    //     applicationConfig.functions.query.setCurrHotelStep(steps[index-1])
    //   }else{
    //     applicationConfig.functions.query.closeCreateQueryForm(false)
    //   }
    // }
 

 
  
    return(
        <div className="create-query-header bg-color-light position-relative full-height-width ">
          <div className="full-width display-flex-row space-between">
            {/* <div className="" onClick={props.closeAction}> <i className="color-black hide-in-desktop font-medium fas fa-arrow-left"></i> </div> */}
            <div onClick={props.closeAction} className=""> <i className="color-black right-middle font-medium fas fa-times"></i> </div>
          </div>
          <div className="heading1 middle text-center letter-spacing1">{props.heading}</div>
        </div>
      )
  };
  
  export default Header;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
  