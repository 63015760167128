import React, { useEffect, useState } from 'react';
// import { IonModal } from "@ionic/react";
import { createOpportunity, getNationalityList } from '../../../../services/api';
import { showToast } from '../../../../utils/UI_methods/global';
import { HotelForm } from '../../../../utils/forms/hotel';
import { hotelNameValidation } from '../../../../utils/forms/validation';
import { applicationConfig } from '../../../../utils/methods';
import Button2 from '../../../common/ui-components/buttons/Button2';
import Header from '../common/Header';
import Dates from './Dates';
import HotelCategory from './HotelCategory';
import HotelOptions from './HotelOptions';
import Location from './Location';
import MealPlan from './MealPlan';
import Other from './Other';
import Nationality from './Nationality';
//import RoomSelector from './room-selector/RoomSelector';
import RoomSelector from './room-selector/RoomSelectorWithRoomDetails';
import QueryCreatedSuccess from '../../queryList/query-details/QueryCreatedSuccess';



const Hotel: React.FC<{
  isUpdateMode: boolean
  closeAction: () => void
  query: any
}> = (props) => {

  console.log("Query Data", props.query);

  const [currStep, setCurrStep] = useState("step1")
  const [formData, setFormData]: any = useState(new HotelForm(null))
  // const [isOpenQuerySuccess, setIsOpenQuerySuccess] = useState<boolean>(false);


  useEffect(() => {
    if (props.query) {
      setFormData(null)
      let form = new HotelForm(props.query)
      // console.log("Nick Dat", props.query)
      setFormData(form)
    }
  }, [props.query])

  useEffect(() => {
    applicationConfig.setState("query", { currStepHotelBooking: currStep })
    applicationConfig.setFunctionRef("query", { setCurrHotelStep: setCurrStep })
  }, [currStep])

  useEffect(() => {
     getNationalityList();
  }, [])

  function onchangespecialRequirement(event: any) {
    hotelNameValidation(event)
    formData.setSpecialRequirement(event.target.value)
  }

  function submit() {
    if (formData.destination_city === "") {
      showToast("Please enter destination", 2000, "error")
      return
    }
    if (formData.check_in === "" || formData.check_out === "") {
      showToast("Please enter checkin checkout date", 2000, "error")
      return
    }
    if(formData.nationality === ""){
      showToast("Please select nationality", 2000, "error")
      return
    }
    if (formData.budget === 0 && (formData.hotel_name === "" || formData.room_category === "")) {
      showToast("Please enter budget or hotel name / category", 2000, "error")
      return
    }
    if(formData.how_soon_are_you_willing_to_continue === ""){
      showToast("Please select how soon are you willing to continue", 2000, "error")
      return
    }

    // console.log("formDataHotel", formData);

    createOpportunity(formData).then((data: any) => {
      if (data.data.success) {
        // setIsOpenQuerySuccess(true);
        applicationConfig.logger.info(data.data.message)
        window.location.href = "/queries"
      } else {
        showToast(data.data.message, 2000, "error")
        applicationConfig.logger.error(data.data.message)
      }
      // applicationConfig.functions.query.closeCreateQueryForm(false)
      // showToast("Query created successfully",500000)
      // alert("Query created")
    }).catch(err => {
      applicationConfig.logger.info(`Error in creating accomodation query`)
      console.log(err)
    })
  }

  function update() {
    console.log("FormData after update", formData);
  }

  return (

    <div className="wrapper create-query-wrapper full-height-width position-relative">
      
      <div className='header-content'>
        <Header
          heading="Hotel Booking"
          closeAction={props.closeAction}
        />
      </div>
      <div className='body-content'>

        <div className="select-location-wrapper ion-padding">

          <div className="position-relative">

            {props.isUpdateMode ?
              <div className="input-wrapper">
                <div className="" >
                  <div className="label full-width">
                    Query ID
                  </div>
                  <div className="-aligned-row">
                    <input defaultValue={formData.query_id} disabled={false} className="search-input disabled input " />
                  </div>
                </div>
                <div className="display-flex-row marginTop5" style={{ justifyContent: "center" }}>
                  <ul className="color-danger error font-small"></ul>
                </div>
              </div>
              :
              null
            }

            <Location
              formData={formData}
              setCurrStep={setCurrStep}
              isUpdateMode={props.isUpdateMode}
            />

            <Dates
              formData={formData}
              setCurrStep={setCurrStep}
            />

            <RoomSelector
              formData={formData}
              setCurrStep={setCurrStep}
            />

            <Nationality 
               formData={formData}
               setCurrStep={setCurrStep}
            />

            <HotelOptions
              formData={formData}
              setCurrStep={setCurrStep}
            />

            <div className='display-flex-row nowrap space-between ion-margin-top'>
              <div className='width50'>
                <MealPlan
                  formData={formData}
                  setCurrStep={setCurrStep}
                />
              </div>
              <div className='width50'>
                <HotelCategory
                  formData={formData}
                  setCurrStep={setCurrStep}
                />
              </div>
            </div>

            <Other
              formData={formData}
              setCurrStep={setCurrStep}
            />



            <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
              <div className="label full-width">Special Request</div>
              <div className="input-wrapper">
                <div className="center-aligned-row">
                  <textarea defaultValue={formData.special_request} onChange={(event) => onchangespecialRequirement(event)} rows={5} cols={50} className="search-input input" style={{ height: "unset" }} />
                </div>
                <div className="display-flex-row marginTop5" style={{ justifyContent: "center" }}>
                  <ul className="color-danger error font-small"></ul>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div className='footer-content'>
        <div className="center-aligned-row ion-padding">
          {!props.isUpdateMode ?
            <Button2
              configObj={{}}
              action={submit}
              label={"Submit"}
              fontAwesomeIcon={"fas fa-clipboard color-danger"}
              iconPos=""
              classNames={"shadow-light margin0 bg-color-primary"}
            />
            :
            <Button2
              configObj={{}}
              action={update}
              label={"Update"}
              fontAwesomeIcon={"fas fa-clipboard color-danger"}
              iconPos=""
              classNames={"shadow-light margin0 bg-color-success"}
            />
          }

        </div>
      </div>

    </div>
  )
};

export default Hotel;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}

