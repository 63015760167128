import React, { useEffect, useState } from "react";
import $ from "jquery";
import { stringWithoutWhiteSpace } from "../../../../utils/methods";
import { IonIcon } from "@ionic/react";
  
  const ControlsTab: React.FC<{
    configObj:any
    tabItems:Array<any>
    currSelectedValue:string
    action:(value:any)=>void
    theme:string
  }> = (props) => {

    const[fillBgColor,setFillBgColor] = useState("")

    var pos = 0

    function updateTabValue(event:any,val:string){
      props.action(val)
    }

    useEffect(()=>{
      if(props.theme === "red"){
        setFillBgColor("rgb(0,255,255)")
      }else{
        setFillBgColor("rgb(0,0,0)")
      }
    },[])
   
 
    return (
      <div className="display-flex-row position-relative" style={{padding:"0 0"}}>

      {/* <div className="center-aligned-column right-middle zindex30" style={{right:"0"}}> <i className="fas fa-chevron-right color-black"></i> </div>
      <div className="center-aligned-column left-middle zindex30" style={{left:"0"}}> <i className="fas fa-chevron-left color-danger"></i> </div> */}
      <div className="controls-tab-container border-bottom hide-scrollbar">
        {props.tabItems.length?props.tabItems.map((currTab:any,index:number)=>{
          let classNames = []
          classNames.push("controls-tab display-flex-row")
          if(props.theme === "black"){
            classNames.push("controls-tab-black")
          }
          if(props.theme === "red"){
            classNames.push("controls-tab-red")
          }
          if(stringWithoutWhiteSpace(currTab.tabName)=== stringWithoutWhiteSpace(props.currSelectedValue)){
            classNames.push("--is-active")

            //100to convert into percentages 
            let index = 0
            for (let i = 0; i < props.tabItems.length; i++) {
              let tab = props.tabItems[i]
              if(tab.tabName === props.currSelectedValue){
                index = i
              }
            }
            if(index != 0){
              pos = 100 * (index / props.tabItems.length)
            }
          }
          return(
            <div className={classNames.join(" ")} key={index} onClick={(event) => updateTabValue(event,currTab.tabName)}>
              {currTab.ionIcon?
              <div className="center-aligned-column marginRight5"> <IonIcon className="center-aligned-column icon font-large" icon={currTab.ionIcon}></IonIcon> </div>
              :null}
            <div className="pointer-none text center-aligned-column" style={{pointerEvents:"none"}}>{currTab.label}</div>
            </div>
          )
        }):null}
      
      </div>

      <div className="controls-tab-active-bar">
        <div className="controls-bar-fill top-left" id="controlsTabFill"  style={{left:`${pos}%`,width:`calc(100% / ${props.tabItems.length})` ,padding:"0",background:props.theme==="red"?"var(--krab-color-danger)":"black"}}></div>
      </div>

      </div>
    );
  };
  
  export default ControlsTab;
  