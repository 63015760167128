  
  import React, { useEffect } from "react";
import { checkIfErrorExists, hotelNameValidation } from "../../../../utils/forms/validation";
  
  const MealPlan: React.FC<{
    setCurrStep:(val:string)=>void
    formData:any
  }> = (props) => {

    // useEffect(() => {
    //   setTimeout(() => {
    //     console.log(props.formData.meal_plan);
    //     onchangeMealPlan(props.formData.meal_plan)
    //   }, 500);
    // }, [props.formData])

    function nextStep() {
      if(checkIfErrorExists()){
        alert("Please correct details")
        return
      }
      if(!props.formData.room_category.length){
        alert("Please enter room category")
        return
      }
      props.setCurrStep("step5");
    }

    function onchangeMealPlan(value:string){
      props.formData.setMealPlan(value)
    }

    function onchangespecialRequirement(event:any){
      hotelNameValidation(event)
      props.formData.setSpecialRequirement(event.target.value)
    }

    function onchangeRoomCategory(event:any){
      hotelNameValidation(event)
      props.formData.setRoomCategory(event.target.value)
    }


    function submitHandle() {
      if(!props.formData.destination_city.length){
        alert("Please enter destination")
        return
      }
      nextStep()
    }
 
  
    return(

            <div className="full-width display-flex-row no-wrap space-between" >


            {/* <div className="ion-margin-top" style={{marginTop:"5px",width:"48%"}}>
                <div className="label full-width">Select Room</div>
                <div className="center-aligned-row">
                  <select  defaultValue={props.formData.room_category} onChange={(event)=>onchangeRoomCategory(event.target.value)} className="input search-input" name="" >
                      <option>Select Room</option>
                  </select>  
                </div>
            </div> */}

            <div className="full-width">
                <div className="label full-width">Meal Plan</div>
                <div className="center-aligned-row">
                  <select defaultValue={props.formData.meal_plan} onChange={(event)=>onchangeMealPlan(event.target.value)} className="input search-input" name="" >
                      <option>Breakfast</option>
                      <option>Breakfast and Dinner </option>
                      <option>All Meals</option>
                      <option>All Inclusive </option>
                  </select>  
                </div>
            </div>

            {/* <div className="ion-margin-bottom" style={{marginTop:"20px"}}>
                <div className="label full-width">Special requests</div>
                <div className="input-wrapper">
                  <div className="center-aligned-row">
                    <textarea  defaultValue={props.formData.special_request} onChange={(event)=>onchangespecialRequirement(event)} rows={5} cols={50} className="search-input input" />
                  </div>
                  <div className="display-flex-row marginTop5" style={{justifyContent:"center"}}>
                      <ul className="color-danger error font-small"></ul>
                  </div>
                </div>
            </div> */}

            </div>
     
      )
  };
  
  export default MealPlan;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
  