  
  import React from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
  
  const IssueFrom: React.FC<{
    formData:VisaForm
  }> = (props) => {
 
   

    function onChange(value:string){
      props.formData.setPassportIssuedFrom(value)
    }

  
    return(

            <div className="full-width display-flex-row no-wrap space-between marginTop10" >
           
            <div className="full-width">
                <div className="label full-width">Passport Issued From <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                  <input placeholder="Passport Issued From "  defaultValue={props.formData.passport_issued_from} onChange={(event)=>onChange(event.target.value)} className="input search-input" name="" >
                  </input>  
                </div>
            </div>

          

            </div>
     
      )
  };
  
  export default IssueFrom;

 