import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import { checkIfErrorExists, removeMandatoryClass } from "../../../../utils/forms/validation";
import "react-multi-date-picker/styles/layouts/mobile.css"
import $ from "jquery"
import { HotelForm } from "../../../../utils/forms/hotel";
import { showToast } from "../../../../utils/UI_methods/global";

const Dates: React.FC<{
  formData: HotelForm
  setCurrStep: (val: string) => void;
}> = (props) => {


  var startDate: any = null
  var endDate: any = null

  const [refreshDate, setRefreshDate] = useState(Number(new Date()))

  function onchangeCheckIn(value: string) {
    props.formData.setCheckIn(value)
  }


  function onchangeCheckOut(value: string) {
    props.formData.setCheckOut(value)
  }


  function onChangeDates(event: any) {
    removeMandatoryClass(event)
    // console.log("Ye chal raha ki nahi");
    if (event.length === 2) {
      // console.log("dates chage hui kya", event);s
      let startDateEvent = event[0]
      let endDateEvent = event[1]

      // console.log(startDateEvent);



      let startDate: any = `${startDateEvent.year}-${startDateEvent.month.number}-${startDateEvent.day}`
      let endDate: any = `${endDateEvent.year}-${endDateEvent.month.number}-${endDateEvent.day}`



      // if(startDate === endDate){
      //   setTimeout(() => {
      //     $(".rmdp-day").removeClass("rmdp-range")
      //     $(".rmdp-day").removeClass("start")
      //     $(".rmdp-input").val("")
      //   }, 200);
      // }

      let startDateTimeStamp = new Date(startDateEvent.year, startDateEvent.month.number, startDateEvent.day)
      let endDateTimeStamp = new Date(endDateEvent.year, endDateEvent.month.number, endDateEvent.day)
      if (endDateTimeStamp <= startDateTimeStamp) {
        showToast("Invalid config", 2000, "error");
        setRefreshDate(Number(new Date()))
        setTimeout(() => {
          $(".rmdp-day").removeClass("rmdp-range")
          $(".rmdp-day").removeClass("start")
          $(".rmdp-input").val("")
        }, 200);
        
        return;
      }

      onchangeCheckIn(startDate)
      onchangeCheckOut(endDate)
      // props.formData.setCheckIn(startDate)
      // props.formData.setCheckOut(endDate)
    }
    else {
      showToast("Please select check-out date", 2000, "error")
    }
  }

  function openDateAction() {
    startDate = null
    endDate = null
  }

  function onDateFocus(event: any) {
    if (!startDate) {
      // console.log("Startdate nhi hai kya isme");
      startDate = event
      return
    }
    // console.log("Startdate hai kya isme");
    endDate = event
    let startDateTimeStamp = new Date(startDate.year, startDate.month.number, startDate.day)
    let endDateTimeStamp = new Date(endDate.year, endDate.month.number, endDate.day)

    if (endDateTimeStamp <= startDateTimeStamp) {
      showToast("Invalid date selection", 2000, "error")
      setRefreshDate(Number(new Date()))
      setTimeout(() => {
        $(".rmdp-day").removeClass("rmdp-range")
        $(".rmdp-day").removeClass("start")
        $(".rmdp-input").val("")
      }, 200);
      openDateAction()
      props.formData.setCheckIn("")
      props.formData.setCheckOut("")
      startDate = null
      endDate = null
    }

  }

  return (
    <div className="full-width">

      {/* <div className="full-width  display-none no-wrap space-between padding10" style={{marginTop:"30px"}}>
            <div className="">
              <div className="label center-aligned-column" style={{ alignItems: "start" }}>
                Check in
              </div>
              <div className="">
                <input defaultValue={props.formData.check_in} onChange={(event)=>onchangeCheckIn(event.target.value)} type="date" placeholder="dd-mm-yyyy" className="query-input" />
              </div>
            </div>

            <div className="">
              <div className="label center-aligned-column" style={{ alignItems: "start" }}>
                Check out
              </div>
              <div className="">
                <input defaultValue={props.formData.check_out} onChange={(event)=>onchangeCheckOut(event.target.value)} type="date" placeholder="dd-mm-yyyy" className="query-input" />
              </div>
            </div>
 

           
          </div> */}

      <div className="full-width no-wrap space-between" key={refreshDate}>

        <div className="input-wrapper" style={{ width: "100%" }}>
          <div className="ion-margin-top">
            <div className="label full-width">
              Check In - Check Out
              <span className="mandatory color-danger font-medium">*</span>
            </div>
          </div>
          <div className="display-flex-row position-relative">
            <div className="top-left padding0 full-width">
              {
                <DatePicker
                  value={[props.formData.check_in, props.formData.check_out]}
                  range={true}
                  onChange={onChangeDates}
                  minDate={new Date()}
                  onOpen={openDateAction}
                  onFocusedDateChange={onDateFocus}
                  rangeHover={true}
                  containerClassName="range-date-container"
                  style={{ width: "600px" }}
                  className="rmdp-mobile mandatory-field"
                />
              }
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Dates;

