import React from "react";
import { cacheNames } from "workbox-core";


const Button: React.FC<{
  configObj: any;
  action: () => void
  label: string
  fontAwesomeIcon: string
  iconPos: string
  classNames: string
}> = (props) => {

  let string = props.fontAwesomeIcon;
  let firstLetterOfFontAwesom = string.split("")[0];
  // console.log(firstLetterOfFontAwesom);

  return (
    <React.Fragment>
      {props.label !== "" ?
        <div className={`position-relative zindex30 button2 white-card click-effect no-wrap display-flex-row ${props.classNames}`} onClick={() => props.action()}>
          {props.iconPos === "left" && firstLetterOfFontAwesom === "/" ?
            <img src={`${props.fontAwesomeIcon}`} alt="" className="icon center-aligned-column pointer-none" height={17}/>

            : props.iconPos === "left" && firstLetterOfFontAwesom !== "/" ?
              <i className={`${props.fontAwesomeIcon} icon center-aligned-column pointer-none`}></i>

              :
              null}
          <span className="text center-aligned-column pointer-none" style={{ margin: "0 0.4rem",}}>{props.label}</span>
          {props.iconPos === "right" && firstLetterOfFontAwesom === "/" ?
            <img src={`${props.fontAwesomeIcon}`} alt="" className="icon center-aligned-column pointer-none" height={17}/>

            : props.iconPos === "right" && firstLetterOfFontAwesom !== "/" ?
              <i className={`${props.fontAwesomeIcon} icon center-aligned-column pointer-none`}></i>

            : null}
        </div>
        :
        <div className={`position-relative zindex30 button2 white-card click-effect no-wrap display-flex-row ${props.classNames}`} onClick={() => props.action()} style={{ padding: "0.7rem" }}>
          {props.configObj.svg ?
            <props.configObj.SvgIcon />
            :
            props.iconPos === "left" ?
              <i className={`${props.fontAwesomeIcon} icon center-aligned-column pointer-none`}></i>
              : null}
          {props.iconPos === "right" ?
            // <img src="/assets/images/Vector.png" alt="" className="icon center-aligned-column" />
            <i className={`${props.fontAwesomeIcon} icon center-aligned-column pointer-none`}></i>
            : null
          }
        </div>
      }
    </React.Fragment>

  );
};

export default Button;
