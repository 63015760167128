
import React, { useEffect, useState } from "react";
import {
    IonCheckbox
} from "@ionic/react";

const Checkboxes: React.FC<{
    formData: any
}> = (props) => {

    const [visa, setVisa] = useState(false);
    const [hotel, setHotel] = useState(false);
    const [cruise, setCruise] = useState(false);
    const [sightseeingTransfers, setSightseeing] = useState(false);
    const [transfers, setTransfers] = useState(false);
    const [travelInsurance, setTravelInsurance] = useState(false);

    const [serviceCategoriesClicked, setServiceCategoriesClicked] = useState(0);

    useEffect(() => {
         if(serviceCategoriesClicked === 0){
            props.formData.setService_CategoriesClicked(String(serviceCategoriesClicked));
         }else{
            props.formData.setService_CategoriesClicked(String(serviceCategoriesClicked));
         }
    }, [serviceCategoriesClicked])

    function onchangeVisa(value: boolean) {
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setVisa(value);
            
        }else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setVisa(value);
        }
        props.formData.setVisa(value);
    }

    function onchangeHotel(value: boolean) {
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setHotel(value);
            
        }else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setHotel(value);
        }
        props.formData.setHotel(value)
    }

    function onchangeCruise(value: boolean){
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setCruise(value);
            
        }else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setCruise(value);
        }
        props.formData.setCruise(value);
    }

    function onchangeSightseeing(value: boolean) {
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setSightseeing(value);
            
        }else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setSightseeing(value);
        }
        props.formData.setSightseeing(value);
    }

    function onchangeTransfers(value: boolean){
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setTransfers(value);
        }
        else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setTransfers(value);
        }
        props.formData.setTransfers(value);
    }

    function onchangeTravelInsurance(value: boolean) {
        if(value === true){
            setServiceCategoriesClicked(serviceCategoriesClicked + 1);
            setTravelInsurance(value);
            
        }else{
            if(serviceCategoriesClicked === 0){
                setServiceCategoriesClicked(0);
            }
            else{
                setServiceCategoriesClicked(serviceCategoriesClicked - 1);
            }
            setTravelInsurance(value);
        }
        props.formData.setTravelInsurance(value);
    }
    
    // console.log("serviceCategoriesClicked", props.formData.service_CategoriesClicked);

    return (

        <div>
            <p className="label full-width">Services Required <span className="mandatory color-danger">*</span></p>
            <div className="services-container" style={{border: "", display: "flex", gap:"45px"}}>
                <div className="services-cont1">
                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeVisa(!visa)}></IonCheckbox>
                            <div className="input-label marginleft5">Visa (if applicable)</div>
                        </div>
                    </div>

                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeHotel(!hotel)}></IonCheckbox>
                            <div className="input-label marginleft5">Hotel</div>
                        </div>
                    </div>

                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeCruise(!cruise)}></IonCheckbox>
                            <div className="input-label marginleft5">Cruise</div>
                        </div>
                    </div>
                </div>

                <div className="services-cont2">
                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeSightseeing(!sightseeingTransfers)}></IonCheckbox>
                            <div className="input-label marginleft5">Sightseeing</div>
                        </div>
                    </div>

                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeTransfers(!transfers)}></IonCheckbox>
                            <div className="input-label marginleft5">Transfers</div>
                        </div>
                    </div>

                    <div className="form-label-input-container" style={{ padding: "10px 5px" }}>
                        <div className="display-flex-row no-wrap">
                            <IonCheckbox onIonChange={() => onchangeTravelInsurance(!travelInsurance)}></IonCheckbox>
                            <div className="input-label marginleft5">Travel Insurance</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
};

export default Checkboxes;

