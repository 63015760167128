import {
  IonPage
} from "@ionic/react";
import React, { useState } from "react";
import Toast from "../../common/ui-components/notification/Toast";
import backgroundImage from "../../style/background-main.png";
import Form1 from "./Form1";
import Form2 from "./Form2";

const Register: React.FC<{
}> = (props) => {


  const [currStep, setCurrStep] = useState("step1")


  return (
    <IonPage>
      <div className="ion-padding full-height-width center-aligned-column login-page-wrapper">
        {/* <div className="heading1 font-large color-black top-middle ion-padding-bottom ion-padding-top text-center">Register</div> */}   
        <div className="white-card login-container register-container overflow-y-scroll hide-scrollbar position-relative ion-margin-top ion-padding">
          {currStep === "step1" ?
            <Form1
              setCurrStep={setCurrStep}
            /> :
            currStep === "step2" ?
              <Form2
                setCurrStep={setCurrStep}
              />
              : null}

        </div>
      </div>
    </IonPage>
  );
};

export default Register;
