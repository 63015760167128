import React, { useEffect, useState } from "react";
import { specialRequestValidation } from "../../../../utils/forms/validation";

const PlacesCovered: React.FC<{
  formData: any
}> = (props) => {

  function onChangePlacedCovered(event:any){
    specialRequestValidation(event);
    props.formData.setPlacesCovered(event.target.value);
  }

  return (
    <div className="ion-margin-bottom" style={{ marginTop: "20px" }}>
      <div className="label full-width">Places to be Covered <span className="mandatory color-danger">*</span></div>
      <div className="input-wrapper">
        <div className="center-aligned-row">
          <textarea defaultValue={props.formData.places_to_be_covered} onChange={(event) => onChangePlacedCovered(event)} rows={2} cols={50} className="search-input input" style={{ height: "unset" }} placeholder='Mention places to be covered' />
        </div>
        <div className="display-flex-row marginTop5" style={{ justifyContent: "center" }}>
          <ul className="color-danger error font-small"></ul>
        </div>
      </div>
    </div>
  )
}


export default PlacesCovered;