import React from "react";
import Button2 from "../../../common/ui-components/buttons/Button2";

const QueryListIs100: React.FC<{
  closeQuery: (val: boolean) => void
}> = (props) => {

  function backToQueriesAll(){
       props.closeQuery(false)
  }
  return (
      <div className="queryList100-container" style={{}}>
          <p>Only 100 most recent queries are displayed. To view older queries, use the Archive Section</p>
          <button onClick={backToQueriesAll} className="queryList100-btn center-aligned-column bg-color-primary" style={{}}>OK</button>
      </div>
  );
};

export default QueryListIs100;
