import { Logging } from "./logging/logs";

export class applicationConfig {
    configuration: any;
    data: any = {}
    functions: any = {}
    token:string | undefined
    user:any
    states: any = {}

    logger:Logging = new Logging()
  
    constructor() {
     
    }
  
    setUser(user:any){
      this.user = user
    }
  
    setConfiguration(key: string, configuration: any) {
      this.configuration[key] = configuration;
    }
  
   
  
    setData(data: any) {
      this.data = data;
    }
  
    updateData(key:string,data:Array<any>){
      this.data[key] = data
    }
  
    getData() {
      return this.data;
    }
   
    setState(moduleName:string,stateInfo:any){
      this.states[moduleName] = {
        ...this.states[moduleName],
        ...stateInfo
      }
    }
 
  
    setFunctions(functions: any) {
      this.functions = functions;
    }
  
  
    setFunctionRef(moduleName:string,funInfo:any){
      this.functions[moduleName] = {
        ...this.functions[moduleName],
        ...funInfo
      }
    }
   
  
    setCurrentConfiguration(configuration: any) {
      this.configuration = configuration;
    }
  
    getCurrentConfiguration() {
      return this.configuration;
    }
  
    getCurrentState() {
      return {
        // 'areaList':this.areaList,
        // 'areaViewList':this.areaViewList,
        // 'areaViewProductList':this.areaViewProductList,
        configuration: this.configuration,
      };
    } 
  }
  
  
  