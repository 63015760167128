  
  import React from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";
  
  const Destination: React.FC<{
    formData:VisaForm
  }> = (props) => {
 
   

    function onChangeDestination(value:string){
      props.formData.setVisaDestination(value)
    }

  
    return(

            <div className="full-width display-flex-row no-wrap space-between" >


            {/* <div className="ion-margin-top" style={{marginTop:"5px",width:"48%"}}>
                <div className="label full-width">Select Room</div>
                <div className="center-aligned-row">
                  <select  defaultValue={props.formData.room_category} onChange={(event)=>onchangeRoomCategory(event.target.value)} className="input search-input" name="" >
                      <option>Select Room</option>
                  </select>  
                </div>
            </div> */}

            <div className="full-width">
                <div className="label full-width">Destination <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                  <select value={props.formData.visa_destination}  defaultValue={props.formData.visa_destination} onChange={(event)=>onChangeDestination(event.target.value)} className="input search-input" name="" >
                      <option>Select Destination</option>
                      <option>Delhi</option>
                      <option>Mumbai</option>
                      <option>Bangalore</option>
                      <option>Pune</option>
                      <option>Singapore</option>
                      <option>Dubai</option>
                   </select>  
                </div>
            </div>

          

            </div>
     
      )
  };
  
  export default Destination;

 