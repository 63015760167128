import React, { useState } from "react";
import { withDrawQuery } from "../../../../services/api";

const CancleQueryReason: React.FC<{
    closeAction: () => void
    query: any
}> = (props) => {

    const [selectReason, setSelectReason] = useState("");

    function CancleQuery(){
       let data = {
        QueryId: props.query.potential_no,
        ReasonForCancellation: selectReason
       }
       
       withDrawQuery(data).then((response) => {
            console.log(response);
       }).catch((err) => {
          console.log(err);
       })
       
    }

    return (
        <div className="CancleQueryReason-container" style={{}}>
            <p className="heading2">Reason for Withdraw</p>
            <div className="">
            <select className="input search-input" name="" value={selectReason} onChange={(event) => setSelectReason(event.target.value)}>
                <option>Select Reason</option>
                <option>No resonse from client</option>
                <option>Availability</option>
                <option>Delay in Quote</option>
                <option>Plan Postponed</option>
                <option>Plan Cancelled</option>
                <option>Pricing Issue</option>
                <option>Other</option>
            </select>
            </div>
            <button onClick={CancleQuery} className="CancleQueryReason-btn center-aligned-column bg-color-primary" style={{}}>Submit</button>
        </div>

    )
}

export default CancleQueryReason