
import React, { useState } from "react";

const UploadItinerary: React.FC<{
    formData: any
}> = (props) => {

    const [file, setFile] = useState(" ");
 
    function uploadFile(event: any){
           console.log(event.target.files[0]);
           let encodedStringBtoA = btoa(event.target.files[0]);
           console.log("encodedStringBtoA", encodedStringBtoA);
    }

    function handleSubmit(event: any){
        event.preventDefault()
        props.formData.setUploadItinerary(file)
    }

    return (

        <div>
            <div className="full-width display-flex-row no-wrap space-between marginTop10" >
                <div className="full-width">
                    <div className="label full-width">Upload Itinerary (.doc or .pdf only)</div>
                    <form onSubmit={handleSubmit} style={{ display: "flex", gap: "10px" }}>
                        <input className="input search-input" type="file" onChange={(event) => uploadFile(event)}/>
                        <button type="submit" style={{ padding: "10px", background: "blue", color: "whitesmoke", borderRadius: "5px" }}>Upload</button>
                    </form>
                </div>
            </div>
        </div>

    )
};

export default UploadItinerary;

