import React, { useState } from "react";
import Button2 from "../common/ui-components/buttons/Button2";
import { IonModal } from "@ionic/react";
import OtpverifySetPass from "./OtpverifySetPass";
import { useHistory } from "react-router-dom";
import GetAccountDetails from "./GetAccountDetails";

const ForgetPassword: React.FC<{
    closeForgetPass: () => void
}> = (props) => {

    const history = useHistory();

    const [otpPass, setOtpPass] = useState<string>(" ");
    const [isOpenOtpPass, setisOpenOtpPass] = useState<boolean>(false)
    const [isOpengetDetailsForm, setisOpengetDetailsForm] = useState<boolean>(false)

    const [agencyCode, setAgencyCode] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");

    // const [isOpenQueryDetails, setisOpenQueryDetails] = useState<boolean>(false)
    // const [currStep, setCurrStep] = useState("")
    function goToOtpVerifyPage() {
        setOtpPass("enterOtp");
        setisOpenOtpPass(true);
    }

    function gotoRegisterPage() {
        props.closeForgetPass()
        history.push("otpverify");
    }

    function gotoLoginPage() {
        props.closeForgetPass();
        history.push("login")
    }

    function goToForgetUserNameCompanyCode() {
        setisOpengetDetailsForm(true);
    }


    return (

        <div className="ion-padding full-height-width center-aligned-column login-page-wrapper" style={{ border: "" }}>

            <IonModal
                id="example-modal"
                onDidDismiss={() => setisOpenOtpPass(false)}
                isOpen={isOpenOtpPass}
                // id="inventoryModal"
                cssClass='medium-y-modal Forget-passoword-popup'
            >
                {otpPass == "enterOtp" ?
                    <OtpverifySetPass closeOtpPass={() => setisOpenOtpPass(false)} closeForgetPass={props.closeForgetPass} />
                    : null}

            </IonModal>

            <IonModal
                id="example-modal"
                onDidDismiss={() => setisOpengetDetailsForm(false)}
                isOpen={isOpengetDetailsForm}
                // id="inventoryModal"
                cssClass='medium-y-modal Forget-passoword-popup'
            >
                    <GetAccountDetails closeOpengetDetailsForm={() => setisOpengetDetailsForm(false)} closeForgetPass={props.closeForgetPass}/>

            </IonModal>

            <div className="white-card position-relative full-height-width register-container ion-padding" style={{ border: "", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>

                <i onClick={gotoLoginPage} style={{ position: "absolute", top: "10px", right: "10px", fontSize: "18px", color: "gray" }} className="fas fa-times"></i>

                <div className="full-width center-aligned-column">
                    <img width={250} src="assets/images/travel_power_logo.png" alt="" />
                </div>

                <p className="text-center heading1 font-medium capitalize" style={{fontWeight: "600"}}>Reset Password</p>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Company Code</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={agencyCode} className="input search-input" name="" >
                        </input>
                    </div>
                </div>

                <div className="form-label-input-container" style={{ border: "" }}>
                    <div className="label heading3 full-width">Username</div>
                    <div className="center-aligned-row marginTop10" style={{display: "flex", flexDirection: "column"}}>
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={username} className="input search-input" name="" >
                        </input>
                        {/* <div className="display-flex-row flex-end marginTop10">
                            <strong>
                                <span onClick={() => { }} className="heading color-black underline pointer" style={{}}>Forgot password?</span>
                            </strong>
                        </div> */}
                    </div>
                    <div className="display-flex-row flex-end marginTop5">
                        <strong>
                            <span onClick={goToForgetUserNameCompanyCode} className="heading color-black underline pointer" style={{}}>Don't remember?</span>
                        </strong>
                    </div>
                </div>

                <div className="form-label-input-container" style={{border: "", marginTop: "-20px"}} >
                    <div className="label heading3 full-width">Email</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={email} className="input search-input" name="" >
                        </input>
                    </div>
                </div>

                <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
                    <Button2
                        configObj={{}}
                        action={goToOtpVerifyPage}
                        label={'Reset password'}
                        fontAwesomeIcon={""}
                        iconPos="right"
                        classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
                    />

                </div>

                <div className="go-to-registerpage ion-text-center ion-padding heading3">
                    Not a member yet?
                    <span onClick={gotoRegisterPage} className="heading bold underline pointer" style={{ color: "blueviolet", marginLeft: "3px" }}>Register Now!</span>
                    {/* <a className="pointer" onClick={gotoRegisterPage} >Register Now!</a> */}
                </div>
            </div>
        </div>

        // <div className="full-height-width" >
        //        <h1>Forget Password Page</h1>
        // </div>
        // <div className="ion-padding full-height-width center-aligned-column login-page-wrapper">
        //     <div className="white-card login-container register-container overflow-y-scroll hide-scrollbar position-relative ion-margin-top ion-padding">
        //         <h1>Forget Password Page</h1>
        //     </div>
        // </div>
    );
};

export default ForgetPassword;