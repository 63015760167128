import React from "react";
import { IonIcon, IonPage } from "@ionic/react";
import { checkmarkCircleOutline, logoFacebook, logoInstagram, logoWebComponent } from "ionicons/icons";
import { useHistory } from "react-router";
import { applicationConfig, removeUserSession } from "../utils/methods";
import Domestic from "./common/ui-components/slider/Domestic";
import Interntional from "./common/ui-components/slider/International";
const LogoutPage: React.FC<{
}> = (props) => {


  let history = useHistory()

  function gotoLoginPage() {
    removeUserSession();
    applicationConfig.logger.info("User logged out");
    setTimeout(() => {
      history.push("/login")
    }, 200)
   
  }

  function HeaderLeftComponent() {
    return(
      <div className="brand">
        <div className="heading font-medium">HomePage</div>
      </div>
    )
  }

  return(
    <IonPage>
      <div className="page full-height-width ion-padding">

        <div className="logo-container full-width ion-padding">
          <div className="center-aligned-column">
            <img src="/assets/icon/rsk.png" alt="" />
          </div>
          <div className="center-aligned-column" >
            <div className="width70 bg-color-black ion-margin-top" style={{padding:"2px"}}></div>
          </div>
        </div>


        <div className="full-width display-flex-row sliders full-width no-wrap">
          
          <div className="slider1 ion-padding-start" style={{width:"30.33%"}}>
            {/* <Interntional/> */}
          </div>
         
          <div className="flex1 text center-aligned-column">
            <div className="center-aligned-column">
              <IonIcon className="color-success center-aligned-row" icon={checkmarkCircleOutline} style={{fontSize:"10rem"}}></IonIcon>
              <div>
                <div className="ion-margin-top text-center heading2 font-large">You have beed logged out <br/> Thank you</div>
              </div>

              <div className="ion-margin-top">
                <div className="flex-end ion-padding-top">
                <button className="button-gradient"
                id="login"
                onClick={gotoLoginPage}>
                Sign in again
              </button>
                </div>
              </div>
            </div>
          </div>
         
          <div className="slider2 ion-padding-end" style={{width:"30.33%"}}>
            {/* <Domestic/> */}
          </div>
        </div>

        <div className="full-width hide-in-mobile display-flex-row sliders full-width no-wrap">
          
          <div className="slider1 ion-padding-start" style={{width:"30.33%"}}>
            <div className="center-aligned-row ion-padding-top">
              <button className="button-gradient2"
              id="login"
              onClick={()=>{}}>
              Avail this offer!
              </button>
            </div>
            <div className="center-aligned-row heading1 marginTop5">International</div>
            </div>
         
          <div className="flex1">

            <div className="center-aligned-row heading1 ion-margin-top uppercase">RSK Travel Consultants PVT. LTD</div>
              <div className="center-aligned-row no-wrap ion-margin-top">
                <IonIcon className="font-medium center-aligned-column" icon={logoWebComponent}/>
                <a target="blank" href="https://www.rsktravels.com/@info.rsktravels.com" className="marginleft5 pointer center-aligned-column heading2">https://www.rsktravels.com/@info.rsktravels.com</a>
              </div>

              <div className="center-aligned-row no-wrap ion-margin-top">
                <IonIcon className="font-medium center-aligned-column" icon={logoFacebook}/>
                <a target="blank" href="https://www.facebook.com/rsktravelconsultants" className="marginleft5 pointer center-aligned-column heading2">https://www.facebook.com/rsktravelconsultants</a>
              </div>

              <div className="center-aligned-row no-wrap ion-margin-top">
                <IonIcon className="font-medium center-aligned-column" icon={logoInstagram}/>
                <a target="blank" href="https://www.instagram.com/rsktravelconsultants" className="marginleft5 pointer center-aligned-column heading2">https://www.instagram.com/rsktravelconsultants</a>
              </div>
             
          </div>
         
          <div className="slider2 ion-padding-end" style={{width:"30.33%"}}>
            <div className="center-aligned-row ion-padding-top">
              <button className="button-gradient2"
              id="login"
              onClick={()=>{}}>
              Avail this offer!
              </button>
            </div>
            <div className="center-aligned-row heading1 marginTop5">Domestic</div>

          </div>
        </div>


      </div>
  </IonPage>  
  )
   
};

export default LogoutPage;
