import React, { useState } from "react";
import Button2 from "../common/ui-components/buttons/Button2";
import { useHistory } from "react-router-dom";


const OtpverifySetPass: React.FC<{
    closeForgetPass: () => void
    closeOtpPass: () => void
}> = (props) => {

    const history = useHistory();

    const [enterOTP, setEnterOTP] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");

    function gotoRegisterPage() {
        props.closeOtpPass();
        props.closeForgetPass();
        history.push("register")
    }

    function gotoLoginPage() {
        props.closeOtpPass();
        props.closeForgetPass();
        history.push("login")
    }

    // const [isOpenQueryDetails, setisOpenQueryDetails] = useState<boolean>(false)
    // const [currStep, setCurrStep] = useState("")


    return (

        <div className="ion-padding full-height-width center-aligned-column login-page-wrapper" style={{ border: "" }}>
            <div className="white-card full-height-width register-container position-relative ion-padding" style={{ border: "", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>

                <i onClick={gotoLoginPage} style={{ position: "absolute", top: "10px", right: "10px", fontSize: "18px", color: "gray" }} className="fas fa-times"></i>

                <div className="full-width center-aligned-column">
                    <img width={250} src="assets/images/travel_power_logo.png" alt="" />
                </div>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Enter OTP</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={enterOTP} className="input search-input" name="" >
                        </input>
                    </div>
                    <div className="display-flex-row flex-end">
                        <strong>
                            <span onClick={() => {}} className="heading underline pointer" style={{fontSize: "", color: "blueviolet"}}>Verify OTP</span>
                        </strong>
                    </div>
                </div>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Set New Password</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={newPassword} className="input search-input" name="" >
                        </input>
                    </div>
                </div>

                <div className="form-label-input-container">
                    <div className="label heading3 full-width">Retype New Password</div>
                    <div className="center-aligned-row marginTop10">
                        <input style={{ border: "none", backgroundColor: "#FBFBFB" }} id="agencyCode" type="text" defaultValue={retypePassword} className="input search-input" name="" >
                        </input>
                    </div>
                </div>

                <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
                    <Button2
                        configObj={{}}
                        action={() => { }}
                        label={'Send'}
                        fontAwesomeIcon={""}
                        iconPos="right"
                        classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
                    />

                </div>

                <div className="go-to-registerpage ion-text-center ion-padding heading3">
                    Not a member yet?
                    <span onClick={gotoRegisterPage} className="heading bold underline pointer" style={{ color: "blueviolet", marginLeft: "3px" }}>Register Now!</span>
                    {/* <a className="pointer" onClick={gotoRegisterPage} >Register Now!</a> */}
                </div>
            </div>
        </div>

        // <div className="full-height-width" >
        //        <h1>Forget Password Page</h1>
        // </div>
        // <div className="ion-padding full-height-width center-aligned-column login-page-wrapper">
        //     <div className="white-card login-container register-container overflow-y-scroll hide-scrollbar position-relative ion-margin-top ion-padding">
        //         <h1>Forget Password Page</h1>
        //     </div>
        // </div>
    );
};

export default OtpverifySetPass;