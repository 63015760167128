import React, { useEffect, useState } from "react";
import { enableHorizontalSlider } from "../../../../utils/UI_methods/ui_effects";
import $ from "jquery"

const HorizontalScroll: React.FC<{
  itemsClass: string;
  Component: any;
  id:string
  itemsList: Array<any>;
}> = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollLength, setScrollLength] = useState(100);

  // if scroll left is greater then 0 show left
  useEffect(() => {
    setTimeout(() => {
      if (props.itemsList.length) {
        enableHorizontalSlider(props.id);
      }
    }, 500);
  }, [props.itemsList]);

  function scrollLeft(event:any) {
    let ele = $(event.target).siblings(".horizontal-scroll-wrapper")
    let pos:any = ele.scrollLeft();

    let scrollUpto = pos - scrollLength;
    if (scrollUpto < 0) {
      scrollUpto = 0;
    }
    ele.animate(
      {
        scrollLeft: scrollUpto,
      },
      400
    );
    setScrollPosition(scrollUpto);
  }

  function scrollRight(event:any) {
    // ele.scrollTo(scroll,0)
    let ele = $(event.target).siblings(".horizontal-scroll-wrapper")
    let pos:any = ele.scrollLeft();

    let scrollUpto = pos + scrollLength;

    ele.animate(
      {
        scrollLeft: scrollUpto,
      },
      600
    );

    setScrollPosition(scrollUpto);

    // if(getContainerTotalWidth() - Math.ceil(getScrolledWidth(scrollUpto))  < 10){
    //     $("#"+props.wrapperId).find("#rightScrollArrow").find(".icon").removeClass("--is-active")
    //     return
    // }
  }

  return (
    <div className="display-flex-row no-wrap" style={{padding:"0px 5px"}}>
      <span
        className="prev-arrow center-aligned-column pointer"
        onClick={scrollLeft}>
        <i className="fas fa-chevron-left pointer-none color-black"></i>
      </span>
      <div
        className={`${props.itemsClass} horizontal-scroll-wrapper display-flex-row no-wrap hide-scrollbar`}
        id={props.id}>
        <props.Component />
      </div>
      <span
        className="next-arrow center-aligned-column pointer"
        onClick={scrollRight}>
        <i className="fas fa-chevron-right pointer-none color-black"></i>
      </span>
    </div>
  );
};

export default HorizontalScroll;
