import $ from "jquery";
import React, { useEffect, useState } from "react";


const Category: React.FC<{
    formData: any
    setCurrStep: (val: string) => void;
}> = (props) => {

    const [values, setValues] = useState(null)

    useEffect(() => {
        setTimeout(() => {
          updateClass(props.formData.category)
        }, 500);
      }, [props.formData])
    
      function onchangePackageCategory(value: number) {
        props.formData.setPackageCategory(String(value))
      }
    
    
      function updateClass(index: number) {
        // console.log("Update function called with hotel category", index);
    
        $(".hotel-star").removeClass("color-warning")
        $(".hotel-star").removeClass("fas")
    
        $(".hotel-star").addClass("color-dark")
        $(".hotel-star").addClass("far")
    
        onchangePackageCategory(index)
        let stars = document.querySelectorAll(".hotel-star")
        // console.log(stars);
    
        for (let i = 0; i < index; i++) {
          const element = stars[i];
    
          if (i <= index) {
            // console.log("Setting value for", i, " as enabled");
            $(element).addClass("color-warning")
            $(element).addClass("fas")
    
            $(element).removeClass("color-dark")
            $(element).removeClass("far")
          }
    
        }
    
      }

    return (
        <div className="full-width" style={{ paddingLeft: "0px", marginTop:"7px", marginBottom:"8px" }}>
            <div className="input-wrapper full-width">
                <div style={{border: "", marginLeft:"0px"}}>
                    <div className="label full-width ">
                        Category
                        <span className="mandatory color-danger">*</span>
                    </div>
                    <div className="center-aligned marginTop10">
                        <div className="display-flex-row no-wrap">
                            <i className="far fa-star font-large color-dark hotel-star" onClick={() => updateClass(1)}></i>
                            <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(2)}></i>
                            <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(3)}></i>
                            <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(4)}></i>
                            <i className="far fa-star font-large color-dark marginleft5 hotel-star" onClick={() => updateClass(5)}></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;

