import React, { useEffect, useState } from 'react';
import { hotelNameValidation } from '../../../../utils/forms/validation';

const PickupFrom: React.FC<{
    formData: any
}> = (props) => {

    function onchangesPickupFrom(event: any){
        hotelNameValidation(event)
        props.formData.setPickupFromArrival(event.target.value)
    }

    return (
        <div className="full-width display-flex-row no-wrap space-between marginTop10" >
            <div className="full-width">
                <div className="label full-width">Arrival at (Pickup from) <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <input defaultValue={props.formData.pickup_from} onChange={(event) => onchangesPickupFrom(event)} className="input search-input" name="" type="text" placeholder="Pickup from (Name of Airport / Railway Station)" />
                </div>
            </div>
        </div>
    )
};

export default PickupFrom;




