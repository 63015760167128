
import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getQueryList } from "../../../services/api";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getStatusClass, isTouchDevice } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import HorizontalScroll from "../../common/ui-components/scrollbar/HorizontalScroll";
import { ReactComponent as SvgBus } from '../../svg/Bus-Ticket.svg';
import { ReactComponent as SvgHotel } from '../../svg/Hotel-booking.svg';
import { ReactComponent as SvgQueryID } from '../../svg/QueryID.svg';
import { ReactComponent as SvgDate } from '../../svg/Travel Date Range.svg';
import { ReactComponent as SvgVisa } from '../../svg/Visa-Service.svg';
import { ReactComponent as SvgBell } from '../../svg/bell.svg';
import { ReactComponent as SvgLocation } from '../../svg/location.svg';
import { ReactComponent as SvgTime } from '../../svg/time-1.svg';
import { ReactComponent as SvgVisaGray } from '../../svg/visa-gray.svg';
import Details from "./query-details/Details";
import QueryDetailsContainer from "./query-details/QueryDetailsContainer";
import Loader from "../../common/ui-components/Loader";
import { hideComponentLoader } from "../../../utils/UI_methods/global";
import QueryListIs100 from "./query-details/QueryListIs100";
import Modal from "../../common/ui-components/modals/Modal2";

const AllQueryList: React.FC<{
  filteredQueries: any
  setFilteredQueries: (val: string[]) => void
  setIsShowDetails: (val: boolean) => void
}> = (props) => {

  const [queriesList, setQueriesList]: Array<any> = useState([])
  const [limit, setLimit] = useState(3)
  const [servicesList, setServicesList] = useState([])
  const [currSelectedService, setCurrSelectedService]: any = useState("")
  const [isOpenQueryDetails, setisOpenQueryDetails] = useState<boolean>(false)
  const [currQuery, setCurrQuery] = useState<any>(null)
  const [isOpenQueryList100, setisOpenQueryList100] = useState<boolean>(false)
  // const [isOpenCancle, setIsOpenCancle] = useState<boolean>(false)

  // console.log("iski value kya hai", isOpenQueryDetails);


  useEffect(() => {
    getQueryList().then((data: any) => {
      if (data.data.opportunities_list) {
        let list: Array<any> = JSON.parse(data.data.opportunities_list)
        // list.reverse();
        // console.log("list", list);
        setQueriesList(list);
        applicationConfig.data.queriesList = list;
        applicationConfig.logger.info(`Showing open queries`)
      }
      hideComponentLoader("queryListLoader")
    }).catch(err => {
      console.log(err)
      hideComponentLoader("queryListLoader")
    })

    let allServices: any = getFilteredArray(applicationConfig?.data?.servicesList, "discontinued", 1);
    // console.log("allServices", allServices);
    allServices = getArrayOfDistinctValues(allServices, "servicename") || []
    // console.log("allservices---------", allServices);

    if (allServices?.length) {
      // console.log("Ye vala chala nahi shayad");
      setServicesList(allServices)
      setCurrSelectedService("All")
    }
    else {
      setServicesList([]);
    }

    applicationConfig.setFunctionRef("queries", { setQueriesList: setQueriesList })
  }, [])

  useEffect(() => {
    if (queriesList.length >= 100) {
      setisOpenQueryList100(true);
    }
  }, [queriesList])

  useEffect(() => {
    if (currSelectedService.length && applicationConfig.data.queriesList) {
      const allQueries = applicationConfig.data.queriesList
      if (currSelectedService === "All") {
        // console.log("By defaut all chal raha hai..........");
        setQueriesList(allQueries)
      } else {
        // console.log("........................");
        setQueriesList(allQueries.filter((currQuery: any) => currQuery.cf_983.toLowerCase().includes(currSelectedService.toLowerCase())))
      }
    }
  }, [currSelectedService])


  function gotoQueryDetailsPage(id: string) {
    console.log("id", id);
    window.location.href = "/queries/" + id
  }


  const Date: React.FC<{
    query: any
    isStartDate: boolean
  }> = (props) => {
    return (
      <>
        {props.query.cf_983.toLowerCase() === "accommodation" ?
          <div style={{ fontSize: "" }} className="fitsize ft-size-acc heading3 color-black">{props.isStartDate ? props.query.cf_935 : props.query.cf_937}</div>
          :
          props.query.cf_983.toLowerCase() === "visa" ?
            <div className="fitsize heading3 color-black">{props.query.cf_1015}</div>
            :
            null
        }
      </>
    );
  }

  const DestinatonAndCategory: React.FC<{
    query: any
  }> = (props) => {
    // console.log(props.query);
    // ({props.query.cf_919} {props.query.cf_919.length && props.query.cf_921.length ? "|" : ""} {props.query.cf_921})
    return (
      <>
        {props.query.cf_983.toLowerCase() === "accommodation" ?
          <div style={{ fontSize: "" }} className="fitsize heading3 heading-4 center-aligned-column semibold  capitalize detination-details">{props.query.cf_917} {props.query.cf_919.length ? `(${props.query.cf_919})` : " "}</div>
          :
          props.query.cf_983.toLowerCase() === "visa" ?
            <div className="fitsize heading3 center-aligned-column semibold  capitalize detination-details">
              {props.query.cf_1019}
              {/* {props.query.cf_1019} ({props.query.cf_1003} {props.query.cf_919.length && props.query.cf_921.length ? "|" : ""} {props.query.cf_921}) */}
            </div>
            :
            null
        }
      </>
    );
  }

  const Requirement: React.FC<{
    query: any
  }> = (props) => {
    return (
      <>
        {props.query.cf_983.toLowerCase() === "accommodation" ?
          <>
            {/* <div className="center-aligned-column">
        <SvgTime width={10} height={10}  style={{ fill: 'blue' }} />
      </div> */}
            <div style={{ fontSize: "" }} className="fitsize ft-size-acc heading3 no-wrap-text color-black">{props.query.cf_925} {props.query.cf_925 > 1 ? "Rooms" : "Room"}</div>
          </>

          :
          props.query.cf_983.toLowerCase() === "visa" ?
            <div className="fitsize heading3 no-wrap-text color-black">{props.query.cf_1003}</div>
            :
            null
        }
      </>
    );
  }


  const NoOfPeople: React.FC<{
    query: any
  }> = (props) => {
    return (
      <>
        {props.query.cf_983.toLowerCase() === "accommodation" ?
          <div style={{ fontSize: "" }} className="fitsize ft-size-acc heading3 color-black">{props.query.cf_927}{props.query.cf_927 > 1 ? "Adults" : "Adult"}, {Number(props.query.cf_929) + Number(props.query.cf_931)}Childrens</div>
          :
          props.query.cf_983.toLowerCase() === "visa" ?
            <div className="fitsize heading3 color-black">{props.query.cf_1011} {props.query.cf_1011 > 1 ? "Adults" : "Adult"}, {props.query.cf_1013} Childrens </div>
            :
            null
        }
      </>
    );
  }

  const Category: React.FC<{
    query: any
  }> = (props) => {
    return (
      <>
        {props.query.cf_983.toLowerCase() === "accommodation" ?
          <div className="fitsize ft-size-acc heading3 no-wrap-text color-black">

            {
              showStars(props.query.cf_921).map((curr, index) => {
                return curr
              })
            }

          </div>
          :
          props.query.cf_983.toLowerCase() === "visa" ?
            <div className="fitsize heading3 color-black">{props.query.cf_1009}</div>
            :
            null
        }
      </>
    );
  }



  function ServicesList() {
    return (
      <>
        <div className={`marginleft10 item marginTop5 ${currSelectedService === "All" ? " --is-active" : ""}`} onClick={() => setCurrSelectedService("All")}>
          <div className='heading3 font-normal text-center text'>All</div>
        </div>
        {servicesList.map((service: any, index: number) => {
          return (
            <div className={`marginleft10 item marginTop5 ${service === currSelectedService ? " --is-active" : ""}`} key={index} onClick={() => setCurrSelectedService(service)}>
              <div className='heading3 font-normal text-center text'>{service}</div>
            </div>
          )
        })}
      </>
    )
  }

  function viewQueryAction(query: any) {
    if (isTouchDevice()) {
      gotoQueryDetailsPage(query.id)
    } else {
      setCurrQuery(query)
      setisOpenQueryDetails(true)
    }
  }

  function Options(props: any) {
    return (
      <div className="display-flex-row no-wrap">
        <Button2
          configObj={{ id: "" }}
          action={() => { }}
          label={""}
          fontAwesomeIcon={"fab fa-whatsapp color-black"}
          iconPos="left"
          classNames={"shadow-light bg-color-light"}
        />
        <Button2
          configObj={{ id: "" }}
          action={() => { }}
          label={""}
          fontAwesomeIcon={"far fa-envelope color-black"}
          iconPos="left"
          classNames={"shadow-light bg-color-light"}
        />
        <Button2
          configObj={{ id: "" }}
          action={() => { }}
          label={""}
          fontAwesomeIcon={"fas fa-ellipsis-v color-dark"}
          iconPos="left"
          classNames={"shadow-light  bg-color-light"}
        />
      </div>
    )
  }

  function showStars(value: any) {
    let stars = [];
    for (let i = 0; i < value; i++) {
      stars.push(<i className="fas fa-star" style={{ color: "#1c71d8", margin: "1px" }}></i>)
    }
    return stars;
  }

  function removeClickedFilter(value: string) {
    let queryFilters = props.filteredQueries;
    let updatedQueryFilters = queryFilters.filter((currFilt: string) => currFilt !== value);
    props.setFilteredQueries(updatedQueryFilters);
  }

  return (
    <>
      <div className="full-width service-list-filter marginTop10">
        <HorizontalScroll
          itemsClass="sub-categories-container"
          Component={ServicesList}
          itemsList={servicesList}
          id={`ProductSbuCategoriesScrollBar`}
        />
      </div>

      <Loader
        data={{}}
        className="--is-active fade-background-loader"
        id="queryListLoader"
        loaderType="spinner"
      />

      <IonModal
        onDidDismiss={() => setisOpenQueryDetails(false)}
        isOpen={isOpenQueryDetails}
        id="inventoryModal"
        cssClass='medium-y-modal'
      >
        {currQuery ?
          <QueryDetailsContainer query={currQuery} closeQuery={() => setisOpenQueryDetails(false)} />
          : null}

      </IonModal>

      <div className="leads-wrapper position-relative full-height-width display-flex-row marginTop5" style={{ border: "" }}>

        <IonModal
          onDidDismiss={() => setisOpenQueryList100(false)}
          isOpen={isOpenQueryList100}
          id="inventoryModal"
          cssClass='vertical-modal my-modal'
        >
          <QueryListIs100 closeQuery={() => setisOpenQueryList100(false)} />
        </IonModal>

        {/* <p>{props.filteredQueries}</p> */}

        {/* <div className="display-flex-row">
          {
            props.filteredQueries.map((currFilter: any, index: number) => {
              return (
                <div key={index} className="item display-flex-row" style={{ border: "1px solid #b5e9f5", padding: "2px 5px", borderRadius: "5px", backgroundColor: "#b5e9f5", flexWrap: "wrap", alignItems: "center", gap: "8px", marginLeft: "10px", height: "30px", marginTop: "5px" }}>
                  <p className='heading3 font-normal text-center text' style={{ color: "blue" }}>{currFilter}</p>
                  <i className="fas fa-times" style={{ color: "blueviolet" }} onClick={() => removeClickedFilter(currFilter)}></i>
                </div>
              )
            })
          }
        </div> */}

        {queriesList.map((query: any, index: number) => {
          let statusClassNames = ["status custom-badge center-aligned-column"];
          statusClassNames.push(query.statusbg);
          return (
            <React.Fragment key={index}>
              {index < limit ?
                <div onClick={() => viewQueryAction(query)} className={`white-card shadow-medium full-width pointer position-relative query-container  ${index !== -1 ? "marginTop15" : ""} `} key={index}>

                  {query.isNewMessage ?
                    <div className="top-right">
                      <SvgBell width={10} height={10} style={{ fill: 'blue' }} />
                    </div>
                    : null}

                  <div className={`top-right options-container hide-in-mobile`}>
                    <Options query={query} />
                  </div>


                  <div className="display-flex-row full-height pointer-none top-content no-wrap padding5 overflow-x-scroll hide-scrollbar" >
                    <div className="icon bg-color-ternary center-aligned-column height-inherit cent" style={{ padding: "0 1rem" }}>
                      {query.cf_983 === "Accommodation" ?
                        <SvgHotel className="query-img" width={36} height={60} style={{ fill: 'blue' }} />
                        :
                        query.cf_983 === "bus" ?
                          <SvgBus width={36} height={36} style={{ fill: 'blue' }} />
                          :
                          query.cf_983 === "Visa" ?
                            <SvgVisa className="visa-img" width={36} height={60} style={{ fill: 'red' }} />
                            :
                            null
                      }
                    </div>

                    <div className="name-details display-flex-column padding10 query-main-cont" style={{ border: "" }}>

                      <div className="display-flex-row no-wrap">
                        <div className="center-aligned-column">
                          <SvgQueryID width={15} height={15} style={{ fill: 'blue' }} />
                        </div>
                        {/* <span className="heading2 font-md-small color-black ">Query ID: </span> */}
                        <div className="display-flex-row no-wrap font-cont" style={{ marginLeft: "5px" }}>
                          <span className="heading4 font-md-small font-size1 color-black" style={{ marginLeft: "2px" }}> {query.potential_no}</span>
                          <div className={`heading2 marginleft10 font-size font-small bg-color-${getStatusClass(query.sales_stage)}-light color-${getStatusClass(query.sales_stage)}`} style={{ padding: "2px 5px" }}>{query.sales_stage}</div>
                        </div>
                      </div>


                      <div className="row marginTop10 display-flex-row no-wrap">
                        <div className="center-aligned-column" style={{ marginRight: "5px" }}>
                          <SvgLocation width={15} height={15} style={{ fill: 'blue' }} />
                        </div>
                        <DestinatonAndCategory query={query} />
                      </div>

                      <div className="row marginTop10 display-flex-row no-wrap marginnn">
                        <div className="display-flex-row no-wrap flex-clm" style={{ border: "" }}>
                          <div className="flex-mobile" style={{ display: "flex", border: "" }}>
                            <div className="padding5 flex1 flex" style={{ border: "" }}>
                              <div className="display-flex-row no-wrap content-fit ">
                                <div className="center-aligned-column">
                                  <SvgDate width={15} height={15} style={{ fill: 'blue' }} />
                                </div>
                                <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">{query.cf_983 === "Accommodation" ? "Check-In" : "Date Of Travel"}</div>
                              </div>
                              <div className="marginTop10 margin1">
                                <Date query={query} isStartDate={true} />
                              </div>
                            </div>

                            {query.cf_983 === "Accommodation" ?
                              <div className="padding5 ion-margin-start addmargin flex margin-acc" style={{ border: "" }}>
                                <div className="display-flex-row no-wrap content-fit">
                                  <div className="center-aligned-column">
                                    <SvgDate width={15} height={15} style={{ fill: 'blue' }} />
                                  </div>
                                  <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">Check-Out</div>
                                </div>
                                <div className="marginTop10 margin1 marginRight">
                                  <Date query={query} isStartDate={false} />
                                </div>
                              </div>
                              :
                              query.cf_983 === "Visa" ?
                                <div className="padding5 ion-margin-start addmargin flex">
                                  <div className="display-flex-row no-wrap content-fit">
                                    <div className="center-aligned-column">
                                      <i className="far fa-clock  color-dark"></i>
                                    </div>
                                    <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">Duration</div>
                                  </div>
                                  <div className="marginTop10 margin1 marginRight1">
                                    <div className="fitsize heading3 color-black">{query.cf_1005}</div>
                                  </div>
                                </div>
                                :
                                null
                            }

                            {/* <div className="padding5 ion-margin-start addmargin flex">
                            <div className="display-flex-row no-wrap content-fit">
                              <div className="center-aligned-column">
                                <SvgDate width={15} height={15} style={{ fill: 'blue' }} />
                              </div>
                              <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">Check-Out</div>
                            </div>
                            <div className="marginTop10 margin1">
                              <Date query={query} isStartDate={false} />
                            </div>
                          </div> */}
                          </div>

                          <div className="flex-mobile" style={{ display: "flex" }}>
                            <div className="padding5 ion-margin-start addmargin flex margin-acc">
                              <div className="display-flex-row no-wrap content-fit">
                                <div className="center-aligned-column">
                                  {query.cf_983 === "Accommodation" ?
                                    <i className="far fa-clock color-dark"></i>
                                    : <i className="far fa-id-card color-dark"></i>
                                  }
                                </div>
                                <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">{query.cf_983 === "Accommodation" ? "No. of Rooms" : "Category"}</div>
                              </div>
                              <div className="marginTop10 margin1">
                                <Requirement query={query} />
                              </div>
                            </div>

                            <div className="padding5 ion-margin-start addmargin flex">
                              <div className="display-flex-row no-wrap content-fit">
                                <div className="center-aligned-column">
                                  <i className="fas fa-user-friends color-dark" style={{ color: "white" }}></i>
                                  {/* <SvgTime width={10} height={10} style={{ fill: 'blue' }} /> */}
                                </div>
                                <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">No. of Guests</div>
                              </div>
                              <div className="marginTop10 margin1">
                                <NoOfPeople query={query} />
                              </div>
                            </div>
                          </div>

                          <div className="flex-mobile" style={{ display: "flex" }}>
                            <div className="padding5 ion-margin-start addmargin flex">
                              <div className="display-flex-row no-wrap content-fit">
                                <div className="center-aligned-column">
                                  {/* <i className="far fa-hotel color-dark"></i> */}

                                  {query.cf_983 === "Accommodation" ?
                                    <i className="fas fa-hotel color-dark"></i>
                                    : <i className="fas font-medium fa-passport color-dark"></i>
                                  }

                                </div>
                                <div style={{ fontSize: "" }} className="heading4 headingg uppercase color-dark marginleft5">{query.cf_983 === "Accommodation" ? "Hotel Category" : "Passport Nationality"}</div>
                              </div>
                              <div className="marginTop10 margin1">
                                <Category query={query} />
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>

                      <div className="row marginTop10 display-flex-row no-wrap">

                        {/* <div className="display-flex-row no-wrap">
                    <Requirement query={query}/>
                  </div> */}

                        {/* <div className="display-flex-row no-wrap">
                    <div className="center-aligned-column">
                      <SvgPeople width={15} height={15}  style={{ fill: 'blue' }} />
                    </div>
                    <NoOfPeople query={query}/>
                  </div> */}

                      </div>

                      {/* <div className="row right-bottom marginTop10 center-aligned-column no-wrap">
                  <div className={`heading3 font-small  bg-color-${getStatusClass(query.sales_stage)}-light color-${getStatusClass(query.sales_stage)}`} style={{padding:"2px 5px"}}>{query.sales_stage}</div>
                </div> */}

                    </div>
                  </div>

                </div>
                : null}
            </React.Fragment>
          );
        })}





        {limit >= queriesList.length ?
          <div className="center-aligned-column full-width padding10 click-effect" onClick={() => setLimit(3)}>
            <div className="display-flex-row no-wrap ion-padding-top">
              <div className="heading3 color-primary " style={{ textDecoration: "underline" }}>Show less</div>
              <div> <i className="fas fa-external-link-alt marginleft5 color-primary"></i> </div>
            </div>
          </div>
          :
          <div className="center-aligned-column full-width padding10 click-effect" onClick={() => setLimit(prev => {
            return prev + 10
          })}>
            <div className="display-flex-row no-wrap ion-padding">
              <div className="heading3 color-primary " style={{ textDecoration: "underline" }}>Load more</div>
              <div>
                <img src="/assets/images/submit.png" alt="" className="marginleft5 color-primary" />
                {/* <i className="fas fa-external-link-alt marginleft5 color-primary"></i> */}
              </div>
            </div>
          </div>
        }



      </div>
    </>
  )
};

export default AllQueryList;
