import React, { useState, useEffect, useRef } from 'react';

interface CaptchaGeneratorProps {
  onChange: (text: string) => void;
}

const CaptchaGenerator: React.FC<CaptchaGeneratorProps> = ({ onChange }) => {
  const [captchaText, setCaptchaText] = useState<string>('');
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const text = Math.random().toString(36).substring(3, 8);
    setCaptchaText(text);
    drawCaptcha(text);
    onChange(text);
  };

  const drawCaptcha = (text: string) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = '30px Arial';
        ctx.fillStyle = "#6d7642";
        ctx.fillText(text, 10, 30);
        ctx.strokeStyle = "";
        ctx.lineWidth = 1;
        ctx.shadowColor = "rgba(55, 180, 40, 0.5)";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 5;
        ctx.shadowOffsetY = 5;

        // Adding some noise
        for (let i = 0; i < 2; i++) {
          ctx.beginPath();
          ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
          ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
          ctx.stroke();
          // Assuming ctx is your canvas 2D context
          // const centerX = canvas.width / 2;
          //const centerY = canvas.height / 2;
          const centerX = Math.random() * canvas.width / 2;
          const centerY = Math.random() * canvas.height / 2;
          const radius = (i * 3) * i;

          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI); // Full circle
          ctx.strokeStyle = '#6d7642';
          ctx.lineWidth = 2;
          ctx.fillStyle = 'transparent';
          ctx.stroke();
          ctx.closePath();

        }
    }
    }
  };

  return (
    <div style={{border: "",width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px"}}>
      <canvas className='captcha-comp' ref={canvasRef} width={155} height={50} />
      <button className='heading underline pointer' style={{background: "white", paddingRight: "22px"}} onClick={generateCaptcha}><i className="fas fa-redo color-dark"></i></button>
    </div>
  );
};

export default CaptchaGenerator;

