export class VisaForm {
    // query_service_category:string = "demo"
    // opportunity_name:string = "opp1"

    visa_destination: string = ""
    visa_category: string = ""
    visa_type: string = ""
    nationality: string = ""
    passport_issued_from: string = ""
    no_of_passports: string = ""
    date_of_travel: string = ""
    date_of_return: string = ""
    no_of_adults: number = 0
    no_of_children: number = 0
    customer_city_town: string = ""
    how_soon_are_you_willing_to_continue: string = ""

    constructor(query: any) {

        if (query) {
            this.visa_destination = query.cf_1019
            this.visa_category = query.cf_1003
            this.visa_type = query.cf_1005
            this.nationality = query.cf_1009
            this.passport_issued_from = query.cf_1007
            this.no_of_passports = ""
            this.date_of_travel = query.cf_1015
            this.date_of_return = query.cf_1021
            this.no_of_adults = query.cf_1011
            this.no_of_children = query.cf_1013
            this.customer_city_town = query.cf_1161
        }

    }

    setVisaDestination(value: string) {
        this.visa_destination = value;
    }

    setVisaCategory(value: string) {
        this.visa_category = value;
    }

    setVisaType(value: string) {
        this.visa_type = value;
    }

    setVisaNationality(value: string) {
        this.nationality = value;
    }

    setPassportIssuedFrom(value: string) {
        this.passport_issued_from = value;
    }

    setNumberOfPassport(value: string) {
        this.no_of_passports = value;
    }

    setDateOfTravel(value: string) {
        this.date_of_travel = value;
    }

    setDateOfReturn(value: string) {
        this.date_of_return = value;
    }

    // setNumberOfAdults(value: number){
    //     this.no_of_adults = value;
    // }

    // setNumberOfChildren(value: number){
    //     this.no_of_children = value;
    // }

    setCustomerCityOrTown(value: string) {
        this.customer_city_town = value;
    }

    setWillingToContinue(value:string){
        this.how_soon_are_you_willing_to_continue = value
    }
    // removeFields(){
    //     this.destination_city = undefined
    //     this.hotel_name = undefined
    //     this.hotel_category = undefined
    //     this.budget = undefined
    // }   
}
