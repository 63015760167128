import React, { useEffect, useState } from "react";
import "../../style/query/createquery.css";
import Services from "./Services";
import Visa from "./Visa/Visa";
import Hotel from "./hotel/Hotel";
import Tourpackage from "./tourPackage/Tourpackage";
import CabBooking from "./cabBooking/CabBooking";
import Header from "./common/Header";
import { applicationConfig } from "../../../utils/methods";
import GroupTours from "./groupTour/GroupTours";
import CorporateEventConference from "./corporateEvent&Conference/CorporateEvent_Conference";
import { showToast } from "../../../utils/UI_methods/global";

const CreateQuery: React.FC<{
  setisOpenForm: (val: boolean) => void
}> = (props) => {

  const [currSelectedService, setCurrSelectedService]: any = useState(null)

  useEffect(() => {
    if (currSelectedService) {
      applicationConfig.logger.info(`Create Query started for: ${currSelectedService}`)
    }
  }, [currSelectedService])




  return (
    <div className="wrapper create-query-wrapper full-height-width position-relative">
      {!currSelectedService ?
        <Services
          setCurrSelectedService={setCurrSelectedService}
        />
        :
        currSelectedService?.toLowerCase() === "hotel" || currSelectedService?.toLowerCase() === "accommodation" ?
          <Hotel
            isUpdateMode={false}
            closeAction={() => setCurrSelectedService(null)}
            query={null}
          />
          :
          currSelectedService?.toLowerCase() === "visa" ?
            <Visa
              isUpdateMode={false}
              closeAction={() => setCurrSelectedService(null)}
              query={null}
            />
            :
            currSelectedService?.toLowerCase() === "tour package" ?
              <Tourpackage
                isUpdateMode={false}
                closeAction={() => setCurrSelectedService(null)}
              />
              :
              currSelectedService?.toLowerCase() === "cab booking" ?
                <CabBooking
                  isUpdateMode={false}
                  closeAction={() => setCurrSelectedService(null)}
                />
                :
                currSelectedService?.toLowerCase() === "group tours" ?
                  setCurrSelectedService(null)
                  :
                  currSelectedService?.toLowerCase() === "corporate event / conference" ?
                    setCurrSelectedService(null)
                    :
                    <>
                      <Header
                        heading="Service not available at this moment"
                        closeAction={() => setCurrSelectedService(null)}
                      />
                      <div className="create-query-header full-height-width">
                        <div className="full-width display-flex-row space-between">
                          <div className="" onClick={() => setCurrSelectedService(null)}> <i className="color-white hide-in-desktop font-medium fas fa-arrow-left"></i> </div>
                          <div onClick={() => setCurrSelectedService(null)} className=""> <i className="color-white  font-medium fas fa-home"></i> </div>
                        </div>
                        <div className="heading3 font-medium text-center color-white letter-spacing1">{"Servie not available"}</div>
                      </div>
                    </>

      }

    </div>
  )
};

export default CreateQuery;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}

