
import React, {useState} from "react";
import AllQueryList from "./AllQueryList";
import ArchivedQueryList from "./ArchivedQueryList";
import QueryHeader from "./QueryHeader";
import { ReactComponent as SvgLocation } from '../../svg/location.svg';
import HorizontalScroll from "../../common/ui-components/scrollbar/HorizontalScroll";
import { jsPDF } from "jspdf";
import { useHistory } from "react-router";
import Loader from "../../common/ui-components/Loader";

const QueryListContainer: React.FC<{
  setIsShowDetails: (val: boolean) => void
  isShowDetails: boolean
  currTab: string
  setCurrTab: (val: string) => void
}> = (props) => {


  var locations = [
    {
      src: "assets/images/destinations/maldives.jpg",
      title: "Khao tropical rainforest",
      location: "Thailand"
    },
    {
      src: "assets/images/destinations/manali.jpg",
      title: "Khao tropical rainforest",
      location: "Thailand"
    },
    {
      src: "assets/images/destinations/manali.jpg",
      title: "Khao tropical rainforest",
      location: "Thailand"
    },
  ]



  const [filteredQueries, setFilteredQueries]: any = useState([]);

  // console.log("filteredQueries-----", filteredQueries);


  function Suggestions() {
    return (
      <div className="suggessions-container  marginTop20">
        <div className="margin-bottom display-flex-row no-wrap space-between ion-margin-bottom">
          <div className="heading3 heading semibold">Need suggestions?</div>
          <div className="heading4 see-all">See all</div>
        </div>

        <div className="suggestions-cont full-width">
          {locations.map((location: any, index: number) => {
            return (
              <div className="white-card marginRight10 shadow-light" style={{ minWidth: "169px", maxWidth: "12rem", height: "194px", borderRadius: "15px", marginBottom: "10px" }}>
                <div className="" style={{ height: "136px" }}>
                  <img src={location.src} className="full-height-width" alt="" style={{ borderRadius: "15px 15px 0 0" }} />
                </div>
                <div className="padding5">
                  <div className="heading3 bold">{location.title}</div>
                  <div className="display-flex-row no-wrap marginTop5">
                    <div className="center-aligned-column">
                      <SvgLocation width={10} height={10} />
                    </div>
                    <div className="heading4 marginleft5 color-dark">{location.location}</div>
                  </div>
                </div>

              </div>

            )
          })}
        </div>


      </div>
    )
  }


  return (
    <div className="module-content-wrapper padding-container hide-scrollbar padding-container" >
      <QueryHeader
        isShowLeadDetails={props.isShowDetails}
        setIsShowLeadDetails={props.setIsShowDetails}
        currTab={props.currTab}
        setCurrTab={props.setCurrTab}
        setFilteredQueries={setFilteredQueries}
        filteredQueries={filteredQueries}
      />
      <div className="main-inner-content-wrapper hide-scrollbar  display-flex-row" style={{ paddingBottom: "7rem" }}>

        <div className="query-suggestion-cont display-flex-row full-width distance-between">
          <div className="padding10 query-cont" style={{ width: "calc(100% - 17rem)" }}>
            {props.currTab === "All" ?
              <AllQueryList setIsShowDetails={props.setIsShowDetails} filteredQueries={filteredQueries} setFilteredQueries={setFilteredQueries} />
              :
              <ArchivedQueryList setIsShowDetails={props.setIsShowDetails} />
            }
          </div>
          <div className="suggestion padding10 display-flex-row" style={{ justifyContent: "flex-end", width: "17rem" }}>
            <div className="full-width service-list-filter service-list-with-scroll marginTop10">
              <HorizontalScroll
                itemsClass="sub-categories-container"
                Component={Suggestions}
                itemsList={locations}
                id={`ProductSbuCategoriesScrollBar`}
              />
            </div>
            <div className="service-list-without-scroll">
              <Suggestions />
            </div>
          </div>
        </div>


      </div>

    </div>
  )
};

export default QueryListContainer;
