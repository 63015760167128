import {
  IonCheckbox,
  IonIcon, IonModal
} from "@ionic/react";
import { search } from "ionicons/icons";

import React, { useEffect, useState } from "react";
import { addClassInElement, removeClassFromElement } from "../../../utils/UI_methods/global";
import { applicationConfig } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
// import Button from "../../common/ui-components/buttons/Button";
import ControlsTab from "../../common/ui-components/tabs/ControlsTab";
import { ReactComponent as SvgDestination } from '../../svg/Destination.svg';
import { ReactComponent as SvgGuestId } from '../../svg/Guest ID.svg';
import { ReactComponent as SvgQueryID } from '../../svg/QueryID.svg';
import { ReactComponent as SvgQueryStatus } from '../../svg/QueryStatus.svg';
import { ReactComponent as SvgService } from '../../svg/Service Category.svg';
import { ReactComponent as SvgTravelDateRange } from '../../svg/Travel Date Range.svg';
import { ReactComponent as SvgFilter } from "../../svg/filter.svg";
import CreateQuery from "./../craeteQuery/CreateQuery";
import $ from "jquery"

const QueryHeader: React.FC<{
  isShowLeadDetails: boolean
  setIsShowLeadDetails: (val: boolean) => void
  currTab: string
  setCurrTab: (val: string) => void
  filteredQueries?: any
  setFilteredQueries: (val: string[]) => void
}> = (props) => {

  const [isOpenForm, setisOpenForm] = useState<boolean>(false);
  const [queryStatusList] = useState(["Query Submitted", "Quote Received", "Modification Approved", "Modification Requested", "Confirmed", "Comfirmation Pending", "Sent for confirmation", "Vouchered"]);

  var selectedQueryStatus: any = []

  var tabItems = [
    { tabName: "All", label: "Open queries", ionIcon: null },
    { tabName: "Archived", label: "Bookings", ionIcon: null },
  ]


  useEffect(() => {
    applicationConfig.setFunctionRef("query", { closeCreateQueryForm: setisOpenForm })
  }, [])


  function filterQueries() {
    addClassInElement("filterElementList", "display-none")
    let allQueries = applicationConfig.data.queriesList
    const setQueriesList = applicationConfig.functions.queries.setQueriesList
    const destination: any = $("#destinationFilter").val() || ""
    const queryId: any = $("#queryIdFilter").val() || ""
    const serviceCategory: any = $("#serviceCategoryFilter").val() || ""
    let checkInDate: any = $("#checkInDate").val() || ""
    let checkOutDate: any = $("#checkOutDate").val() || ""

    if (destination.length) {
      allQueries = allQueries.filter((currQuery: any) => currQuery.cf_917.toLowerCase().includes(destination.toLowerCase()))
      applicationConfig.logger.info(`Filtered query with destination ${destination}`)
      props.setFilteredQueries([...props.filteredQueries, `Destination ${destination}`]);
    }
    if (queryId.length) {
      allQueries = allQueries.filter((currQuery: any) => currQuery.potential_no.toLowerCase().includes(queryId.toLowerCase()))
      applicationConfig.logger.info(`Filtered query with queryId ${queryId}`)
      props.setFilteredQueries([...props.filteredQueries, `QueryId ${queryId}`])
    }
    if (serviceCategory.length) {
      allQueries = allQueries.filter((currQuery: any) => currQuery.cf_983.toLowerCase().includes(serviceCategory.toLowerCase()))
      applicationConfig.logger.info(`Filtered query with serviceCategory ${serviceCategory}`)
      props.setFilteredQueries([...props.filteredQueries, `ServiceCategory ${serviceCategory}`])
    }
    if (selectedQueryStatus.length) {
      allQueries = allQueries.filter((currQuery: any) => selectedQueryStatus.includes(currQuery.sales_stage))
      applicationConfig.logger.info(`Filtered query with selectedQueryStatus ${selectedQueryStatus}`)
      props.setFilteredQueries([...props.filteredQueries, `SelectedQueryStatus ${selectedQueryStatus}`])
    }

    if (checkInDate.length && checkOutDate.length) {
      checkInDate = Number(new Date(checkInDate))
      checkOutDate = Number(new Date(checkOutDate))
      allQueries = allQueries.filter((currQuery: any) => {
        console.log(currQuery, "---------------");
        const checkIn = Number(new Date(currQuery.cf_935))
        const checkOut = Number(new Date(currQuery.cf_937))
        if (checkIn >= checkInDate && checkIn <= checkOutDate) {
          return currQuery
        }
        if (checkOut >= checkOutDate && checkOut <= checkOutDate) {
          return currQuery
        }
      })
      applicationConfig.logger.info(`Filtered query with checkInDate ${checkInDate} and  checkOutDate ${checkOutDate}`)
      props.setFilteredQueries([...props.filteredQueries, `CheckInDate ${new Date(checkInDate).toLocaleDateString('en-GB')} and  CheckOutDate ${new Date(checkOutDate).toLocaleDateString("en-GB")}`])
    }

    setQueriesList(allQueries)
  }


  function clearFilters() {
    addClassInElement("filterElementList", "display-none")
    let allQueries = applicationConfig.data.queriesList
    const setQueriesList = applicationConfig.functions.queries.setQueriesList
    setQueriesList(allQueries)
    resetFilters()
  }

  function updateQueryStatusArray(event: any, value: string) {
    // selectedQueryStatus = event.target.value
    const status = event.target.value
    if (selectedQueryStatus.includes(status)) {
      selectedQueryStatus = selectedQueryStatus.filter((currStatus: string) => currStatus !== status)
    } else {
      selectedQueryStatus.push(status)
    }
  }

  function resetFilters() {
    $("#destinationFilter").val("")
    $("#queryIdFilter").val("")
    $("#serviceCategoryFilter").val("")
    $("#checkInDate").val("")
    $("#checkOutDate").val("")
    selectedQueryStatus = []
  }


  function QueryStatus() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column pointer-none"><SvgQueryStatus width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium marginleft10 color-dark pointer-none">Query Status</div>
        </div>

        <div className="filters-list display-none" style={{ padding: "0 10px" }}>
          {queryStatusList.map((status: any, index: number) => {
            return (
              <div key={index} className="display-flex-row no-wrap marginRight5 marginTop5">
                <IonCheckbox value={status} onIonChange={(event) => { updateQueryStatusArray(event, status) }}></IonCheckbox>
                <div className="input-label marginleft10">{status}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  function TravelDateRange() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column pointer-none"><SvgTravelDateRange width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium pointer-none marginleft10 color-dark">Travel Date Range</div>
        </div>

        <div className="filters-list display-none display-flex-row space-between" style={{ padding: "0 10px" }}>
          <div className="">
            <div className="heading3 font-small">Start Date</div>
            <input className="input" id="checkInDate" type="date" name="" />
          </div>

          <div className="">
            <div className="heading3 font-small">End Date</div>
            <input className="input" id="checkOutDate" type="date" name="" />
          </div>

        </div>
      </div>
    )
  }

  function GuestName() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column"><SvgGuestId width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium pointer-none marginleft10 color-dark">Guest Name</div>
        </div>

        <div className="filters-list display-none display-flex-row space-between" style={{ padding: "0 10px" }}>
          <div className="full-width">
            <input className="input full-width" type="text" name="" />
          </div>
        </div>
      </div>
    )
  }

  function Destination() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column"><SvgDestination width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium pointer-none marginleft10 color-dark">Destination</div>
        </div>

        <div className="filters-list display-none display-flex-row space-between" style={{ padding: "0 10px" }}>
          <div className="full-width">
            <input id="destinationFilter" className="input full-width" type="text" name="" />
          </div>
        </div>
      </div>
    )
  }

  function ServiceCategory() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column"><SvgService width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium pointer-none marginleft10 color-dark">Service Category</div>
        </div>

        <div className="filters-list display-none display-flex-row space-between" style={{ padding: "0 10px" }}>
          <div className="full-width">
            <select id="serviceCategoryFilter" className="input" name="" >
              <option></option>
              {applicationConfig?.data?.servicesList?.map((service: any, index: number) => {
                return (
                  <option key={index}>{service.servicename}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }


  function QueryId() {
    return (
      <div className="wrapper ">
        <div className="display-flex-row no-wrap border-bottom padding10" onClick={expandFilters}>
          <div className="center-aligned-column"><SvgQueryID width={16} height={16} style={{ fill: 'blue' }} /></div>
          <div className="heading4 font-medium pointer-none marginleft10 color-dark">Query Id</div>
        </div>

        <div className="filters-list display-none display-flex-row space-between" style={{ padding: "0 10px" }}>
          <div className="full-width">
            <input id="queryIdFilter" className="input full-width" type="text" name="" />
          </div>
        </div>
      </div>
    )
  }

  function changeTabOption(tabName: string) {
    props.setCurrTab(tabName);
    // updateTabValue(tabName)
  }


  function expandFilters(event: any) {
    $(event.target).parent().find(".filters-list").toggleClass("display-none")
  }

  function openFiltersList() {
    removeClassFromElement("filterElementList", "display-none")
    // resetFilters()
  }

  return (
    <div className="filter-header-wrapper position-relative">

      <IonModal
        onDidDismiss={() => setisOpenForm(false)}
        isOpen={isOpenForm}
        id="inventoryModal"
        cssClass='medium-y-modal'
      >
        <CreateQuery setisOpenForm={setisOpenForm}
        />
      </IonModal>

      {!props.isShowLeadDetails ?
        <>
          <div className="display-flex-row no-wrap header-2-btns" style={{ justifyContent: "space-between", border: "" }}>

            <div className="tab-btn">
              <ControlsTab
                configObj={{}}
                tabItems={tabItems}
                currSelectedValue={props.currTab}
                action={changeTabOption}
                theme=""
              />
            </div>


            <div className="add-new center-aligned-column create-query-btn">
              <Button2
                configObj={{}}
                action={() => setisOpenForm(true)}
                label={"Create query"}
                fontAwesomeIcon={"/assets/images/create.png"}
                iconPos="left"
                classNames={"shadow-light button- bg-color-primary"}
              />
            </div>

          </div>

          <div className="display-flex-row no-wrap space-between ion-margin-top">
            <div className="search-container" style={{ width: "90%", marginRight: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <input type="text" placeholder="Search query" className="search-input" style={{ paddingLeft: "30px" }} />
              <img src="/assets/images/search.png" alt="" className="icon left-middle font-medium" />
              {/* <IonIcon className="icon left-middle font-medium" icon={search}></IonIcon> */}
            </div>

            <div className="center-aligned-column position-relative">
              <Button2
                configObj={{ svg: true, SvgIcon: () => (<SvgFilter />) }}
                action={openFiltersList}
                label={""}
                fontAwesomeIcon={"fas fa-filter color-"}
                iconPos="left"
                classNames={"shadow-light margin0 bg-color-primary"}
              />

              <div className="top-right zindex100">
                <div className="white-card padding10 display-none" style={{ width: "300px" }} id="filterElementList">
                  <div className="header display-flex-row space-between">
                    <div className="heading1 font-medium">Filter queries</div>
                    <div onClick={() => addClassInElement("filterElementList", "display-none")} className="center-aligned-column"><i className="fas fa-times color-black font-medium"></i></div>
                  </div>

                  <div className="options">

                    <QueryStatus />
                    <TravelDateRange />
                    <GuestName />
                    <Destination />
                    <ServiceCategory />
                    <QueryId />
                  </div>

                  <div className="actions display-flex-row space-around padding10">
                    <Button2
                      configObj={{ svg: true, SvgIcon: () => (<SvgFilter />) }}
                      action={() => { clearFilters() }}
                      label={"Clear filters"}
                      fontAwesomeIcon={"fas fa-filter color-"}
                      iconPos="left"
                      classNames={"shadow-light button-small margin0 bg-color-light"}
                    />
                    <Button2
                      configObj={{ svg: true, SvgIcon: () => (<SvgFilter />) }}
                      action={() => { filterQueries() }}
                      label={"Apply filters"}
                      fontAwesomeIcon={"fas fa-filter color-"}
                      iconPos="left"
                      classNames={"shadow-light button-small margin0 bg-color-primary"}
                    />
                  </div>


                </div>
              </div>


            </div>

          </div>

        </>
        :
        <div className="display-flex-row no-wrap" style={{ justifyContent: "space-between" }}>
          <div className="center-aligned-column">
            <Button2
              configObj={{}}
              action={() => props.setIsShowLeadDetails(false)}
              label={"Go back"}
              fontAwesomeIcon={"fas fa-chevron-left color-danger"}
              iconPos="left"
              classNames={"shadow-light font-medium bg-color-black"}
            />
          </div>
        </div>
      }
    </div>
  )
};

export default QueryHeader;
