import React, { useState } from "react";
import { Children, Room, Rooms } from "../../../../../utils/forms/hotel";
import { checkIfErrorExists, noOfChidlrenValidation, noOfRoomsValidation } from "../../../../../utils/forms/validation";
import { addClassInElement, removeClassFromElement, showToast } from "../../../../../utils/UI_methods/global";

const RoomSelector: React.FC<{
  formData: any
  setCurrStep: (val: string) => void;
}> = (props) => {

  const [rooms, setRooms] = useState(props.formData.room_details)
  const [refresh, setRefresh] = useState(String(Number(new Date)))

  // console.log("rooms...........", rooms.rooms);

  const [ageValue, setAgeValue] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])

  function increase(room: any, para: string, kids: string) {
    if (room[para] + room[kids] > 3) {
      alert("Max value 4")
      return
    }
    
    room[para] = room[para] + 1
    setRooms(rooms)
    setRefresh(String(Number(new Date)))

  }

  function dicrease(room: any, para: string) {
    let minValue = para === "no_of_adults" ? 1 : 0
    if (room[para] - 1 < minValue) {
      showToast("Min value " + minValue, 2000, "error")
      return
    }

    room[para] = room[para] - 1
    setRooms(rooms)
    setRefresh(String(Number(new Date)))

  }

  function addChildren(room: any, para: string, kids: string) {
	  if (room[para] + room[kids] > 3) {
      alert("Max value 4")
      return
    }
    room.children_list.push(new Children())
    room[kids] = room.children_list.length
    setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }

  function removeChildren(room: any, kids: string) {
    // room.removeChildren(children.childId)
    room.children_list.pop()
    room[kids] = room.children_list.length
    setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }




  function submitHandle() {
    // if(!props.formData.destination_city.length){
    //   alert("Please enter destination")
    //   return
    // }
    // nextStep()
    removeClassFromElement("roomSelectorWrapper", "--is-active")
  }


  function addRoom() {
    console.log(rooms.rooms)
    if (rooms.rooms.length === 6) {
      showToast("For more than 6 rooms, create a group query",2000, "error");
      return
    }
    rooms.addRoom()
    setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }

  function deleteRoom(id: number) {
    // console.log(rooms.rooms)
    if (rooms.rooms.length === 1) {
      alert("Min value 1")
      return
    }
    rooms.deleteRoom(id)
    setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }

  function getAdultsCount() {
    let count = 0
    for (const room of rooms.rooms) {
      count = count + room.no_of_adults
    }
    props.formData.no_of_rooms = rooms.rooms.length
    props.formData.no_of_adults = count
    return count
  }

  function getChildrenCount() {
    let count = 0
    for (const room of rooms.rooms) {
      count = count + room.no_of_children
    }
    props.formData.no_of_rooms = rooms.rooms.length
    props.formData.no_of_children = count
    return count
  }

  function updateChildrenAge(children: any, age: number) {
    children.age = age
    setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }

  return (
    <>

      <div className="ion-margin-top" data-refresh={refresh} style={{ marginTop: "55px" }} onClick={() => addClassInElement("roomSelectorWrapper", "--is-active")}>
        <div className="label pointer-none full-width">Number of Rooms / Guests</div>
        <div className="center-aligned-row pointer-none" >
          <input value={`${rooms.rooms.length} Rooms, ${getAdultsCount()} Adults, ${getChildrenCount()} Children`} className="input pointer-none search-input" placeholder="" name="" >
          </input>
        </div>
      </div>

      <div className="ion-padding white-card overflow-y-scroll" data-refresh={refresh} id="roomSelectorWrapper">
        <div className="white-card ion-padding">
          {/* <div onClick={()=>removeClassFromElement("roomSelectorWrapper","--is-active")} className="heading1 color-primary display-flex-row" style={{justifyContent:"flex-end"}}> <i className="fas fa-times font-medium color-black"></i> </div> */}

          {rooms.rooms.map((room: Room, index: any) => {
            return (
              <div className="full-width ion-margin-bottom" key={index}>
                <div className="display-flex-row space-between">
                  <div className="heading2  color-primary">Room {index + 1}</div>
                  <i className="fas fa-trash-alt color-danger" onClick={() => deleteRoom(room.roomId)}></i>
                </div>

                <div className="input-wrapper">
                  <div className="display-flex-row no-wrap" style={{ marginTop: "20px" }}>
                    <div className="label  width80 display-flex-row"
                      style={{ alignItems: "start" }}>
                      No. of Adults <span className="heading4 marginleft5 marginTop5 font-small color-dark">Age above 12</span>
                    </div>
                    <div className="width20">
                      <div className="white-card no-shadow display-flex-row no-wrap border" style={{ borderWidth: "2px" }}>
                        <i className="fas fa-minus pointer color-black font-small center-aligned-column padding5" onClick={() => dicrease(room, "no_of_adults")}></i>
                        <div className="input center-aligned-column input-range-number border-right border-left color-primary" style={{ display: "flex" }}>{room.no_of_adults}</div>
                        <i className="fas fa-plus pointer color-black font-small  center-aligned-column padding5" onClick={() => increase(room, "no_of_adults", "no_of_children")}></i>
                      </div>
                    </div>
                  </div>
                  <div className="display-flex-row" style={{ justifyContent: "flex-end" }}>
                    <ul className="color-danger error font-small"></ul>
                  </div>
                </div>

                <div className="input-wrapper">
                  <div className="display-flex-row no-wrap ion-margin-top" style={{ marginTop: "10px" }}>
                    <div className="label  width80 display-flex-row"
                      style={{ alignItems: "start" }}>
                      No. of Children
                    </div>
                    <div className="width20">
                      <div className="white-card no-shadow display-flex-row no-wrap border" style={{ borderWidth: "2px" }}>
                        <i className="fas fa-minus pointer color-black font-small center-aligned-column padding5" onClick={() => removeChildren(room, "no_of_children")}></i>
                        <div className="input center-aligned-column input-range-number border-right border-left color-primary" style={{ display: "flex" }}>{room.children_list.length}</div>
                        {/* <input value={room.no_of_children_6_to_12yr} className="input input-range-number border-right border-left color-primary" type="number"  name=""/> */}
                        <i className="fas fa-plus pointer color-black font-small  center-aligned-column padding5" onClick={() => addChildren(room, "no_of_adults", "no_of_children")}></i>
                      </div>
                    </div>
                  </div>
                  <div className="display-flex-row" style={{ justifyContent: "flex-end" }}>
                    <ul className="color-danger error font-small"></ul>
                  </div>

                  <div className="age-selector marginTop5 display-flex-row">
                    {room.children_list.map((child: any, index: number) => {
                      return (
                        <div key={index} className="marginRight5 marginTop5">
                          <select className={"input "} name="" onChange={(event) => updateChildrenAge(child, Number(event.target.value))}>
                            {ageValue.map((val: number, index1: number) => (
                              <option key={index1} value={val}>{val} years</option>
                            ))}
                          </select>
                        </div>
                      )
                    })}
                  </div>

                </div>

                {/* <div className="input-wrapper">
                            <div className="display-flex-row no-wrap ion-margin-top" style={{marginTop:"10px"}}>
                            <div className="label  width80 display-flex-row"
                                style={{ alignItems: "start" }}>
                                How many Children <span className="heading4 marginleft5 marginTop5 font-small color-light">Age less then 6</span>
                            </div>
                            <div className="width20">
                              <div className="white-card no-shadow display-flex-row no-wrap border" style={{borderWidth:"2px"}}>
                                <i className="fas fa-minus pointer color-black font-small center-aligned-column padding5" onClick={()=>dicrease(room,"children_less_than_5yr")}></i>
                                <div className="input center-aligned-column input-range-number border-right border-left color-primary" style={{display:"flex"}}>{room.children_less_than_5yr}</div>
                                <i className="fas fa-plus pointer color-black font-small  center-aligned-column padding5" onClick={()=>increase(room,"children_less_than_5yr")}></i>
                              </div>
                            </div>
                            </div>
                            <div className="display-flex-row" style={{justifyContent:"flex-end"}}>
                                <ul className="color-danger error font-small"></ul>
                            </div>
                        </div> */}

              </div>

            )
          })}

          <div className="display-flex-row border-top space-between paddingTop10" style={{ paddingTop: "15px", borderColor: "black" }}>
            <div className="heading2  color-primary" onClick={addRoom}>
              <i className="fas fa-plus  color-primary marginRight5"></i>Add Room
            </div>
            <div className="heading2  color-primary" onClick={submitHandle}>
              Done
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default RoomSelector;

//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option defaultValue="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
