import React from "react";
import { ReactComponent as SvgDate } from '../../../svg/Travel Date Range.svg';

const Dates: React.FC<{
  query: any
}> = (props) => {
  //Curr page can be leads list page or details page
  return (
    <div className="full-width marginTop10">

      {props.query?.cf_983?.toLowerCase() === "accommodation" ?
        <></>
        :
        <div className="display-flex-row no-wrap padding5" style={{marginBottom: "-5px", justifyContent:"space-between"}}>
          <div className="heading-small color-ex-dark line-height-1 marginT visa-details-ft-size" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>Nationality: <span className="visa-details-ft-size color-ex-dark line-height-1">{props.query.cf_1009}</span></div>
          <div className="heading-small color-ex-dark line-height-1 marginT visa-details-ft-size" style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"0px"}}>Passport Issued From: <span className="visa-details-ft-size color-ex-dark line-height-1">{props.query.cf_1007}</span></div>
          <div className="heading-small color-ex-dark line-height-1 marginT visa-details-ft-size" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>Client City/Town: <span className="visa-details-ft-size color-ex-dark line-height-1">{props.query.cf_1161}</span></div>
        </div>
      }

      <div className="display-flex-row no-wrap padding5 space-between">
        <div className="">
          {
            props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <div className="heading-medium color-ex-dark line-height-1 marginT">Check In</div>
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <div>
                  {/* <div className="heading-small color-ex-dark line-height-1 marginT">Nationality: {props.query.cf_1009}</div> */}
                  <div className="heading-medium color-ex-dark line-height-1 marginT marginTop5">Date of Travel</div>
                </div>
                :
                null
          }
          <div className="display-flex-row no-wrap marginTop5">
            <div className="center-aligned-column">
              <SvgDate width={15} height={15} style={{ fill: 'blue' }} />
              {/* <i className="far fa-calendar font-medium color-dark"></i> */}
            </div>
            {props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <div className="heading4 marginleft10 color-dark">{props.query.cf_935}</div>
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <div className="heading4 marginleft10 color-dark">{props.query.cf_1015}</div>
                :
                <div className="heading4 marginleft10 color-dark">{props.query.cf_1029}</div>
              // null
            }
          </div>
        </div>

        <div className="center-aligned-column">
          <div className="center-aligned-column">
            <i className="fas fa-arrow-right font-medium color-dark"></i>
          </div>
        </div>

        <div className="">
          {
            props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <div className="heading-medium color-ex-dark line-height-1 marginT">Check Out</div>
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <div>
                  <div className="heading-medium color-ex-dark line-height-1 marginT marginTop5">Date of Return</div>
                </div>
                :
                null
          }

          <div className="display-flex-row no-wrap marginTop5">
            <div className="center-aligned-column">
              <SvgDate width={15} height={15} style={{ fill: 'blue' }} />
              {/* <i className="far fa-calendar font-medium color-dark"></i> */}
            </div>
            {props.query?.cf_983?.toLowerCase() === "accommodation" ?
              <div className="heading4 marginleft10 color-dark">{props.query.cf_937}</div>
              :
              props.query?.cf_983?.toLowerCase() === "visa" ?
                <div className="heading4 marginleft10 color-dark">{props.query.cf_1021}</div>
                :
                <div className="heading4 marginleft10 color-dark">{props.query.cf_1031}</div>
              // null
            }
          </div>
        </div>


      </div>
    </div>
  );
};

export default Dates;
