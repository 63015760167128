import React, { useRef } from "react";
import "./style/voucher.css";
import jsPDF from "jspdf";

const HotelBookingVoucher: React.FC<{}> = (props) => {

  const htmlContentRef: any = useRef(null);

  const handleGeneratePdf = () => {
    // const doc = new jsPDF({
    //   format: 'a4',
    //   unit: 'px',
    // });

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: [830,1170]
      /*putOnlyUsedFonts: true,*/
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    doc.html(htmlContentRef.current, {
      async callback(doc) {
        await doc.save('document.pdf');
      },
    });

  }

  return (
  <div className="entirea4page">
    <div className="main-container">
      <div ref={htmlContentRef} className="inner-main-conatiner">

        {/*========= Voucher Header ============= */}

        <div className="voucher-header" style={{ background: "" }}>

          <div className="voucher-header-left">
            <img width={280} src="assets/images/travel_power_logo.png" alt="" />
          </div>

          <div className="voucher-header-right" >
            <div className="voucher-header-right-content">
              <i className="fas fa-phone-alt color-dark"></i>
              <p>1234567890</p>
            </div>
            <div className="voucher-header-right-content">
              <i className="fas fa-envelope color-dark"></i>
              <p>info@travelpower.com</p>
            </div>
            <div className="voucher-header-right-content">
              <i className="fas fa-envelope color-dark"></i>
              <p>www.travelpower.com</p>
            </div>
            <div className="voucher-header-right-content">
              <i className="fas fa-map-marker-alt color-dark"></i>
              <p>B-10, Gurugram</p>
            </div>
          </div>

        </div>

        {/*============ Voucher Main Container =========== */}

        <div className="voucher-main" style={{ background: "" }}>
          <div className="voucher-main-heading">
            <h1>Hotel Booking Voucher</h1>
          </div>

          {/*============ Voucher Main Container inside content =========== */}

          <div className="voucher-main-contents">

            {/*============ Voucher Main content1 div1 =========== */}

            <div className="voucher-main-content1" style={{ border: "" }}>
              <div className="voucher-main-content-heading">
                <p className="voucher-heading1">Booking Details</p>
                <p className="voucher-heading1"><span className="p-size" style={{fontWeight:"700", background:"white", padding:"1px 2px"}}> Hotel Confirmation No: 46265832 </span></p>
              </div>

              <div className="voucher-main-content-row1">
                <div className="row1-div-1">
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>Hotel Name</p>
                </div>

                <div className="row1-div-2">
                  <p className="p-size">Holiday Inn Express Zurich Airport</p>
                  <p className="p-size">Hofwinsenstrasse 30, Rumlang, Zuric 8153 ( P ):+41448093400</p>
                </div>


              </div>

              <div className="voucher-main-content-row2">
                <div className="row2-div-1">
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>Booking ID</p>
                </div>

                <div className="row2-div-2">
                  <p className="p-size">46265832</p>
                </div>

                <div className="row2-div-3">
                  <p className="voucher-heading1">Destination</p>
                </div>

                <div className="row2-div-4">
                  <p className="p-size">Zurich, Switzerland</p>
                </div>
              </div>

              <div className="voucher-main-content-row3">
                <div className="row3-div-1">
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>No. of Rooms</p>
                </div>

                <div className="row3-div-2">
                  <p className="p-size">1 Room(s)</p>
                </div>

                <div className="row3-div-3">
                  <p className="voucher-heading1">Stay Duration</p>
                </div>

                <div className="row3-div-4">
                  <p className="p-size">3 Night(s)</p>
                </div>
              </div>

              <div className="voucher-main-content-row4">

                <div className="row4-div-1">
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>Lead Guest &</p>
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>Residency / Nationality</p>
                </div>

                <div className="row4-div-2">
                  <p className="voucher-heading1" style={{ fontWeight: "bold" }}>Anil Kumar</p>
                  <p>Indian</p>
                </div>

                <div className="row4-div-3">
                  <p style={{ color: "white", textAlign: "center", fontWeight: "700" }}>Check In</p>
                  <p style={{ color: "white", textAlign: "center", fontWeight: "bold" }}>14-July-2023</p>
                </div>

                <div className="row4-div-4">
                  <p style={{ color: "white", textAlign: "center", fontWeight: "700" }}>Check Out</p>
                  <p style={{ color: "white", textAlign: "center", fontWeight: "bold" }}>14-July-2023</p>
                </div>
              </div>

            </div>

            {/*============ Voucher Main content2 div2 =========== */}

            <div className="voucher-main-content2" style={{ marginTop: "25px" }}>
              <div className="voucher-main-content-heading">
                <p className="voucher-heading1">Guest Details</p>
              </div>

              <div className="voucher-main-content2-innerheading marginTop5">
                <div className="content2-row1-div-1">
                  <p className="voucher-heading3">Room No</p>
                </div>

                <div className="content2-row1-div-2">
                  <p className="voucher-heading3">Room Type / Meal Plan</p>
                </div>

                <div className="content2-row1-div-3">
                  <p className="voucher-heading3" >Guest Name(s)</p>
                </div>

                <div className="content2-row1-div-4">
                  <p className="voucher-heading3" >Adult(s)</p>
                </div>

                <div className="content2-row1-div-5">
                  <p className="voucher-heading3">Child(ren)</p>
                </div>

                {/* <p className="voucher-heading3">Room No</p>
                <p className="voucher-heading3">Room Type / Board Basis</p>
                <p className="voucher-heading3">Guest Name</p>
                <p className="voucher-heading3">Adult(s)</p>
                <p className="voucher-heading3">Children</p> */}
              </div>

              <div className="voucher-main-content2-row1">
                <div className="content2-row1-div-1">
                  <p className="p-size">1</p>
                </div>

                <div className="content2-row1-div-2">
                  <p className="p-size">Double Bed Standard</p>
                  <p className="p-size">Sofa Bed (Breakfast)</p>
                </div>

                <div className="content2-row1-div-3">
                  <p className="p-size">Mr. Anil Kumar</p>
                  <p className="p-size">Mrs. Sweta Raj</p>
                </div>

                <div className="content2-row1-div-4">
                  <p className="p-size">2</p>
                </div>

                <div className="content2-row1-div-5">
                  <p className="p-size">NA</p>
                </div>

              </div>

            </div>

            {/*============ Voucher Main content3 div3 =========== */}

            <div className="voucher-main-content3" style={{ marginTop: "25px" }}>
              <div className="voucher-main-content-heading">
                <p className="voucher-heading1">Check-in/Check-out Timing & Policies</p>
              </div>

              <div className="voucher-main-content3-texts" style={{ padding: "15px 20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <p className="p-size"><span>*</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex velit sapiente modi iure minima reiciendis Lorem ipsum dolor sit amet id, </p>

                <p className="p-size"><span>*</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex velit sapiente modi iure minima reiciendis Lorem ipsum dolor sit amet
                </p>

                <p className="p-size"><span>*</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex velit sapiente modi iure minima reiciendis Lorem ipsum dolor sit amet
                </p>

                <p className="p-size"><span>*</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex velit sapiente modi iure minima reiciendis Lorem ipsum dolor sit amet
                </p>

                <p className="p-size"><span>*</span> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex velit sapiente modi iure minima reiciendis Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>

            {/*============ Voucher Main content4 div4 =========== */}

            <div className="voucher-main-content4" style={{ marginTop: "25px" }}>
              <div className="voucher-main-content-heading">
                <p className="voucher-heading1">Nationality & Domicile</p>
              </div>

              <div className="voucher-main-content4-texts" style={{ padding: "15px 15px" }}>
                <p className="p-size">Lorem ipsum dolor sit amet consectetur adipisicing elit. At minus voluptatum cupiditate, tempore libero veritatis iusto recusandae perspiciatis aspernatur ipsam ducimus a quisquam sunt hic fugit dignissimos dolorum nobis officiis.Quibusdam blanditiis eveniet explicabo earum laboriosam labore repellat, quam tenetur magni. Fugit atque odit maxime quisquam consectetur. In, sequi saepe reiciendis quidem impedit expedita eaque pariatur, incidunt, dolores rem doloremque? repellat, quam tenetur magni. Fugit atque odit maxime quisquam consectetur. In, sequi saepe reiciendis quidem impedit expedita eaque pariatur, incidunt, dolores rem doloremque?
                </p>
              </div>
            </div>

            {/*============ Voucher Main content footer div5 =========== */}

            <div className="voucher-main-footer" style={{ marginBottom: "20px", padding: "0px 5px" }}>
              <p className="p-size"><span className="p-size" style={{ fontWeight: "bold" }}>Note:</span> Check your Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat eius commodi mollitia. Amet ut facere cumque eos nisi.</p>
            </div>

          </div>

        </div>

        <button onClick={handleGeneratePdf} style={{ background: "blue", color: "white", padding: "5px 8px" }}>Download PDF</button>
      </div>

    </div>
  </div>
  )

};

export default HotelBookingVoucher;
