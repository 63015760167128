import React, { useEffect, useState } from 'react';
import { hotelNameValidation } from '../../../../utils/forms/validation';

const DepartureFrom: React.FC<{
    formData: any
}> = (props) => {

    function onchangesDepartureFrom(event: any){
        hotelNameValidation(event)
        props.formData.setDepartureFrom(event.target.value)
    }

    return (


        <div className="full-width display-flex-row no-wrap space-between marginTop10" >
            <div className="full-width">
                <div className="label full-width">Departure from (Tour ends at)<span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row">
                    <input defaultValue={props.formData.departure_from} onChange={(event) => {onchangesDepartureFrom(event)}} className="input search-input" name="" type="text" placeholder="End at (Name of Airport / Railway Station)" />
                </div>
            </div>
        </div>
    )
};

export default DepartureFrom;




