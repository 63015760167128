import React, { useEffect, useState } from "react";
import {
    IonCheckbox
} from "@ionic/react";
import { phoneValidation } from "../../../../utils/forms/validation";

const WattsappLink: React.FC<{
    closeQuery: (val: boolean) => void
    optionsData: any
}> = (props) => {


    // console.log(props.optionsData);

    const [inputNumber, setInputNumber]: any = useState("");
    const [cancellationPolicy, setCancellationPolicy] = useState<boolean>(false);
    const [markup, setMarkup]: any = useState(0);

    function phoneNumberValidation(event: any) {
        let errors = phoneValidation(event);

        console.log(errors);

        if (errors.length === 0) {
            setInputNumber(event.target.value);
        }
    }

    const url = `https://wa.me/91${inputNumber}?text=Hi,
with reference to your requirements, here's please find our quote below.

*Accommodation* in *${props.optionsData.cf_1037}* 

*Quote ID*: ${props.optionsData.quote_no}

*Check-in Date:* ${props.optionsData.cf_1031}
*Check-out Date:* ${props.optionsData.cf_1029}

*Room Category*: ${props.optionsData.cf_1087}
*No. of Rooms*: ${props.optionsData.cf_1093}
*Meal Plan*: ${props.optionsData.cf_1039}

*No. of Adults*: ${props.optionsData.cf_1089}
*No. of Childrens CWB*: ${props.optionsData.cf_1047}
*No. of Childrens CNB*: ${props.optionsData.cf_1049}

*Total Amount*: ${Number(props.optionsData.hdnSubTotal) + (Number(props.optionsData.hdnSubTotal) * Number(markup) / 100)} inclusive of GST
*Remarks*: ${props.optionsData.cf_1095}

${cancellationPolicy ? `*Cancellation Policy*: ${props.optionsData.cf_1041} `: " "}`;

//     const url = `https://wa.me/91${inputNumber}?text=Hi with reference to your requirements, here's please find our quote below. Quote ID = ${props.optionsData.quote_no} 
// Check-in Date: ${props.optionsData.cf_1031} 
// Check-out Date: ${props.optionsData.cf_1029}
// Room Category: ${props.optionsData.cf_1087}
// No. of Rooms: ${props.optionsData.cf_1093}
// Meal Plan: ${props.optionsData.cf_1039}
// No. of Adults: ${props.optionsData.cf_1089}
// No. of Childrens CWB: ${props.optionsData.cf_1047}
// No. of Childrens CNB: ${props.optionsData.cf_1049}
// Amount: ${Number(props.optionsData.hdnSubTotal) + (Number(props.optionsData.hdnSubTotal) * Number(markup) / 100)}
// Remarks: ${props.optionsData.cf_1095}
// Cancellation Policy: ${cancellationPolicy ? props.optionsData.cf_1041 : " "}`;

    const encodedURL = encodeURI(url);
    // console.log(encodedURL)

    return (
        <div className="WattsapLinkNumber-container" style={{ justifyContent: "" }}>
            <div>
                <p className="label heading3 full-width">Enter Your Mobile Number</p>
                {/* <select className="w3-select" name="option" style={{ width: "15%", border: "none" }}>
                    <option value="" disabled selected>+91</option>
                </select> */}

                {/* <input onChange={(e) => setInputNumber(e.target.value)} style={{ border: "none", backgroundColor: "#FBFBFB", width: "" }} id="agencyCode" type="tel" defaultValue={inputNumber} className="input search-input" name="" >
                </input> */}

                <div className="marginTop10">
                    <input style={{ border: "none", backgroundColor: "#FBFBFB" }} onChange={(e) => { phoneNumberValidation(e) }} id="phoneNumber" type="tel" defaultValue={inputNumber} className="input search-input" name="" >
                    </input>
                    <ul className="color-danger error font-small"></ul>
                </div>
            </div>

            <div>
                <p className="label heading3 full-width">Markup %</p>
                <input onChange={(e) => setMarkup(e.target.value)} style={{ border: "none", backgroundColor: "#FBFBFB", width: "" }} id="agencyCode" type="number" defaultValue={""} className="input search-input" name="" >
                </input>
                <div className="display-flex-row no-wrap marginTop10" style={{ alignItems: "center", justifyContent: "flex-start" }}>
                    <IonCheckbox onIonChange={() => {setCancellationPolicy(!cancellationPolicy)}}></IonCheckbox>
                    <div className="marginleft5" style={{ fontSize: "", fontWeight: "600" }}>Include Cancellation Policy</div>
                </div>
            </div>



            <a href={encodedURL} className="WattsapLinkNumber-btn center-aligned-column marginTop5" target="_blank" style={{ backgroundColor: "blueviolet", textDecoration: "none" }}>Submit</a>

            {/* <p>Only 100 most recent queries are displayed. To view older queries, use the Archive Section</p> */}
            {/* <button onClick={submit} className="queryList100-btn center-aligned-column bg-color-primary marginTop10" style={{}}>Submit</button> */}
        </div>

        // https://wa.me/91${inputNumber}?text=Hi%21%20Please%20find%20flight%20options%20below%2E`
    );
};

export default WattsappLink;
