import React from "react";
import { contactDetails, generalDetails, otherDetails } from "../../utils/data";
import { ReactComponent as SvgLocation } from '../svg/location.svg';
import Button2 from "../common/ui-components/buttons/Button2";
import "../style/profile/profile.css"


const Leads: React.FC<{
}> = (props) => {

  //Curr page can be leads list page or details page 




  function MainDetails() {
    return (
      <div className="full-width ion-padding shadow-light white-card main-card-white">
        <div className="display-flex-row space-between">
          <div className="profile-image-container">
            <div className="display-flex-row">
              <div className="image profile-user-image border padding10" style={{ position: "relative" }}>
                <img className="full-width img-size" src="assets/icon/profile.png" alt="" />
                <i className="fas fa-edit color-dark" style={{ position: "absolute", top: "0px" }}></i>
              </div>
              <div className="other marginleft10">
                <div className="heading1 headingggg">Kartik Katahre</div>
                <div className="marginTop5 color-dark headingg1">katahrekartik@gmail.com </div>
                <div className="marginTop5 color-dark" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <SvgLocation className="logo-icon" width={15} height={15} style={{ fill: 'blue' }} />
                  <p className="headingg2">Delhi</p>
                </div>
                {/* <i className="fas fa-edit color-dark"></i> */}
              </div>
            </div>
          </div>

          <div className="add-new center-aligned-column">
            <Button2
              configObj={{}}
              action={() => { }}
              label={"Edit"}
              fontAwesomeIcon={"/assets/images/create.png"}
              iconPos="left"
              classNames={"shadow-light button- bg-color-primary"}
            />
          </div>

          {/* <i className="fas fa-edit font-small"></i>  */}
          {/* <div className="actions">
            <button className="op-btn op-btn-primary" style={{display: "flex", alignItems: "center", justifyContent:,}}>
              <img src="/assets/images/create.png" alt=""/>
              <p style={{color: "white"}}>Edit</p>
          </button>
          </div> */}


        </div>
      </div>
    )
  }

  function OtherDetails() {
    return (
      <div className="full-width ion-margin-top">

        <div className="ion-padding full-width shadow-light white-card main-cont">
          <div className="container">
            <div className="font-medium heading1">User Information</div>
            <div className="wrapper display-flex-row" style={{}}>
              {generalDetails.map((details: any, index: number) => (
                <div className="display-flex-row width50 space-between ion-margin-top no-wrap content-container">
                  <div className="color-dark font-size">{details.fieldName}</div>
                  <div className="color-black ion-margin-end font-size">{details.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="full-width">
          <div className="ion-padding ion-margin-top shadow-light white-card main-cont">
            <div className="container">
              <div className="heading1">Company Details</div>
              <div className="wrapper display-flex-row">
                {contactDetails.map((details: any, index: number) => (
                  <div className="display-flex-row width50 space-between ion-margin-top no-wrap content-container">
                    <div className="color-dark font-size">{details.fieldName}</div>
                    <div className="color-black ion-margin-end font-size">{details.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="ion-padding ion-margin-top shadow-light white-card main-cont">
            <div className="container">
              <div className="heading1">Accounts Details</div>
              <div className="wrapper display-flex-row">
                {otherDetails.map((details: any, index: number) => (
                  <div className="display-flex-row width50 space-between ion-margin-top no-wrap content-container">
                    <div className="color-dark font-size">{details.fieldName}</div>
                    <div className="color-black ion-margin-end font-size">{details.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>


      </div>

    )
  }


  return (
    <div className="main-content-wrapper hide-scrollbar padding10 light-background">
      <div className="background-white full-height-width padding10 overflow-y-scroll">
        <MainDetails />
        <OtherDetails />
      </div>
    </div>
  );
};

export default Leads;
