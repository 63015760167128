import {
    IonInput, IonItem,
    IonLabel
} from "@ionic/react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCities, login } from "../../../services/api";
import { applicationConfig, setUserSession } from "../../../utils/methods";
import Button2 from "../../common/ui-components/buttons/Button2";
import Loader from "../../common/ui-components/Loader";
import { disable } from "workbox-navigation-preload";
import { showToast } from "../../../utils/UI_methods/global";
import Emailverify from "./Emailcom";
var md5 = require('md5');


const Emailotpget: React.FC<{
    setEmailotp: (val: boolean) => void
    emailotptext: any
    // enterOpt: (val: boolean) => void
}> = (props) => {

    const history = useHistory();

    console.log("EmailOPTtext.......", props.emailotptext);

    const [agencyCode, setAgencyCode] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const [reCaptchaToken, setRecaptchaToken] = useState<any>(null);

    function gotoEnteremail() {
        props.setEmailotp(false);
    }

    function confirmOtp() {
         let emailOtpData = getEmailOtpData();

         if(!emailOtpData.userName){
            showToast("Fill empty fields", 2000, "error")
            return;
         }
         
         if(emailOtpData.userName === props.emailotptext){
            showToast("OTP Verified Successfully", 2000, "success")
         }else{
            showToast("Incorrect OTP", 2000, "error")
         }
    }

    function getEmailOtpData() {
        return {
            userName: $("#otpEmail").val(),
          }
    }

    return (


            <div className="login-container position-relative" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "45px" }}>

                <div className="full-width center-aligned-column">
                    <img width={250} src="assets/images/travel_power_logo.png" alt="" />
                </div>

                {error ? <p className="error text-center heading1 font-normal capitalize">{error}</p> : null}

                <p className="text-center font-normal heading1" style={{ fontWeight: "bold" }}>REGISTER TO GET POWER IN YOUR HANDS</p>

                <div className="form-label-input-container">

                    <div className="label heading3 full-width">
                        <strong className="display-flex-row flex-end">
                            <span onClick={gotoEnteremail} className="heading underline pointer" style={{ fontSize: "", color: "blueviolet" }}>Re-Enter Email</span>
                        </strong>
                        Enter OTP Received On Email
                    </div>
                    <div className="center-aligned-row marginTop10">
                        <input placeholder={"_ _ _ _ _ _"} style={{ border: "none", backgroundColor: "#FBFBFB", fontSize: "15px" }} id="otpEmail" type="tel" defaultValue={agencyCode} className="input search-input" name="" >
                        </input>
                    </div>
                </div>

                <div className="flex-end full-width " style={{ display: "flex", alignItems: "center", }}>
                    <Button2
                        configObj={{}}
                        action={confirmOtp}
                        label={loading ? 'Please wait...' : 'Next'}
                        fontAwesomeIcon={loading ? " " : "/assets/images/Vector.png"}
                        iconPos="right"
                        classNames={"shadow-light login-button center-aligned-column full-width bg-color-primary"}
                    />

                </div>

            </div>

        )

};

export default Emailotpget;
