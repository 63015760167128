
import React from "react";
import { VisaForm } from "../../../../utils/forms/VisaForm";

const Nationality: React.FC<{
  formData: VisaForm
}> = (props) => {



  function onChangeNationality(value: string) {
    props.formData.setVisaNationality(value);
  }


  return (

    <div className="full-width display-flex-row no-wrap space-between marginTop10" >


      <div className="full-width">
        <div className="label full-width">Nationality <span className="mandatory color-danger">*</span></div>
        <div className="center-aligned-row">
          <select value={props.formData.nationality} defaultValue={props.formData.nationality} onChange={(event) => onChangeNationality(event.target.value)} className="input search-input" name="" >
            <option>Select Nationality</option>
            <option>Indian</option>
          </select>
        </div>
      </div>



    </div>

  )
};

export default Nationality;

