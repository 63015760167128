import React, { useState } from "react";
import { addClassInElement, removeClassFromElement, showToast } from "../../../../../utils/UI_methods/global";
import { VisaForm } from "../../../../../utils/forms/VisaForm";

const PassportSelector: React.FC<{
    formData:VisaForm
}> = (props) => {

    // const[rooms,setRooms] = useState(props.formData.room_details)
    const[refresh,setRefresh] = useState(String(Number(new Date)))

  function increase(para:string){

    if(para === "no_of_adults"){
      if(props.formData.no_of_adults > 9){
        showToast("Max value 9",2000,"error")
        return
      }
      props.formData.no_of_adults = props.formData.no_of_adults + 1
    }

    
    if(para === "no_of_children"){
      if(props.formData.no_of_children > 9){
        showToast("Max value 9",2000,"error")
        return
      }
      props.formData.no_of_children = props.formData.no_of_children + 1
    }

    setRefresh(String(Number(new Date)))
  }

  function dicrease(para:any){
    let minValue = para === "no_of_adults" ? 1 : 0
   
    
    if(para === "no_of_adults"){
      if(props.formData.no_of_adults - 1 < minValue){
        showToast("Min value " + minValue,2000,"error")
        return
      }
      props.formData.no_of_adults = props.formData.no_of_adults - 1
    }

    
    if(para === "no_of_children"){
      if(props.formData.no_of_children - 1 < minValue){
        showToast("Min value " + minValue,2000,"error")
        return
      }
      props.formData.no_of_children = props.formData.no_of_children - 1
    }
   
    // setRooms(rooms)
    setRefresh(String(Number(new Date)))
  }

  function submitHandle() {
    // if(!props.formData.destination_city.length){
    //   alert("Please enter destination")
    //   return
    // }
    // nextStep()
    removeClassFromElement("roomSelectorWrapper","--is-active")
  }

 


  return (
    <>

        <div className="marginTop10" data-refresh={refresh} onClick={()=>addClassInElement("roomSelectorWrapper","--is-active")}>
                <div className="label pointer-none full-width">No. of Passports <span className="mandatory color-danger">*</span></div>
                <div className="center-aligned-row pointer-none" >
                  <input value={`${props.formData.no_of_adults} Adults, ${props.formData.no_of_children} Children`} className="input pointer-none search-input" placeholder="" name="" >
                  </input>  
                </div>
        </div>

        <div  className="ion-padding white-card overflow-y-scroll center-aligned-column" data-refresh={refresh} id="roomSelectorWrapper">

            {/* <div onClick={()=>removeClassFromElement("roomSelectorWrapper","--is-active")} className="heading1 color-primary display-flex-row" style={{justifyContent:"flex-end"}}> <i className="fas fa-times font-medium color-black"></i> </div> */}

<div className="white-card padding10 full-width">

        <div  className="full-width ion-margin-bottom">
            <div className="display-flex-row space-between">
              <div className="heading2  color-primary">Passport Details</div>
            </div>
            

            <div className="input-wrapper">
                <div className="display-flex-row no-wrap" style={{marginTop:"10px"}}>
                <div className="label  width80 display-flex-row"
                    style={{ alignItems: "start" }}>
                    No. of Adults
                </div>
                <div className="width20">
                  <div className="white-card no-shadow display-flex-row no-wrap border" style={{borderWidth:"2px"}}>
                    <i className="fas fa-minus pointer color-black font-small center-aligned-column padding5" onClick={()=>dicrease("no_of_adults")}></i>
                    <div className="input center-aligned-column input-range-number border-right border-left color-primary" style={{display:"flex"}}>{props.formData.no_of_adults}</div>
                    <i className="fas fa-plus pointer color-black font-small  center-aligned-column padding5" onClick={()=>increase("no_of_adults")}></i>
                  </div>
                </div>
                </div>
                <div className="display-flex-row" style={{justifyContent:"flex-end"}}>
                    <ul className="color-danger error font-small"></ul>
                </div>
            </div>

            <div className="input-wrapper">
                <div className="display-flex-row no-wrap ion-margin-top" style={{marginTop:"10px"}}>
                <div className="label  width80 display-flex-row"
                    style={{ alignItems: "start" }}>
                    No of Children
                </div>
                <div className="width20">
                  <div className="white-card no-shadow display-flex-row no-wrap border" style={{borderWidth:"2px"}}>
                    <i className="fas fa-minus pointer color-black font-small center-aligned-column padding5" onClick={()=>dicrease("no_of_children")}></i>
                    <div className="input center-aligned-column input-range-number border-right border-left color-primary" style={{display:"flex"}}>{props.formData.no_of_children}</div>
                    {/* <input value={room.no_of_children} className="input input-range-number border-right border-left color-primary" type="number"  name=""/> */}
                    <i className="fas fa-plus pointer color-black font-small  center-aligned-column padding5" onClick={()=>increase("no_of_children")}></i>
                  </div>
                </div>
                </div>
                <div className="display-flex-row" style={{justifyContent:"flex-end"}}>
                    <ul className="color-danger error font-small"></ul>
                </div>
            </div>


        </div>

        <div className="display-flex-row border-top space-between paddingTop10" style={{paddingTop:"15px",borderColor:"black"}}>
                <div className="heading2  color-primary" style={{visibility:"hidden"}}>
                    <i className="fas fa-plus  color-primary marginRight5"></i>Add Room
                </div>
                <div className="heading2  color-primary" onClick={submitHandle}>
                    Done
                </div>
            </div>
           
        </div>
</div>

    </>
  );
  
};

export default PassportSelector;

//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option defaultValue="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
