import React, { useEffect, useState } from 'react';
import { IonModal } from "@ionic/react";
import { showToast } from '../../../../utils/UI_methods/global';
import { VisaForm } from '../../../../utils/forms/VisaForm';
import { applicationConfig } from '../../../../utils/methods';
import Button2 from '../../../common/ui-components/buttons/Button2';
import Header from '../common/Header';
import Destination from './Destination';
import VisaCategoryAndType from './VisaCategoryAndType';
import Nationality from './Nationality';
import IssueFrom from './IssueFrom';
import DateOfTravel from './DateOfTravel';
import PassportSelector from './passport-selector/PassportSelector';
import { createOpportunity, createVisaQuery } from '../../../../services/api';
import QueryCreatedSuccess from '../../queryList/query-details/QueryCreatedSuccess';
import CustomerCityTown from './CustomerCityTown';
import Other from "./Other";


const Visa: React.FC<{
  isUpdateMode: boolean
  closeAction: () => void
  query: any
}> = (props) => {

  console.log(props.query);

  const [currStep, setCurrStep] = useState("step1")
  const [formData, setFormData]: any = useState(new VisaForm(null))
  const [isOpenQuerySuccess, setIsOpenQuerySuccess] = useState<boolean>(false);

  useEffect(() => {
    if (props.query) {
      setFormData(null)
      let form = new VisaForm(props.query)
      // console.log("Nick Dat", props.query)
      setFormData(form)
    }
  }, [props.query])

  useEffect(() => {
    applicationConfig.setState("query", { currStepHotelBooking: currStep })
    applicationConfig.setFunctionRef("query", { setCurrHotelStep: setCurrStep })
  }, [currStep])



  function submit() {

    if (formData.visa_destination === "") {
      showToast("Please enter destination", 2000, "error")
      return
    }
    if (formData.visa_category === "") {
      showToast("Please enter visa category", 2000, "error")
      return
    }
    if (formData.visa_type === "") {
      showToast("Please enter visa Type", 2000, "error")
      return
    }

    if (formData.nationality === "") {
      showToast("Please enter nationality", 2000, "error")
      return
    }

    if (formData.passport_issued_from === "") {
      showToast("Please enter passport issued from", 2000, "error")
      return
    }

    if (formData.no_of_adults === 0 && formData.no_of_children === 0) {
      showToast("Please enter no of passport", 2000, "error")
      return
    }

    if (formData.date_of_travel === "") {
      showToast("Please enter date of travel", 2000, "error")
      return
    }

    if (formData.date_of_return === "") {
      showToast("Please enter date of return", 2000, "error")
      return
    }

    if (formData.customer_city_town === "") {
      showToast("Please enter customer city/town", 2000, "error")
      return
    }

    if(formData.how_soon_are_you_willing_to_continue === ""){
      showToast("Please select how soon are you willing to continue", 2000, "error")
      return
    }

    console.log("Visa Form Data", formData);

    createVisaQuery(formData).then((data: any) => {
      // console.log(data.data);
      if (data.data.success) {
        setIsOpenQuerySuccess(true);
        setTimeout(() => {
          applicationConfig.logger.info(data.data.message)
          window.location.href = "/queries"
        }, 3000)
      } else {
        // console.log("Internal Error")
        showToast(data.data.message, 2000, "error")
        applicationConfig.logger.error(data.data.message)
      }
    }).catch(err => {
      applicationConfig.logger.info(`Error in creating visa query`)
      console.log(err)
    })
  }

  return (
    <div className="wrapper create-query-wrapper full-height-width position-relative">

      <IonModal
        onDidDismiss={() => setIsOpenQuerySuccess(false)}
        isOpen={isOpenQuerySuccess}
        id="inventoryModal"
        cssClass='vertical-modal my-modal'
      >
        <QueryCreatedSuccess closeQuery={() => setIsOpenQuerySuccess(false)} />
      </IonModal>

      <div className='header-content'>
        <Header
          heading="Visa"
          closeAction={props.closeAction}
        />
      </div>


      <div className='body-content'>
        <div className="select-location-wrapper ion-padding">

          <div className="position-relative">

            <Destination
              formData={formData}
            />

            <VisaCategoryAndType
              formData={formData}
            />

            <Nationality
              formData={formData}
            />

            <IssueFrom
              formData={formData}
            />

            <PassportSelector
              formData={formData}
            />

            <DateOfTravel
              formData={formData}
            />

            <CustomerCityTown
              formData={formData}
            />

            <Other formData={formData}
              setCurrStep={setCurrStep}
            />


          </div>
        </div>
      </div>
      <div className='footer-content'>
        <div className="center-aligned-row ion-padding">
          {!props.isUpdateMode ?
            <Button2
              configObj={{}}
              action={submit}
              label={"Submit"}
              fontAwesomeIcon={"fas fa-clipboard color-danger"}
              iconPos=""
              classNames={"shadow-light margin0 bg-color-primary"}
            />
            :
            <Button2
              configObj={{}}
              action={props.closeAction}
              label={"Update"}
              fontAwesomeIcon={"fas fa-clipboard color-danger"}
              iconPos=""
              classNames={"shadow-light margin0 bg-color-success"}
            />
          }
        </div>
      </div>
    </div>
  )
};

export default Visa;


//   {queryDetails.map((details:any,index:number)=>(
//     <div className="form-label-input-container marginTop10" key={index}>
//     <div className="label-container">
//       <div className="label">
//         {details.fieldName} <span className="mandatory">*</span>
//       </div>
//     </div>
//     {details.typeInput?
//     <div className="input-container">
//       <input className="input"  name="" />
//     </div>
//     :
//     <div className="input-container">
//       <select className="input" name="" >
//       <option value="">{details.value}</option>
//     </select>
//     </div>
//     }
//   </div>
// ))}
